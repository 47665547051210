import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { tablet } from '@components/constants/constants';
import { colorsVar } from '@components/constants/colorVariables';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import styled from 'styled-components';

interface IProps {
  color?: string;
}
interface INotification {
  colorButton?: string;
  textTitle: string;
  textName?: string;
  onDone: () => void;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
}

const Notification: FC<INotification> = (props) => {
  return (
    <Container className="flex">
      <div className="flex flex-col gap-2">
        <Title className="flex">
          <LineText fontSize="20px">
            <FormattedMessage id={props.textTitle} />
          </LineText>
        </Title>
        <RenderWithCondition condition={!!props.textName}>
          <Title className="flex">
            <LineText fontSize="20px" bold="700">
              {props.textName}
            </LineText>
          </Title>
        </RenderWithCondition>
      </div>

      <ButtonsGroup className="flex">
        <Button className="flex" onClick={props.onDone} color={props.colorButton}>
          <FormattedMessage id="yes" />
        </Button>
        <ButtonCancel className="flex" onClick={props.onCancel}>
          <FormattedMessage id="cancel" />
        </ButtonCancel>
      </ButtonsGroup>
    </Container>
  );
};

export default Notification;

export const Button = styled.button<IProps>`
  justify-content: center;
  align-items: center;
  width: 145px;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  color: ${colorsVar.white};
  background-color: ${(props) => (props.color ? props.color : `${colorsVar.greenBase}`)};
  &:hover {
    background-color: ${(props) => (props.color ? props.color : `${colorsVar.greenHover}`)};
    opacity: ${(props) => (props.color ? 0.6 : 1)};
  }
  @media screen and (max-width: ${tablet}px) {
    width: 100%;
  }
`;
const ButtonCancel = styled.button`
  justify-content: center;
  align-items: center;
  width: 145px;
  font-size: 18px;
  background-color: ${colorsVar.white};
  border: 1px solid ${colorsVar.greenBase};
  color: ${colorsVar.greenBase};
  border-radius: 10px;
  &:hover {
    border: 1px solid ${colorsVar.greenClose};
    color: ${colorsVar.greenClose};
    background-color: ${colorsVar.grey5};
  }
  @media screen and (max-width: ${tablet}px) {
    width: 100%;
  }
`;
const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: ${colorsVar.textColor};
`;
const Container = styled.div`
  display: flex;
  width: 445px;
  flex-direction: column;
  gap: 35px;
  background: ${colorsVar.white};
  border-radius: 20px;
  padding: 15px 45px;
  box-sizing: border-box;
  @media screen and (max-width: ${tablet}px) {
    width: 100%;
    padding: 25px 0px;
    min-height: 180px;
  }
`;
