import { getAvatarFiles } from '@store/user/api';
import { IGroups } from 'interfaces/groups.interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

const initialGroupData: IGroups = {
  id: '',
  name: '',
  participants: [],
  participantsWithAccess: [],
};

interface IProps {
  groupData: IGroups | null;
  isLoading?: boolean;
}

const useDataGroup = ({ groupData, isLoading }: IProps) => {
  const [data, setData] = useState<IGroups>(groupData ?? initialGroupData);
  const [newNameGroup, setNewNameGroup] = useState('');

  const [urlAvatar, setUrlAvatar] = useState<string>();
  const dispatch = useDispatch();

  const createAction = !groupData;

  useEffect(() => {
    if (!groupData) {
      setData({ ...initialGroupData, name: newNameGroup });
      return;
    }
    setData({
      ...groupData,
      name: newNameGroup ? newNameGroup : groupData.name,
    });
  }, [groupData, urlAvatar, newNameGroup]);

  const getUrlAvatar = async () => {
    try {
      const paramsString = new URLSearchParams({
        directDownload: 'true',
      }).toString();
    } catch (error) {
      console.log('handleAuth err', error);
      setUrlAvatar(undefined);
    }
  };

  const handleData = (name: string, value: unknown) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const paramsCreateGroup = useMemo(() => {
    const newData: any = {
      data: {
        name: data.name,
        participantsIds: data.participants.map((item) => item.id),
        participantsWithAccessIds: data.participantsWithAccess.map((item) => item.id),
      },
    };
    return newData;
  }, [data]);

  return useMemo(
    () => ({
      createAction,
      data,
      setNewNameGroup,
      newNameGroup,
      handleData,
      paramsCreateGroup,
    }),
    [groupData, newNameGroup, data],
  );
};

export default useDataGroup;
