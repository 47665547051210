import React, { FC, useState } from 'react';

import { ReactComponent as CloseIcon } from '@assets/icons/close-grey_sm.svg';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { LineText } from '@components/commons/commonsStyled';
import HeaderTooltip from './Header.Tooltip';
import { colorsVar } from '@components/constants/colorVariables';
import { useAuthSelector } from '@store/auth/selectors';
import AvatarAssist from '@ui/avatarAssist/AvatarAssist';
import { IConnectionAssist } from 'interfaces/user.interfaces';
import useGetUrlAvatar from '@hooks/useGetUrlAvatar';
import styled from 'styled-components';

interface IItemAssist {
  textName: any;
  avatarId?: string;
  textTooltip?: string;
  connectionAssist: IConnectionAssist | null;
  onHandleDelete?: () => void;
}

const ItemAssist: FC<IItemAssist> = (props) => {
  const { supervisor } = useAuthSelector((state) => state.auth);

  const urlAvatar = useGetUrlAvatar({ fileId: props.avatarId });

  const [dataTooltipDelete, setDataTooltipDelete] = useState<any | null>(null);

  return (
    <ItemWrap className="flex ">
      <AvatarAssist size="small" textTooltip={props.textTooltip} urlAvatar={urlAvatar} />
      <LineText fontSize="15px">{props.textName}</LineText>
      <RenderWithCondition condition={!supervisor && props.connectionAssist?.isAssistant}>
        <CloseWrap
          className="flex "
          onMouseOver={() => setDataTooltipDelete({ text: 'Удалить', isOpen: true })}
          onMouseOut={() => setDataTooltipDelete({ text: 'Удалить ', isOpen: false })}
          onClick={props.onHandleDelete}
        >
          <CloseIcon />
        </CloseWrap>
      </RenderWithCondition>
      <HeaderTooltip dataTooltip={dataTooltipDelete} className="open_delete" />
      <RenderWithCondition condition={!supervisor && !props.connectionAssist?.isAssistant}>
        <div />
      </RenderWithCondition>
    </ItemWrap>
  );
};
export default ItemAssist;

const ItemWrap = styled.div`
  background-color: ${colorsVar.grey1};
  min-width: 180px;
  position: relative;
  height: 28px;
  padding: 2px 7px 2px 7px;
  border-radius: 8px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;
export const AvatarWrap = styled.div`
  border: 1px solid ${colorsVar.grey4};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const CloseWrap = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${colorsVar.white80};
  gap: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
