import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActiveUsers,
  IActiveUsersPeriod,
  IBusinessGoalsMonth,
  IBusinessTasks,
  IClosureTasks,
  ICompleteTasks,
  IOptionsPersonal,
  IPersonalGoalsMonth,
  IPlanedTasks,
  ITasksBusinessPeriod,
  IUsersWithGoals,
  IUsersWithTasks,
} from 'interfaces/stats.interfaces';
import { IStateUser } from 'interfaces/user.interfaces';
import {
  getActiveUsers,
  getAllTasks,
  getPlanedTasks,
  getBusinessGoals,
  getPersonalGoals,
  getCompletionTasks,
  getCreationTasks,
  getClosureTasks,
  getUsersTasks,
  getUsersGoals,
} from './api';
import { defaultGoals, optionsPersonalGoals } from './constants';

interface IState {
  users: IStateUser[];
  selectedGoals: string[];

  activeUsers: IActiveUsers[];
  isLoadingActiveUsers: boolean;
  activeUsersPeriod: IActiveUsersPeriod;

  tasksBusinessPeriod: ITasksBusinessPeriod;
  usersWithTasks: IUsersWithTasks;
  isLoadingUsersTasks: boolean;
  usersWithGoals: IUsersWithGoals;
  isLoadingUsersGoals: boolean;

  allTasks: IPlanedTasks[];
  isGetAllTasks: boolean;
  planedTasksPeriod: IActiveUsersPeriod;
  planedTasks: IPlanedTasks[];
  isLoadingPlanedTasks: boolean;
  completeTasksPeriod: IActiveUsersPeriod;
  completeTasks: ICompleteTasks[];
  isLoadingCompleteTasks: boolean;
  isGetPeriod: boolean;

  creationTasksPeriod: IActiveUsersPeriod;
  creationTasks: IClosureTasks[];
  isLoadingCreationTasks: boolean;
  closureTasksPeriod: IActiveUsersPeriod;
  closureTasks: IClosureTasks[];
  isLoadingClosureTasks: boolean;

  businessGoalsPeriod: IActiveUsersPeriod;
  businessGoalsMonth: IBusinessGoalsMonth[];
  isLoadingBusinessGoals: boolean;

  personalGoalsPeriod: IActiveUsersPeriod;
  personalGoalsMonth: IPersonalGoalsMonth;
  isLoadingPersonalGoals: boolean;

  closedTasks: IBusinessTasks[];
  optionsPersonalGoals: IOptionsPersonal[];
  currentUser: IStateUser | null;
  isLoading: boolean;
  isFavorites: boolean;
  isLoadingExpand: boolean;
  errorMsg: null | string;
}

const initialState: IState = {
  users: [],

  activeUsers: [],
  isLoadingActiveUsers: false,
  activeUsersPeriod: {} as IActiveUsersPeriod,

  tasksBusinessPeriod: {} as ITasksBusinessPeriod,
  usersWithTasks: {} as IUsersWithTasks,
  isLoadingUsersTasks: false,
  usersWithGoals: {} as IUsersWithGoals,
  isLoadingUsersGoals: false,

  allTasks: [],
  isGetAllTasks: false,
  planedTasksPeriod: {} as IActiveUsersPeriod,
  planedTasks: [],
  isLoadingPlanedTasks: false,
  completeTasksPeriod: {} as IActiveUsersPeriod,
  completeTasks: [],
  isLoadingCompleteTasks: false,
  isGetPeriod: false,

  creationTasksPeriod: {} as IActiveUsersPeriod,
  isLoadingCreationTasks: false,
  closureTasksPeriod: {} as IActiveUsersPeriod,
  isLoadingClosureTasks: false,

  businessGoalsPeriod: {} as IActiveUsersPeriod,
  businessGoalsMonth: [],
  isLoadingBusinessGoals: false,

  personalGoalsPeriod: {} as IActiveUsersPeriod,
  personalGoalsMonth: {} as IPersonalGoalsMonth,
  isLoadingPersonalGoals: false,

  closureTasks: [],
  closedTasks: [],
  creationTasks: [],
  optionsPersonalGoals: optionsPersonalGoals,
  selectedGoals: defaultGoals,
  currentUser: null,
  isLoading: false,
  isFavorites: false,
  errorMsg: null,
  isLoadingExpand: false,
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    currentOptionPersonalGoals: (state: IState, { payload }) => {
      state.optionsPersonalGoals = payload;
    },
    addSelectedGoals: (state: IState, { payload }) => {
      state.selectedGoals = payload;
    },
    setUsersPeriod: (state: IState, { payload }) => {
      state.activeUsersPeriod.startDate = payload.startDay;
      state.activeUsersPeriod.endDate = payload.endDay;
    },
    addStartDate: (state: IState, { payload }) => {
      state.activeUsersPeriod.startDate = payload;
    },
    addEndDate: (state: IState, { payload }) => {
      state.activeUsersPeriod.endDate = payload;
    },
    addPieTasksDate: (state: IState, { payload }) => {
      state.tasksBusinessPeriod.tasksDate = payload;
    },
    addPieBusinessDate: (state: IState, { payload }) => {
      state.tasksBusinessPeriod.businessDate = payload;
    },
    addStartPlanedDate: (state: IState, { payload }) => {
      state.planedTasksPeriod.startDate = payload;
    },
    addEndPlanedDate: (state: IState, { payload }) => {
      state.planedTasksPeriod.endDate = payload;
    },
    addStartCompleteDate: (state: IState, { payload }) => {
      state.completeTasksPeriod.startDate = payload;
      state.isGetPeriod = true;
    },
    addFlagGetAllTasks: (state: IState, { payload }) => {
      state.isGetAllTasks = true;
    },
    addEndCompleteDate: (state: IState, { payload }) => {
      state.completeTasksPeriod.endDate = payload;
    },
    addStartCreationDate: (state: IState, { payload }) => {
      state.creationTasksPeriod.startDate = payload;
    },
    addEndCreationDate: (state: IState, { payload }) => {
      state.creationTasksPeriod.endDate = payload;
    },
    addStartClosureDate: (state: IState, { payload }) => {
      state.closureTasksPeriod.startDate = payload;
    },
    addEndClosureDate: (state: IState, { payload }) => {
      state.closureTasksPeriod.endDate = payload;
    },
    addStartBusinessDate: (state: IState, { payload }) => {
      state.businessGoalsPeriod.startDate = payload;
    },
    addEndBusinessDate: (state: IState, { payload }) => {
      state.businessGoalsPeriod.endDate = payload;
    },
    addStartPersonalDate: (state: IState, { payload }) => {
      state.personalGoalsPeriod.startDate = payload;
    },
    addEndPersonalDate: (state: IState, { payload }) => {
      state.personalGoalsPeriod.endDate = payload;
    },
    setFavorites: (state: IState, { payload }) => {
      state.isFavorites = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      //TODO: ==============  RTK Query =================
      .addCase(getActiveUsers.pending, (state) => {
        state.isLoadingActiveUsers = true;
        state.errorMsg = null;
      })
      .addCase(getActiveUsers.fulfilled, (state, { payload }) => {
        state.activeUsers = payload.response.data;
        state.isLoadingActiveUsers = false;
      })
      .addCase(getActiveUsers.rejected, (state, { payload }) => {
        state.isLoadingActiveUsers = false;
        state.errorMsg = payload;
      })

      .addCase(getAllTasks.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getAllTasks.fulfilled, (state, { payload }) => {
        state.allTasks = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getAllTasks.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getPlanedTasks.pending, (state) => {
        state.isLoadingPlanedTasks = true;
        state.errorMsg = null;
      })
      .addCase(getPlanedTasks.fulfilled, (state, { payload }) => {
        state.planedTasks = payload.response.data;
        state.isLoadingPlanedTasks = false;
      })
      .addCase(getPlanedTasks.rejected, (state, { payload }) => {
        state.isLoadingPlanedTasks = false;
        state.errorMsg = payload;
      })

      .addCase(getCompletionTasks.pending, (state) => {
        state.isLoadingCompleteTasks = true;
        state.errorMsg = null;
      })
      .addCase(getCompletionTasks.fulfilled, (state, { payload }) => {
        state.completeTasks = payload.response.data;
        state.isLoadingCompleteTasks = false;
      })
      .addCase(getCompletionTasks.rejected, (state, { payload }) => {
        state.isLoadingCompleteTasks = false;
        state.errorMsg = payload;
      })

      .addCase(getCreationTasks.pending, (state) => {
        state.isLoadingCreationTasks = true;
        state.errorMsg = null;
      })
      .addCase(getCreationTasks.fulfilled, (state, { payload }) => {
        state.creationTasks = payload.response.data;
        state.isLoadingCreationTasks = false;
      })
      .addCase(getCreationTasks.rejected, (state, { payload }) => {
        state.isLoadingCreationTasks = false;
        state.errorMsg = payload;
      })

      .addCase(getClosureTasks.pending, (state) => {
        state.isLoadingClosureTasks = true;
        state.errorMsg = null;
      })
      .addCase(getClosureTasks.fulfilled, (state, { payload }) => {
        state.closureTasks = payload.response.data;
        state.isLoadingClosureTasks = false;
      })
      .addCase(getClosureTasks.rejected, (state, { payload }) => {
        state.isLoadingClosureTasks = false;
        state.errorMsg = payload;
      })

      .addCase(getBusinessGoals.pending, (state) => {
        state.isLoadingBusinessGoals = true;
        state.errorMsg = null;
      })
      .addCase(getBusinessGoals.fulfilled, (state, { payload }) => {
        state.businessGoalsMonth = payload.response.data;
        state.isLoadingBusinessGoals = false;
      })
      .addCase(getBusinessGoals.rejected, (state, { payload }) => {
        state.isLoadingBusinessGoals = false;
        state.errorMsg = payload;
      })

      .addCase(getPersonalGoals.pending, (state) => {
        state.isLoadingPersonalGoals = true;
        state.errorMsg = null;
      })
      .addCase(getPersonalGoals.fulfilled, (state, { payload }) => {
        state.personalGoalsMonth = payload.response.data;
        state.isLoadingPersonalGoals = false;
      })
      .addCase(getPersonalGoals.rejected, (state, { payload }) => {
        state.isLoadingPersonalGoals = false;
        state.errorMsg = payload;
      })
      //TODO: ==============  RTK Query =================

      .addCase(getUsersTasks.pending, (state) => {
        state.isLoadingUsersTasks = true;
        state.errorMsg = null;
      })
      .addCase(getUsersTasks.fulfilled, (state, { payload }) => {
        state.usersWithTasks = payload.response.data;
        state.isLoadingUsersTasks = false;
      })
      .addCase(getUsersTasks.rejected, (state, { payload }) => {
        state.isLoadingUsersTasks = false;
        state.errorMsg = payload;
      })

      .addCase(getUsersGoals.pending, (state) => {
        state.isLoadingUsersGoals = true;
        state.errorMsg = null;
      })
      .addCase(getUsersGoals.fulfilled, (state, { payload }) => {
        state.usersWithGoals = payload.response.data;
        state.isLoadingUsersGoals = false;
      })
      .addCase(getUsersGoals.rejected, (state, { payload }) => {
        state.isLoadingUsersGoals = false;
        state.errorMsg = payload;
      });
  },
});
export const {
  currentOptionPersonalGoals,
  addSelectedGoals,
  addFlagGetAllTasks,
  addStartDate,
  addEndDate,
  setUsersPeriod,
  addPieTasksDate,
  addPieBusinessDate,
  addStartPlanedDate,
  addEndPlanedDate,
  addStartCompleteDate,
  addEndCompleteDate,
  addStartCreationDate,
  addEndCreationDate,
  addStartClosureDate,
  addEndClosureDate,
  addStartBusinessDate,
  addEndBusinessDate,
  addStartPersonalDate,
  addEndPersonalDate,
  setFavorites,
} = statsSlice.actions;
export const selectStatsSlice = (state: { stats: IState }) => state.stats;
export default statsSlice.reducer;
