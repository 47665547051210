import React, { useState } from 'react';

import { Modal } from '../../../ui/modal';
import { Select } from '../../../ui/select';
import { SelectCalendar } from '../../../ui/selectCalendar';
import { optionsAccess, optionsTest } from './constants';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { IFilter } from 'interfaces/filter.interfaces';
import { getIdDepartment } from '../../../utils/getOptionsDepartments';
import { currentDep, selectUserSlice } from '@store/user/slice';
import cn from 'classnames';
import './modalFilter.scss';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import dayjs from 'dayjs';
import { IDepartment } from 'interfaces/user.interfaces';
import { Container } from '../modalUser/ModalUser';
import { useAppSelector } from '@store/hooks';
import CheckInputMod from '@ui/checkInput/CheckInputMod';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { Spacer } from '@components/commons/commonsStyled';

interface IModalFilter {
  filter: IFilter;
  options?: string[];
  isOthers?: boolean;
  isFiltered: boolean;
  supervisor?: boolean;
  handleFiltered: () => void;
  setFilter: ActionCreatorWithPayload<Partial<IFilter>>;
  setIsVisible: (arg: boolean) => void;
  departmentsList?: IDepartment[];
}

export const ModalFilter = ({
  filter,
  options,
  isOthers,
  setFilter,
  isFiltered,
  supervisor,
  setIsVisible,
  handleFiltered,
  departmentsList,
}: IModalFilter) => {
  const { isMobile } = useWidthScreens();
  const [isEdit, setIsEdit] = useState(false);

  const { currentNameDep } = useAppSelector(selectUserSlice);
  const [checkedLoggedIn, setCheckedLoggedIn] = useState(false);
  const [checkedNotLoggedIn, setCheckedNotLoggedIn] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = () => {
    handleFiltered();
    setIsVisible(false);
  };
  const handleSetFilter = (value: string | boolean | null, definition: string) => {
    setIsEdit(true);

    if (definition === 'departmentId') {
      if (value === '---') {
        dispatch(currentDep({ departmentName: null }));
        return dispatch(setFilter({ [definition]: null }));
      } else {
        const idDep = getIdDepartment(departmentsList, String(value));
        dispatch(currentDep({ departmentName: String(value) }));
        return dispatch(setFilter({ [definition]: idDep }));
      }
    }
    if (definition === 'testOnly') {
      if (value === 'Тест') {
        return dispatch(setFilter({ [definition]: true }));
      } else if (value === 'no-Test') {
        return dispatch(setFilter({ [definition]: false }));
      } else {
        return dispatch(setFilter({ [definition]: null }));
      }
    }
    if (definition === 'isEnabled') {
      if (value === 'Права') {
        return dispatch(setFilter({ [definition]: true }));
      } else if (value === 'Нет прав') {
        return dispatch(setFilter({ [definition]: false }));
      } else {
        return dispatch(setFilter({ [definition]: null }));
      }
    }
    if (definition === 'notLoggedIn' || definition === 'loggedIn') {
      if (value === true) {
        return dispatch(setFilter({ [definition]: true }));
      } else {
        return dispatch(setFilter({ [definition]: null }));
      }
    }
    if (
      definition === 'firstEntryDateStartRange' ||
      definition === 'firstEntryDateEndRange' ||
      definition === 'lastEntryDateStartRange' ||
      definition === 'lastEntryDateEndRange'
    ) {
      if (value === null) {
        return dispatch(setFilter({ [definition]: null }));
      } else {
        dispatch(setFilter({ loggedIn: true }));
        return dispatch(setFilter({ [definition]: value }));
      }
    }
  };

  return (
    <Container>
      <div className="modal-filter">
        <h1>
          <FormattedMessage id="filter" />
        </h1>
        <div className={cn('body')}>
          <RenderWithCondition condition={!isOthers}>
            <div className="line-input">
              <span>
                <FormattedMessage id="subdivision" />
              </span>
              <div className="modal-filter-select">
                <Select
                  type="select"
                  id="departmentId"
                  valueStr={currentNameDep}
                  onChange={handleSetFilter}
                  options={options}
                />
              </div>
            </div>
          </RenderWithCondition>
          <RenderWithCondition condition={!supervisor}>
            <>
              <div className="line-input">
                <span>
                  <FormattedMessage id="test" />
                </span>
                <div className="modal-filter-select ">
                  <Select
                    id="testOnly"
                    type="select"
                    value={filter.testOnly}
                    onChange={handleSetFilter}
                    options={optionsTest}
                  />
                </div>
              </div>
              <RenderWithCondition condition={!isOthers}>
                <div className="line-input">
                  <span>
                    <FormattedMessage id="rights" />
                  </span>
                  <div className="modal-filter-select ">
                    <Select
                      type="select"
                      id="isEnabled"
                      value={filter.isEnabled}
                      options={optionsAccess}
                      onChange={handleSetFilter}
                    />
                  </div>
                </div>
              </RenderWithCondition>
            </>
          </RenderWithCondition>
        </div>
        <RenderWithCondition condition={isOthers}>
          <Spacer px={80} />
        </RenderWithCondition>
        <div className="line"></div>

        <div className="calendar-wrap">
          <div className="cal-item">
            <div className="left-label-up">
              <FormattedMessage id="appUsage" />
            </div>
            <div className="flex flex-col gap-[18px] w-full ">
              <div className="flex gap-3">
                <CheckInputMod
                  changeEvent={() => {
                    handleSetFilter(!checkedNotLoggedIn, 'notLoggedIn');
                    setCheckedNotLoggedIn(!checkedNotLoggedIn);
                  }}
                  isActive={checkedNotLoggedIn}
                  isDisabled={false}
                />
                <span>
                  <FormattedMessage id="notLoggedIn" />
                </span>
              </div>

              <div className="flex gap-3">
                <CheckInputMod
                  changeEvent={() => {
                    handleSetFilter(!checkedLoggedIn, 'loggedIn');
                    setCheckedLoggedIn(!checkedLoggedIn);
                  }}
                  isActive={checkedLoggedIn}
                  isDisabled={false}
                />
                <span>
                  <FormattedMessage id="loggedIn" />
                </span>
              </div>
            </div>
          </div>

          <div className="cal-item">
            <div className="left-label "></div>
            <div className={`flex flex-col gap-5 items-end  w-full ${isMobile ? 'pl-0' : 'pl-8'}`}>
              <div className="data-picker">
                <span>
                  <FormattedMessage id="firstActivity" />
                </span>
                <div className={`flex  w-full ${isMobile ? 'gap-[7px]' : 'gap-[27px]'}`}>
                  <div className="select-cal-body">
                    <SelectCalendar
                      type="input"
                      onChange={handleSetFilter}
                      id="firstEntryDateStartRange"
                      value={
                        filter.firstEntryDateStartRange ? dayjs(filter.firstEntryDateStartRange).format('DD.MM.YY') : ''
                      }
                    />
                  </div>
                  <div className="select-cal-body">
                    <SelectCalendar
                      type="input"
                      onChange={handleSetFilter}
                      id="firstEntryDateEndRange"
                      value={
                        filter.firstEntryDateEndRange ? dayjs(filter.firstEntryDateEndRange).format('DD.MM.YY') : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="data-picker">
                <span>
                  <FormattedMessage id="lastActivity" />
                </span>
                <div style={{ display: 'flex', gap: 27 }}>
                  <div className="select-cal-body">
                    <SelectCalendar
                      type="input"
                      onChange={handleSetFilter}
                      id="lastEntryDateStartRange"
                      value={
                        filter.lastEntryDateStartRange ? dayjs(filter.lastEntryDateStartRange).format('DD.MM.YY') : ''
                      }
                    />
                  </div>
                  <div className="select-cal-body">
                    <SelectCalendar
                      type="input"
                      onChange={handleSetFilter}
                      id="lastEntryDateEndRange"
                      value={filter.lastEntryDateEndRange ? dayjs(filter.lastEntryDateEndRange).format('DD.MM.YY') : ''}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button disabled={isFiltered && !isEdit} onClick={onSubmit}>
          <FormattedMessage id="showIt" />
        </button>
      </div>
    </Container>
  );
};
