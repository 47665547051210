import React, { ReactNode, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';

import styled from 'styled-components';
import { mobile, tablet } from '@components/constants/constants';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { Value } from 'react-calendar/dist/cjs/shared/types';

interface IChipMain {
  buttonText: string;
  colorType?: 'primary_black' | 'secondary_white' | 'primary_white' | 'secondary_black';
  buttonSize?: 'large' | 'normal' | 'medium' | 'small';
  className?: string;
  clickEvent?: (value: string) => void;
  svgIconLeft?: ReactNode;
  svgIconRight?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  active?: boolean;
  keyGroup?: string;
}

export default function ChipMain({
  buttonText,
  clickEvent,
  isDisabled,
  svgIconLeft,
  svgIconRight,
  keyGroup,
  active = false,
}: IChipMain) {
  const { isMobile } = useWidthScreens();

  function handleClick(value: string) {
    if (!isDisabled && clickEvent) {
      clickEvent(value);
    }
  }

  return (
    <ChipWrap className="flex" onClick={() => handleClick(keyGroup ?? '')} isDisabled={isDisabled} active={active}>
      {svgIconLeft && <div className="mr-1">{svgIconLeft}</div>}
      <LineText
        className="justify-center"
        fontSize="12px"
        color={isDisabled ? colorsVar.grey4 : ''}
        bold={'400'}
        lineHeight="20px"
      >
        <FormattedMessage id={buttonText} />
      </LineText>

      {svgIconRight && <div className="mr-1">{svgIconLeft}</div>}
    </ChipWrap>
  );
}

const ChipWrap = styled.div<{ active: boolean; isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  gap: 3px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid ${colorsVar.grey1};
  &:hover {
    background-color: ${colorsVar.grey1};
    transition: background-color 0.5s ease;
  }
  &:active {
    background-color: ${colorsVar.greenBase};
  }

  ${({ active, isDisabled }) =>
    active &&
    !isDisabled &&
    `
    background-color: #087d4b6a;
    &:hover {
    background-color: #087d4b6a;
  }

  `}
  ${({ isDisabled }) =>
    isDisabled &&
    `
    cursor: not-allowed;
    &:hover {
    background-color: transparent;
  }

  `}
`;
