import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultFilter } from '@store/user/constants';
import { IFilter } from 'interfaces/filter.interfaces';
import { IStateUser } from 'interfaces/user.interfaces';
import { getOthers, getMoreOthers, createOther, deleteOther, updateOther } from './api';

interface IOther {
  currentUser: IStateUser | null;
  others: IStateUser[];
  isLoading: boolean;
  isLoadingExpand: boolean;
  errorMsg: null | string;
  lengthList: number;
  filter: IFilter;
}

const initialState: IOther = {
  currentUser: null,
  others: [],
  isLoading: false,
  errorMsg: null,
  lengthList: 0,
  isLoadingExpand: false,
  filter: defaultFilter,
};

const otherSlice = createSlice({
  name: 'other',
  initialState,
  reducers: {
    setFilterOthers: (state: IOther, action: PayloadAction<Partial<IFilter>>) => {
      try {
        const valueArg = action.payload;
        for (const key in valueArg) {
          if (Object.hasOwnProperty.call(valueArg, key) && Object.hasOwnProperty.call(state.filter, key)) {
            state.filter[key] = valueArg[key];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    deleteFromOther: (state: IOther, { payload }) => {
      state.others = [...state.others].filter((item) => item.id !== payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOthers.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getOthers.fulfilled, (state, { payload }) => {
        state.others = payload?.response?.data.content;
        state.lengthList = payload?.response?.data.content.length;
        state.isLoading = false;
      })
      .addCase(getOthers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getMoreOthers.pending, (state) => {
        state.isLoadingExpand = true;
        state.errorMsg = null;
      })
      .addCase(getMoreOthers.fulfilled, (state, { payload }) => {
        state.others = [...state.others, ...payload?.response?.data.content];
        state.lengthList = payload?.response?.data.content.length;
        state.isLoadingExpand = false;
      })
      .addCase(getMoreOthers.rejected, (state, { payload }) => {
        state.isLoadingExpand = false;
        state.errorMsg = payload;
      })

      .addCase(createOther.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(createOther.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        // state.users = [...state.users, payload.response.data];
      })
      .addCase(createOther.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(deleteOther.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(deleteOther.fulfilled, (state, { payload }) => {
        state.currentUser = null;
        state.others = [...state.others].filter((item) => item.id !== payload.id);
        state.isLoading = false;
      })
      .addCase(deleteOther.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      })

      .addCase(updateOther.pending, (state) => {
        // state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(updateOther.fulfilled, (state, { payload }) => {
        state.currentUser = payload?.response?.data;
        state.others = state.others.map((user) =>
          user.id === payload.response.data.id ? payload.response.data : user,
        );
        state.isLoading = false;
      })
      .addCase(updateOther.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      });
  },
});
export const { setFilterOthers, deleteFromOther } = otherSlice.actions;
export const selectOtherSlice = (state: { other: IOther }) => state.other;

export default otherSlice.reducer;
