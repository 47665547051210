import { ACCESS_TOKEN, REFRESH_TOKEN } from '@components/constants/constants';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookieService from '@services/cookie.service';
import { logOut } from '@store/auth/slice';
import axios from 'axios';

const API_URL = '/';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers) => {
    const accessToken = cookieService.get(ACCESS_TOKEN);

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    headers.set('Accept', 'application/octet-stream, application/json');
    headers.set('Cache-Control', 'no-cache');
    headers.set('Content-Type', 'application/json; charset=utf-8');
    headers.set('Access-Control-Allow-Credentials', 'true');

    return headers;
  },
});

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    await sendRefreshToken();
    const refreshResult = await baseQuery(args, api, extraOptions);
    if (refreshResult.error && refreshResult.error.status === 401) {
      api.dispatch(logOut());
    } else {
      result = refreshResult;
    }
  }

  return result;
};

export const apiBaseQuery = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['User', 'Departments', 'Other', 'Admin', 'Assist', 'Manager', 'UserInfo', 'Sessions'],
  refetchOnFocus: true,
  refetchOnReconnect: true,

  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => ({
        url: '/',
        params,
      }),
    }),
  }),
});

async function sendRefreshToken(): Promise<any> {
  try {
    const refresh_token = await cookieService.get(REFRESH_TOKEN);

    if (refresh_token != null) {
      const body = { refresh_token, grant_type: REFRESH_TOKEN };
      const response = await axios.post(`/web/auth/token`, body);

      const accessToken = response?.data?.access_token;
      const refreshToken = response?.data?.refresh_token;

      const options = { path: '/' };
      cookieService.set(ACCESS_TOKEN, accessToken, options);
      cookieService.set(REFRESH_TOKEN, refreshToken, options);

      return;
    }
  } catch (error: any) {
    return cookieService.remove(ACCESS_TOKEN, { path: '/' });
  }
}
