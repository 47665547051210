import { ACCESS_TOKEN, HAVE_EMPLOYEES, REFRESH_TOKEN, SUPERVISOR } from '@components/constants/constants';
import { createSlice } from '@reduxjs/toolkit';
import { IStateUser } from 'interfaces/user.interfaces';
import cookieService from '../../services/cookie.service';
import { checkAuth, codeApi, deleteCurrentSession, getCodeApi, getMainUser, loginApi } from './api';

interface IInitialState {
  isLoading: boolean;
  isLoggedIn: boolean;
  mainUser: IStateUser;
  supervisor: boolean;
  haveEmployees: boolean;
  codeNumber: null | string;
  phoneNumber: null | string;
  adminLog: undefined | string;
  registered: boolean;
  isExistCode: boolean;
  isNoRegistered: boolean;
  errorMsg: null | string;
  isLoginFromDTApp: boolean;
}

const initialState: IInitialState = {
  isLoading: false,
  isLoggedIn: false,
  supervisor: false,
  haveEmployees: false,
  mainUser: {} as IStateUser,
  isExistCode: false,
  codeNumber: null,
  phoneNumber: null,
  adminLog: '',
  registered: false,
  isNoRegistered: false,
  errorMsg: null,
  isLoginFromDTApp: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state: IInitialState) => {
      cookieService.remove(ACCESS_TOKEN, { path: '/' });
      cookieService.remove(REFRESH_TOKEN, { path: '/' });
      cookieService.remove(SUPERVISOR, { path: '/' });
      cookieService.remove(HAVE_EMPLOYEES, { path: '/' });
      return initialState;
    },
    logIn: (state: IInitialState) => {
      state.isLoggedIn = true;
    },

    loginFromDTApp: (state: IInitialState) => {
      state.isLoginFromDTApp = true;
      state.isLoggedIn = true;
    },

    getAdminLog: (state: IInitialState, { payload }) => {
      state.adminLog = payload;
    },

    clearError: (state: IInitialState) => {
      state.errorMsg = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginApi.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(loginApi.fulfilled, (state, { payload }) => {
        const accessToken = payload?.response?.data?.access_token;
        const refreshToken = payload?.response?.data?.refresh_token;

        const options = { path: '/' };
        cookieService.set(ACCESS_TOKEN, accessToken, options);
        cookieService.set(REFRESH_TOKEN, refreshToken, options);

        state.isLoggedIn = true;
        state.isLoading = false;
      })
      .addCase(loginApi.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(codeApi.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(codeApi.fulfilled, (state, { payload }) => {
        const accessToken = payload?.response?.data?.access_token;
        const refreshToken = payload?.response?.data?.refresh_token;

        const options = { path: '/' };
        cookieService.set(ACCESS_TOKEN, accessToken, options);
        cookieService.set(REFRESH_TOKEN, refreshToken, options);

        state.isLoggedIn = true;
        state.isLoading = false;
      })
      .addCase(codeApi.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(checkAuth.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(checkAuth.fulfilled, (state, { payload }) => {
        state.isLoggedIn = payload.accessToken === 'undefined' ? false : !!payload.accessToken;
        state.supervisor = !!payload.supervisor;
        state.haveEmployees = !!payload.haveEmployees;
        state.isLoading = false;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(getMainUser.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getMainUser.fulfilled, (state, { payload }) => {
        state.mainUser = payload?.response?.data;
        state.isLoading = false;
      })
      .addCase(getMainUser.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(getCodeApi.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getCodeApi.fulfilled, (state, { payload }) => {
        state.phoneNumber = payload.phoneNumber;
        state.registered = payload?.response?.data?.registered ?? true;
        state.isLoading = false;
      })
      .addCase(getCodeApi.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export const { logOut, clearError, logIn, getAdminLog, loginFromDTApp } = authSlice.actions;
export const selectAuthSlice = (state: { auth: IInitialState }) => state.auth;

export default authSlice.reducer;
