import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminService } from '@services/admin.service';

const getError = (error: any, thunkAPI: any) => {
  const message = (error.data && error.data.message) || error.message || error.toString();
  return thunkAPI.rejectWithValue(message);
};

//TODO:   ================== RTK Query ==================

export const getAdmins: any = createAsyncThunk('admin/getAdmins', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await adminService.getAdmins(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getAllUsers: any = createAsyncThunk('admin/getAllUsers', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await adminService.getAllUsers(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getMoreAdmins: any = createAsyncThunk('admin/getMoreAdmins', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await adminService.getAdmins(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getSpaceId: any = createAsyncThunk('admin/getSpaceId', async (thunkAPI) => {
  try {
    const response = await adminService.getSpaceId();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const createAdmin = createAsyncThunk('admin/createAdmin', async ({ id, data }: any, thunkAPI) => {
  try {
    const response = await adminService.create(id, data);

    return { id, response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateAdmin = createAsyncThunk('admin/updateAdmin', async ({ id, data }: any, thunkAPI) => {
  try {
    const response = await adminService.updatePassword(id, data);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteAdmin = createAsyncThunk('admin/deleteAdmin', async ({ id, data }: any, thunkAPI) => {
  try {
    await adminService.delete(id, data);
    return { id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

//TODO:   ================== RTK Query ==================
