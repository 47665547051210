import { createAsyncThunk } from '@reduxjs/toolkit';
import { assistService } from '@services/assist.service';

const getError = (error: any, thunkAPI: any) => {
  const message = (error.data && error.data.message) || error.message || error.toString();
  return thunkAPI.rejectWithValue(message);
};

//TODO:   ================== RTK Query ==================

export const getAssists: any = createAsyncThunk('assist/getAssists', async (params: string, thunkAPI) => {
  try {
    const response = await assistService.getAssists(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getManagers: any = createAsyncThunk('assist/getManagers', async (params: string, thunkAPI) => {
  try {
    const response = await assistService.getManagers(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const addAssists: any = createAsyncThunk('assist/addAssists', async ({ data }: any, thunkAPI) => {
  try {
    const response = await assistService.addAssists(data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteAssists: any = createAsyncThunk('assist/deleteAssists', async ({ data }: any, thunkAPI) => {
  try {
    const response = await assistService.deleteAssists(data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

//TODO:   ================== RTK Query ==================

export const updateAssist: any = createAsyncThunk('assist/updateAssist', async ({ data }: any, thunkAPI) => {
  try {
    const response = await assistService.updateAssists(data);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateManager: any = createAsyncThunk('assist/updateManager', async ({ data }: any, thunkAPI) => {
  try {
    const response = await assistService.updateManager(data);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
