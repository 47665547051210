import React from 'react';

import { IStateUser, ITitleKey } from 'interfaces/user.interfaces';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { infoMap } from '../../constants';
import { uniqueId } from 'lodash';
import { LineText } from '@components/commons/commonsStyled';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import styled from 'styled-components';

interface ITableInfo {
  user: IStateUser;
}

const emptyField = 'не заполнено';

export const TableInfo = ({ user }: ITableInfo) => {
  const renderRow = (data: ITitleKey[]) => {
    return data.map((field) => {
      let key = String(field.key);
      // @ts-ignore
      let value = user[key];
      if (key === 'gender') {
        if (value === null) {
          value = emptyField;
        } else {
          value === 'FEMALE' ? (value = 'Женский') : (value = 'Мужской');
        }
      }
      if (key === 'birthDay') {
        value === null ? (value = emptyField) : (value = dayjs(value).locale('ru').format('DD MMMM'));
      }
      const isLink = field.title === 'contactEmail' && value !== emptyField;

      return (
        <ItemBlock key={uniqueId(field.key)} className="flex">
          <LineText fontSize="14px" color="#5f6e67">
            <FormattedMessage id={field.title} />
          </LineText>
          <RenderWithCondition condition={!isLink}>
            <LineText fontSize="16px">{value ?? emptyField}</LineText>
          </RenderWithCondition>
          <RenderWithCondition condition={isLink}>
            <a href="/">
              <LineText fontSize="16px" color="#007AFF">
                {value ?? emptyField}
              </LineText>
            </a>
          </RenderWithCondition>
        </ItemBlock>
      );
    });
  };

  return (
    <ContainerInfo className="flex">
      <Container className="flex">{renderRow(infoMap)}</Container>
    </ContainerInfo>
  );
};

const Container = styled.div`
  flex-direction: column;
  gap: 20px;
`;
const ItemBlock = styled.div`
  flex-direction: column;
`;
const ContainerInfo = styled.div`
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  @media screen and (max-width: 800px) {
    padding: 30px 16px;
  }
`;
