import React, { FC, useEffect, useRef, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SelectDateMonth } from '@components/shared/selectDate/SelectDateMonth';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { BarStatChart } from '@components/shared/barChart/BarStatChart';
import { addEndMonthBusinessDate, addStartMonthBusinessDate, selectDynamicsSlice } from '@store/dynamics/slice';
import { getMonthBusinessGoals } from '@store/dynamics/api';
import { formattedDateMonth } from '@components/screens/home/statsUtils';
import { LineText } from '@components/commons/commonsStyled';
import { CURRENT_USER_ID } from '@configs/consts';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import { ChartWrap } from './RadarChartPersonCompleteness';
import { ContainerChart } from '@components/screens/home/components/chartPlanComplete/ChartPlanComplete';
import { useGetMonthBusinessGoalsQuery } from '@store/dynamics/dynamics.api';
import { useAppSelector } from '@store/hooks';
import { selectUserSlice } from '@store/user/slice';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import styled from 'styled-components';

interface IBarChartMonthBusinessGoals {
  tooltip?: ({ active, payload, label }: any) => JSX.Element | null;
  position: CalendarPosition;
  isLoadingPage?: boolean;
}

const BarChartMonthBusinessGoals: FC<IBarChartMonthBusinessGoals> = (props) => {
  const [paramsStatisticMonth, setParamsStatisticMonth] = useState<Record<string, string>>({});

  const { monthBusinessGoalsPeriod, isLoadingBusinessGoals } = useAppSelector(selectDynamicsSlice);
  const { currentUser } = useAppSelector(selectUserSlice);

  const currentUserId = currentUser?.id ? currentUser.id : localStorage.getItem(CURRENT_USER_ID);
  const prevPeriod = useRef(monthBusinessGoalsPeriod);

  const { data: monthBusinessGoals } = useGetMonthBusinessGoalsQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });

  const dispatch = useDispatch();

  const handleStartData = (event: Dayjs) => {
    dispatch(addStartMonthBusinessDate(event));
  };
  const handleEndData = (event: Dayjs) => {
    dispatch(addEndMonthBusinessDate(event));
  };

  useEffect(() => {
    if (isEmpty(monthBusinessGoals) && isEmpty(monthBusinessGoalsPeriod)) {
      dispatch(addStartMonthBusinessDate(dayjs().add(-6, 'month')));
      dispatch(addEndMonthBusinessDate(dayjs()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(monthBusinessGoalsPeriod)) {
      const paramsString = new URLSearchParams({
        startYearMonth: dayjs(monthBusinessGoalsPeriod.startDate).format('YYYY-MM'),
        endYearMonth: dayjs(monthBusinessGoalsPeriod.endDate).format('YYYY-MM'),
      }).toString();
      if (!isEqual(prevPeriod.current, monthBusinessGoalsPeriod) && currentUserId) {
        setParamsStatisticMonth({ params: paramsString, id: currentUserId });
        dispatch(getMonthBusinessGoals({ params: paramsString, id: currentUserId }));
      }
      prevPeriod.current = monthBusinessGoalsPeriod;
    }
  }, [monthBusinessGoalsPeriod]);

  return (
    <ContainerChart className="flex">
      <HeaderChart className="flex">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="businessGoals" />
        </LineText>
        <GroupSelect className="flex">
          <SelectDateMonth
            type="input"
            onChange={handleStartData}
            id="firstEntryDateStartRange"
            value={monthBusinessGoalsPeriod.startDate}
            position={props.position}
          />
          <span>-</span>
          <SelectDateMonth
            type="input"
            id="firstEntryDateStartRange"
            onChange={handleEndData}
            value={monthBusinessGoalsPeriod.endDate}
            position={props.position}
          />
        </GroupSelect>
      </HeaderChart>
      <ChartWrap className="flex relative" loading={props.isLoadingPage ?? false}>
        <RenderWithCondition condition={!props.isLoadingPage}>
          <BarStatChart data={formattedDateMonth(monthBusinessGoals)} externalTooltip={props.tooltip} />
        </RenderWithCondition>
        <LocalLoader condition={isLoadingBusinessGoals || (props.isLoadingPage ?? false)} size={25} />
      </ChartWrap>
    </ContainerChart>
  );
};

export default BarChartMonthBusinessGoals;

const HeaderChart = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  padding: 5px 0 20px 0;
  @media screen and (max-width: 1560px) {
    flex-direction: column;
    align-items: start;
  }
`;
const GroupSelect = styled.div`
  gap: 15px;
  align-items: center;
  position: relative;
`;
