import { apiBaseQuery } from '../apiBaseQuery';
import { IStateUser } from '../../interfaces/user.interfaces';
import { SERVICE_URL } from '@components/constants/constants';

export interface Response {
  content: IStateUser[];
}
export interface ResponseAvatar {
  data: BlobPart;
}

export interface IAdditionalAdmin {
  id: string;
  data: IAditAdmin;
}
export interface IChangePassword {
  id: string;
  data: { password: string };
}
export interface IDeleteAdmin {
  id: string;
  data: IDelAdmin;
}
export interface IDelAdmin {
  type: string;
  id: string;
  login: string;
}
export interface IAditAdmin {
  login: string;
  isAdmin: string;
  password: string;
  type: string;
  firstName: string;
  lastName: string;
}

export const adminApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getAdminsList: builder.query({
      query: (params) => ({
        url: `${SERVICE_URL}/users/page?`,
        params,
      }),

      providesTags: ['Admin'],
      transformResponse: (response: Response) => {
        return response.content;
      },
    }),

    getAvatarFile: builder.query({
      query: ({ id, params }) => ({
        url: `api/s3-storage/v1/file/download/${id}?${params}`,
        params,
      }),

      transformResponse: (response: ResponseAvatar) => {
        const blob = new Blob([response.data]);
        return URL.createObjectURL(blob);
      },
    }),

    addAdmin: builder.mutation<void, IAdditionalAdmin>({
      query: ({ id, data }) => ({
        body: data,
        url: `${SERVICE_URL}/accounts/by-user/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Admin'],
    }),

    deleteAdmin: builder.mutation<void, IDeleteAdmin>({
      query: ({ id, data }) => ({
        body: data,
        url: `${SERVICE_URL}/accounts/by-user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Admin'],
    }),

    updatePassword: builder.mutation<void, IChangePassword>({
      query: ({ id, data }) => ({
        body: data,
        url: `${SERVICE_URL}/accounts/${id}/change-password`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetAdminsListQuery,
  useAddAdminMutation,
  useUpdatePasswordMutation,
  useDeleteAdminMutation,
  useGetAvatarFileQuery,
} = adminApi;
