import { useEffect, useRef } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Admin } from '@components/screens/admin';
import { Others } from '@components/screens/others';
import { Auth } from '@components/screens/auth';
import { checkAuth, getMainUser, getStatusUser } from '@store/auth/api';
import { AuthCheckCode } from '@components/screens/auth/AuthCheckCode';
import { AuthAdmin } from '@components/screens/auth/AuthAdmin';
import { Home } from '@components/screens/home/Home';
import { Employees } from '@components/screens/employees';
import cookieService from '../../services/cookie.service';
import qs from 'query-string';
import { ACCESS_TOKEN, HAVE_EMPLOYEES, SUPERVISOR, mobile, tablet } from '@components/constants/constants';
import { RootState } from '@store/store';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useAuthSelector } from '@store/auth/selectors';
import React from 'react';
import { loginFromDTApp } from '@store/auth/slice';
import Month from '@components/screens/month/Month';
import { CURRENT_USER_ID } from '@configs/consts';
import Layout from '@components/layout/Layout';
import { addCurrentUser } from '@store/user/slice';
import { resetDynamicsState } from '@store/dynamics/slice';
import { setState } from '@store/device/device.slice';
import { colorsVar } from '@components/constants/colorVariables';
import { Assistants } from '@components/screens/assistants';
import styled from 'styled-components';
import { Groups } from '@components/screens/groups';

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, supervisor, haveEmployees } = useSelector((state: RootState) => state.auth);
  const { mainUser } = useAuthSelector((state) => state.auth);

  const [isLoading, setIsLoading] = React.useState(true);

  const getIsAdmin = async (login: any) => {
    try {
      const response = await dispatch(getStatusUser(login));
      const isAdmin = response.payload.response.isAdmin;
      const isError = response.payload.response.error;

      if (isAdmin && !isError) {
        const options = { path: '/' };
        cookieService.set(SUPERVISOR, '', options);
      } else {
        const options = { path: '/' };
        cookieService.set(SUPERVISOR, SUPERVISOR, options);
      }
    } catch (err) {
      console.log('handleAuth err', err);
    }
  };
  useEffect(() => {
    (async () => {
      const search = window.location.search;
      const queryParams = qs.parse(search);
      const { access_token, refresh_token, login, isWebApp } = queryParams;

      if (login) {
        await getIsAdmin(login);
      }

      if (access_token && refresh_token) {
        const options = { path: '/' };
        cookieService.set('access_token', access_token as string, options);
        cookieService.set('refresh_token', refresh_token as string, options);

        if (!isWebApp) {
          dispatch(loginFromDTApp());
        }
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    dispatch(checkAuth());
    if (isLoggedIn) {
      dispatch(getMainUser());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getMainUser());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!haveEmployees && supervisor && !isLoading) {
      if (mainUser.id) {
        localStorage.setItem(CURRENT_USER_ID, mainUser.id);
        dispatch(addCurrentUser(mainUser));
        dispatch(resetDynamicsState(mainUser));
      }
    }
  }, [haveEmployees, isLoggedIn, mainUser, isLoading]);

  const ref = useRef<HTMLDivElement | null | any>(null);
  function isRetinaDisplay() {
    return window.devicePixelRatio >= 2;
  }

  useEffect(() => {
    dispatch(
      setState({
        isRetina: isRetinaDisplay(),
      }),
    );
  }, []);

  const updateWindowSize = () => {
    setTimeout(() => {
      dispatch(setState({ width: window.innerWidth }));
    }, 500);
  };
  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    window.addEventListener('change', updateWindowSize);
    window.addEventListener('orientationchange', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
      window.removeEventListener('change', updateWindowSize);
      window.removeEventListener('orientationchange', updateWindowSize);
    };
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    dispatch(setState({ width: window.innerWidth, height: window.innerHeight }));
  }, [ref.current]);

  if (!isLoggedIn && !cookieService.get(ACCESS_TOKEN)) {
    return (
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route path="/check" element={<AuthCheckCode />} />
        <Route path="/auth-admin" element={<AuthAdmin />} />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Routes>
    );
  }
  return (
    <MainWrapper ref={ref}>
      <MainContent>
        <Layout>
          <>
            <RenderWithCondition condition={!cookieService.get(SUPERVISOR) && !isLoading}>
              <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/favorites" element={<Favorits />} /> */}
                <Route path="/employees" element={<Employees />} />
                <Route path="/assistants" element={<Assistants />} />
                <Route path="/groups" element={<Groups />} />
                <Route path="/others" element={<Others />} />
                <Route path="/employees/month/*" element={<Month />} />
                <Route path="/admin" element={<Admin />} />
                {/* <Route path="/logs" element={<Logs />} /> */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </RenderWithCondition>
            <RenderWithCondition condition={cookieService.get(SUPERVISOR) && !isLoading}>
              <>
                <RenderWithCondition condition={cookieService.get(HAVE_EMPLOYEES)}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/employees" element={<Employees />} />
                    <Route path="/employees/month/*" element={<Month />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </RenderWithCondition>
                <RenderWithCondition condition={!cookieService.get(HAVE_EMPLOYEES)}>
                  <Routes>
                    <Route path="/employees/month/*" element={<Month />} />
                    <Route path="*" element={<Navigate to="/employees/month/month" />} />
                  </Routes>
                </RenderWithCondition>
              </>
            </RenderWithCondition>
          </>
        </Layout>
      </MainContent>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: ${colorsVar.greyBase};
`;
const MainContent = styled.div`
  width: calc(100% - 2px);
  max-width: 1920px;
  margin: auto auto;
  display: grid;
  position: relative;
`;
