import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { IconWrap } from './NavBar.Exit';
import { Container } from './NavBar.Avatar';
import { ReactComponent as StatsIcon } from '@assets/icons/stats.svg';
import { ReactComponent as UserIcon } from '@assets/icons/user.svg';
import { ReactComponent as AssistIcon } from '@assets/icons/assist__green.svg';
import { ReactComponent as GroupUsersIcon } from '@assets/icons/group-users.svg';
import { ReactComponent as GroupIcon } from '@assets/icons/groups.svg';
import { ReactComponent as KeyIcon } from '@assets/icons/key.svg';
import { uniqueId } from 'lodash';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';

export const navbarAdmin = [
  {
    title: 'statistic',
    path: '/',
    icon: <StatsIcon fill={colorsVar.grey4} />,
    iconActive: <StatsIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'employees',
    path: '/employees',
    icon: <UserIcon fill={colorsVar.grey4} />,
    iconActive: <UserIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'assistants',
    path: '/assistants',
    icon: <AssistIcon fill={colorsVar.grey4} />,
    iconActive: <AssistIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'groups',
    path: 'groups',
    icon: <GroupIcon fill={colorsVar.grey4} />,
    iconActive: <GroupIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'thirdParty',
    path: 'others',
    icon: <GroupUsersIcon fill={colorsVar.grey4} />,
    iconActive: <GroupUsersIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'administrators',
    path: 'admin',
    icon: <KeyIcon fill={colorsVar.grey4} />,
    iconActive: <KeyIcon fill={colorsVar.greenBase} />,
  },
];

export const navbarSupervisorWithEmployees = [
  {
    title: 'statistic',
    path: '/',
    icon: <StatsIcon fill={colorsVar.grey4} />,
    iconActive: <StatsIcon fill={colorsVar.greenBase} />,
  },
  {
    title: 'employees',
    path: '/employees',
    icon: <UserIcon fill={colorsVar.grey4} />,
    iconActive: <UserIcon fill={colorsVar.greenBase} />,
  },
];

interface INavBarLink {
  hidden: boolean;
  haveEmployees: boolean;
  supervisor: boolean;
}

const NavBarLink: FC<INavBarLink> = (props) => {
  const navbarSupervisor = props.haveEmployees ? navbarSupervisorWithEmployees : [];
  const mapNavBar = props.supervisor ? navbarSupervisor : navbarAdmin;

  return (
    <>
      {mapNavBar.map((linkLine) => (
        <NavLink to={linkLine.path} key={uniqueId(linkLine.title)}>
          {({ isActive }) => (
            <ContainerLink className="flex" active={isActive}>
              <IconWrap className="flex">{isActive ? linkLine.iconActive : linkLine.icon}</IconWrap>
              <LineText fontSize={'16px'}>
                <FormattedMessage id={linkLine.title} />
              </LineText>
            </ContainerLink>
          )}
        </NavLink>
      ))}
    </>
  );
};

export default NavBarLink;

const ContainerLink = styled(Container)<{ active: boolean }>`
  padding-left: 14px;
  background-color: ${(props) => (props.active ? colorsVar.grey1 : null)};
  border-radius: 3px;
  transition: background-color 0.5s ease;
  &:hover {
    background-color: ${(props) => (props.active ? null : colorsVar.violetHover)};
    transition: background-color 0.5s ease;
  }
`;
