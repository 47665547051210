import { apiBaseQuery } from '../apiBaseQuery';
import { IStateUser } from '../../interfaces/user.interfaces';
import { ADMIN_URL, SERVICE_URL } from '@components/constants/constants';
import { IExcludeUser, IUpdateUser } from '@store/user/user.api';

export interface Response {
  content: IStateUser[];
}

export const otherApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getOthersList: builder.query({
      query: (params) => ({
        url: `${SERVICE_URL}/users/page?`,
        params,
      }),

      providesTags: ['Other'],
      transformResponse: (response: Response) => {
        return response.content;
      },
    }),

    getSpaceIds: builder.query({
      query: () => ({
        url: `${ADMIN_URL}/spaces`,
      }),
    }),

    updateOther: builder.mutation<void, IUpdateUser>({
      query: ({ id, data }) => ({
        id,
        body: data,
        url: `${SERVICE_URL}/users/${id}`,
        method: 'PATCH',
      }),

      invalidatesTags: ['Other'],
    }),

    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `${SERVICE_URL}/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Other'],
    }),

    addOtherIntoEmployees: builder.mutation<void, IExcludeUser>({
      query: ({ id, spaceId }) => ({
        url: `${ADMIN_URL}/spaces/${spaceId}/include/users/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Other', 'User'],
    }),

    createOther: builder.mutation<void, Pick<IStateUser, 'id'> & Partial<IStateUser>>({
      query: (other) => ({
        body: other,
        url: `${SERVICE_URL}/accounts`,
        method: 'POST',
      }),
      invalidatesTags: ['Other'],
    }),
  }),
});

export const {
  useGetOthersListQuery,
  useGetSpaceIdsQuery,
  useUpdateOtherMutation,
  useDeleteUserMutation,
  useAddOtherIntoEmployeesMutation,
  useCreateOtherMutation,
} = otherApi;
