import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { listPersonalGoals, personalGoalsData } from '../../constants';
import { LineStatChart } from '@components/shared/lineChart/LineStatChart';
import { formattedDateMonth, getRenderLines } from '../../statsUtils';
import { addEndPersonalDate, addStartPersonalDate, selectStatsSlice } from '@store/statistic/slice';
import { colorsVar } from '@components/constants/colorVariables';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import ControlBlockChart from '../ControlBlockChart';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useGetPersonalGoalsQuery } from '@store/statistic/statistic.api';
import { useAppSelector } from '@store/hooks';

interface ChartPersonalGoalsProps {
  tooltip: ({ active, payload, label }: any) => JSX.Element | null;
  position: CalendarPosition;
  isLoadingPage: boolean;
}

export const ChartPersonalGoals = ({ tooltip, position, isLoadingPage }: ChartPersonalGoalsProps) => {
  const [personalGoalsParams, setPersonalGoalsParams] = useState('');

  const { personalGoalsPeriod, selectedGoals, isFavorites } = useAppSelector(selectStatsSlice);

  const {
    data: personalGoalsMonth,
    isLoading: isLoadingPersonalGoals,
    isFetching: isFetchingPersonalGoals,
  } = useGetPersonalGoalsQuery(personalGoalsParams, {
    skip: !personalGoalsParams,
  });

  const prevPeriod = useRef(personalGoalsPeriod);

  const dispatch = useDispatch();

  const handleStartDate = (event: dayjs.Dayjs) => {
    dispatch(addStartPersonalDate(event));
  };
  const handleEndDate = (event: dayjs.Dayjs) => {
    dispatch(addEndPersonalDate(event));
  };

  useEffect(() => {
    if (isEmpty(personalGoalsMonth) && isEmpty(personalGoalsPeriod)) {
      dispatch(addStartPersonalDate(dayjs().add(-12, 'month')));
      dispatch(addEndPersonalDate(dayjs()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(personalGoalsPeriod)) {
      const value = isFavorites ? 'true' : 'false';
      const params = new URLSearchParams();
      listPersonalGoals.forEach((goal) => {
        params.append('category', goal);
      });
      params.append('startYearMonth', dayjs(personalGoalsPeriod?.startDate).format('YYYY-MM'));
      params.append('endYearMonth', dayjs(personalGoalsPeriod?.endDate).format('YYYY-MM'));
      params.append('favoritesOnly', value);
      const paramsString = params.toString();
      setPersonalGoalsParams(paramsString);
      prevPeriod.current = personalGoalsPeriod;
    }
  }, [personalGoalsPeriod, isFavorites]);

  return (
    <>
      <div className="graf-task">
        <ControlBlockChart
          onStartDate={handleStartDate}
          onEndDate={handleEndDate}
          startDate={personalGoalsPeriod?.startDate}
          endDate={personalGoalsPeriod?.endDate}
          position={position}
          textTitle="personalGoals"
          openSelectLines
        />
        <div className="graf-item">
          <span style={{ paddingLeft: 60, height: 10 }}></span>
          <RenderWithCondition condition={!isLoadingPage}>
            <LineStatChart
              fill={colorsVar.textColor}
              dot={{ r: 3 }}
              pointXaxis="yearMonth"
              data={formattedDateMonth(getRenderLines(selectedGoals, personalGoalsMonth))}
              renderLine={personalGoalsData}
              externalTooltip={tooltip}
            />
          </RenderWithCondition>
          <LocalLoader
            condition={isLoadingPersonalGoals || isFetchingPersonalGoals || (isLoadingPage ?? false)}
            size={25}
          />
        </div>
      </div>
    </>
  );
};
