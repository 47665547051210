import { apiBaseQuery } from '../apiBaseQuery';
import { IDepartment, IStateUser, IUserStatistic } from '../../interfaces/user.interfaces';
import { DASHBOARD_SERVICE_URL, SERVICE_URL } from '@components/constants/constants';

export interface Response {
  content: IStateUser[];
}
export interface IUpdateUser {
  id: string;
  data: Partial<IStateUser>;
}
export interface IExcludeUser {
  id: string;
  spaceId: string;
}
export interface IUserGoalsQuery {
  id: string;
  yearMonth: string;
}
export interface ICreateUser {
  login: string;
  lastName: string;
  firstName: string;
  middleName: string;
  departmentId: string | null;
  isDogmaEmployee: boolean;
  type?: string;
}

export const userApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getUsersList: builder.query({
      query: (params) => ({
        url: `${SERVICE_URL}/users/page?`,
        params,
      }),

      providesTags: ['User'],
      transformResponse: (response: Response) => {
        return response.content;
      },
    }),
    getUserInfo: builder.query<IStateUser, string>({
      query: (id) => ({
        url: `${SERVICE_URL}/users/${id}`,
      }),
      providesTags: ['UserInfo'],
    }),

    getUserStatistic: builder.query<IUserStatistic, string>({
      query: (id) => ({
        url: `${DASHBOARD_SERVICE_URL}/users/${id}`,
      }),
    }),

    getUserMonthGoal: builder.query<any, Record<string, string>>({
      query: ({ id, params }) => {
        return {
          url: `${SERVICE_URL}/month-goals/user/${id}?${params}`,
        };
      },
      transformResponse: (response: Response) => {
        return response.content;
      },
    }),

    getDepartmentsList: builder.query<IDepartment[], null>({
      query: () => ({
        url: `${SERVICE_URL}/departments`,
      }),
      providesTags: ['Departments'],
    }),

    createUser: builder.mutation<void, ICreateUser>({
      query: (user) => ({
        body: user,
        url: `${SERVICE_URL}/accounts`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<void, IUpdateUser>({
      query: ({ id, data }) => ({
        id,
        body: data,
        url: `${SERVICE_URL}/users/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['User'],
      async onQueryStarted({ id, ...data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiBaseQuery.util.updateQueryData('getUsers', id, (draft) => {
            Object.assign(draft, data);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    excludeUser: builder.mutation<void, IExcludeUser>({
      query: ({ id, spaceId }) => ({
        url: `${SERVICE_URL}/spaces/${spaceId}/users/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['User', 'Other'],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useGetUsersListQuery,
  useGetDepartmentsListQuery,
  useUpdateUserMutation,
  useExcludeUserMutation,
  useGetUserInfoQuery,
  useGetUserStatisticQuery,
  useGetUserMonthGoalQuery,
} = userApi;
