import React, { useEffect, useState } from 'react';

import { RenderTableWithCondition } from '@components/hoc/RenderTableWithCondition';
import { FormattedMessage } from 'react-intl';
import { formattedTooltip } from './statsUtils';
import { TooltipChart } from './components/tooltips/TooltipChart';
import { LineChartUsers } from './components/chartUsers/LineChartUsers';
import { PieChartTasks } from './components/pieChartTasks';
import { PieChartBusiness } from './components/pieChartBusiness';
import { ChartPlanComplete } from './components/chartPlanComplete';
import { ChartBusinessGoals } from './components/chartBusinessGoals';
import { ChartPersonalGoals } from './components/chartPersonalGoals';
import { RadioButtonsCheck } from '@ui/radioButtonsCheck';
import { closureTasksData, completeTasksData, creationTasksData, planedTasksData } from './constants';
import {
  addEndClosureDate,
  addEndCompleteDate,
  addEndCreationDate,
  addEndPlanedDate,
  addStartClosureDate,
  addStartCompleteDate,
  addStartCreationDate,
  addStartPlanedDate,
  selectStatsSlice,
} from '@store/statistic/slice';
import { ChartTask } from './components/сhartTask';
import './home.scss';
import { colorsVar } from '@components/constants/colorVariables';
import { mapCompleteTasks, mapPlanedTasks } from '../month/components/tableDynamics/charts/constants';
import { useAppSelector } from '@store/hooks';
import { selectDeviceSlice } from '@store/device/device.slice';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';
import {
  useGetClosureTasksQuery,
  useGetCompletionTasksQuery,
  useGetCreationTasksQuery,
  useGetOpenTasksQuery,
} from '@store/statistic/statistic.api';

export const commonTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return <TooltipChart label={label} payload={payload} formattedTooltip={formattedTooltip} />;
  }
  return null;
};

export const Home = () => {
  const { width, isMobile } = useWidthScreens();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [planedTasksParams, setPlanedTasksParams] = useState('');
  const [completionTasksParams, setCompletionTasksParams] = useState('');
  const [creationTasksParams, setCreationTasksParams] = useState('');
  const [closureTasksParams, setClosureTasksParams] = useState('');

  const {
    data: planedTasks,
    isLoading: isLoadingPlanedTasks,
    isFetching: isFetchingPlanedTasks,
  } = useGetOpenTasksQuery(planedTasksParams, {
    skip: !planedTasksParams,
  });
  const {
    data: creationTasks,
    isLoading: isLoadingCreationTasks,
    isFetching: isFetchingCreationTasks,
  } = useGetCreationTasksQuery(creationTasksParams, {
    skip: !creationTasksParams,
  });
  const {
    data: closureTasks,
    isLoading: isLoadingClosureTasks,
    isFetching: isFetchingClosureTasks,
  } = useGetClosureTasksQuery(closureTasksParams, {
    skip: !closureTasksParams,
  });

  const {
    data: completeTasks,
    isLoading: isLoadingCompleteTasks,
    isFetching: isFetchingCompleteTasks,
  } = useGetCompletionTasksQuery(completionTasksParams, {
    skip: !completionTasksParams,
  });

  const {
    creationTasksPeriod,
    closureTasksPeriod,
    isFavorites,
    planedTasksPeriod,
    completeTasksPeriod,
  } = useAppSelector(selectStatsSlice);

  const { isHiddenNavbar } = useAppSelector(selectDeviceSlice);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, [isHiddenNavbar, width]);

  return (
    <ScrollWrapper className="flex ">
      <div className="stats-container">
        <RenderTableWithCondition condition={false}>
          <>
            <div className="users-wrap">
              <div className={`flex flex-col ${isMobile ? 'pl-12' : ''}`}>
                <h1>
                  <FormattedMessage id="statisticTitle" />
                </h1>
              </div>
              <div className="users-title">
                <h2>
                  <FormattedMessage id="amountActiveUsers" />
                </h2>
                <span>
                  <span style={{ color: colorsVar.redLine }}>*</span>
                  <FormattedMessage id="aboutActiveUsers" />
                </span>
              </div>
              <div className="graf-container">
                <LineChartUsers tooltip={commonTooltip} position="right" isLoadingPage={isLoading} />
              </div>
            </div>
          </>
        </RenderTableWithCondition>
        <RenderTableWithCondition condition={false}>
          <>
            <div className="users-wrap tasks goals">
              <div className="graf-container">
                <PieChartTasks position="left" isLoadingPage={isLoading} />
                <PieChartBusiness position="left" isLoadingPage={isLoading} />
              </div>
            </div>
          </>
        </RenderTableWithCondition>
        <RenderTableWithCondition condition={false}>
          <>
            <div className="users-wrap tasks">
              <div className="title-dep">
                <h1>
                  <FormattedMessage id="statisticTitle2" />
                </h1>
                <div className=" task-filter">
                  {/* //TODO: пока не показывать */}
                  {/* <RadioButtonsCheck />   */}
                </div>
              </div>
              <>
                <div className="users-title second">
                  <h2>
                    <FormattedMessage id="businessTasks" />
                  </h2>
                </div>
              </>
              <div className="graf-container">
                <>
                  <ChartItem className="flex">
                    <ChartPlanComplete
                      planed={true}
                      data={planedTasks ?? []}
                      tooltip={commonTooltip}
                      getData={setPlanedTasksParams}
                      period={planedTasksPeriod}
                      renderLine={planedTasksData}
                      setEndDate={addEndPlanedDate}
                      isLoading={isLoadingPlanedTasks || isFetchingPlanedTasks}
                      setStartDate={addStartPlanedDate}
                      titleChart="dynamicsPlaningTasks"
                      lineRectanglesData={mapPlanedTasks}
                      wrap
                      position="right"
                      isLoadingPage={isLoading}
                    />
                  </ChartItem>
                  <ChartItem className="flex">
                    <ChartPlanComplete
                      data={completeTasks ?? []}
                      tooltip={commonTooltip}
                      getData={setCompletionTasksParams}
                      period={completeTasksPeriod}
                      renderLine={completeTasksData}
                      setEndDate={addEndCompleteDate}
                      isLoading={isLoadingCompleteTasks || isFetchingCompleteTasks}
                      setStartDate={addStartCompleteDate}
                      titleChart="closePlansDynamics"
                      lineRectanglesData={mapCompleteTasks}
                      wrap
                      position="right"
                      isLoadingPage={isLoading}
                    />
                  </ChartItem>
                  <ChartTask
                    data={creationTasks ?? []}
                    tooltip={commonTooltip}
                    nameLine="totalCreation"
                    isFavorites={isFavorites}
                    getData={setCreationTasksParams}
                    period={creationTasksPeriod}
                    renderLine={creationTasksData}
                    titleChart="createTasksDynamics"
                    setEndDate={addEndCreationDate}
                    isLoading={isLoadingCreationTasks || isFetchingCreationTasks}
                    setStartDate={addStartCreationDate}
                    position="right"
                    isLoadingPage={isLoading}
                  />
                  <ChartTask
                    data={closureTasks ?? []}
                    nameLine="totalClosure"
                    tooltip={commonTooltip}
                    getData={setClosureTasksParams}
                    isFavorites={isFavorites}
                    titleChart="closedDynamics"
                    period={closureTasksPeriod}
                    renderLine={closureTasksData}
                    setEndDate={addEndClosureDate}
                    isLoading={isLoadingClosureTasks || isFetchingClosureTasks}
                    setStartDate={addStartClosureDate}
                    position="right"
                    isLoadingPage={isLoading}
                  />
                  <ChartBusinessGoals
                    tooltip={commonTooltip}
                    position={isMobile ? 'right' : 'left'}
                    isLoadingPage={isLoading}
                  />
                  <ChartPersonalGoals tooltip={commonTooltip} position="left" isLoadingPage={isLoading} />
                </>
              </div>
            </div>
          </>
        </RenderTableWithCondition>
      </div>
    </ScrollWrapper>
  );
};

const ChartItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  @media screen and (max-width: 1169px) {
    width: 100%;
  }
`;

const ScrollWrapper = styled.div`
  flex-direction: column;
  overflow-x: auto;
  @media screen and (max-width: 800px) {
    width: 100vw;
  }
`;
