import React, { FC, useEffect, useState } from 'react';

import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface IHeaderTooltip {
  className?: string;
  isAssist?: boolean;
  dataTooltip: { text: string; isOpen: boolean } | null;
}

const HeaderTooltip: FC<IHeaderTooltip> = (props) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (props.dataTooltip) {
      setOpenTooltip(props.dataTooltip.isOpen);
    }
  }, [props.dataTooltip]);

  return (
    <TooltipWrap className={openTooltip ? props.className : ''}>
      <LineText fontSize={'12px'}>
        {props.dataTooltip?.text ? <FormattedMessage id={props.dataTooltip?.text} /> : ''}
      </LineText>
    </TooltipWrap>
  );
};
export default HeaderTooltip;

const TooltipWrap = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  position: absolute;
  height: 27px;
  bottom: 17px;
  min-width: 100px;
  left: 25px;
  z-index: 5;
  border-radius: 3px;
  padding: 4px 6px;
  -webkit-box-shadow: 0px 16px 24px 0px ${colorsVar.shadowsTooltip};
  -moz-box-shadow: 0px 16px 24px 0px ${colorsVar.shadowsTooltip};
  box-shadow: 0px 16px 24px 0px ${colorsVar.shadowsTooltip};
  background-color: ${colorsVar.white};
  scale: 0;
  opacity: 0;
  transition: all 0.5s ease;

  &.supervisor {
    scale: 1;
    opacity: 1;
    transition: all 0.5s ease;
    left: 55px;
  }
  &.supervisor__sm {
    scale: 1;
    opacity: 1;
    transition: all 0.5s ease;
    left: 25px;
    bottom: 0px;
  }
  &.assistant {
    scale: 1;
    opacity: 1;
    transition: all 0.5s ease;
    left: 55px;
  }
  &.assistant__sm {
    scale: 1;
    opacity: 1;
    transition: all 0.5s ease;
    left: 35px;
    bottom: 0px;
    justify-content: center;
  }
  &.open_delete {
    scale: 1;
    opacity: 1;
    transition: all 0.5s ease;
    bottom: 0px;
    left: 35px;
    justify-content: center;
  }
  &.on_line {
    bottom: 7px;
  }
`;
