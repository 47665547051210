import React, { FC, useState } from 'react';

import { IMapUserInfo } from 'interfaces/stats.interfaces';
import { uniqueId } from 'lodash';
import HeaderUserInfo from './Header.UserInfo';
import ItemUserInfo from './Item.UserInfo';
import { colorsVar } from '@components/constants/colorVariables';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { IConnectionAssist, IStateUser } from 'interfaces/user.interfaces';
import { useAuthSelector } from '@store/auth/selectors';
import { mobile } from '@components/constants/constants';
import styled from 'styled-components';

interface IUserInfo {
  userNamesLine: string | null;
  urlAvatar?: string;
  mapItemInfo: IMapUserInfo[];
  mapBlockInfo: IMapUserInfo[][];
  connectionAssist: IConnectionAssist | null;
}

const UserInfo: FC<IUserInfo> = (props) => {
  const { isWideScreen } = useWidthScreens();
  const { mainUser, supervisor } = useAuthSelector((state) => state.auth);

  const [isVisible, setIsVisible] = useState(isWideScreen);
  const [openAssistPopup, setAssistPopup] = useState<boolean | null>(false);

  function getUserNamesLine(data: IStateUser | null): string {
    if (!data) return '';
    return `${data?.lastName ?? ''} ${data?.firstName ?? ''} ${data?.middleName ?? ''}`.trim();
  }

  return (
    <Container className={`flex ${isWideScreen ? 'min-w-[361px] max-w-[361px]' : 'w-[98%]'}`}>
      <HeaderUserInfo
        userNamesLine={supervisor ? getUserNamesLine(mainUser) : props.userNamesLine}
        urlAvatar={props.urlAvatar}
        isWideScreen={isWideScreen}
        onOpen={setIsVisible}
        isOpen={isVisible}
        setIsVisibleFilter={setAssistPopup}
        connectionAssist={props.connectionAssist}
      />
      <AccordInfoWrap className="flex flex-col" active={isVisible}>
        <ContainerBlock>
          <ItemUserInfo mapData={props.mapItemInfo} />
        </ContainerBlock>
        {props.mapBlockInfo.map((block) => (
          <ContainerBlock key={uniqueId('block_')}>
            <div className="flex flex-col py-[10px] px-[0px]">
              <ItemUserInfo mapData={block} />
            </div>
          </ContainerBlock>
        ))}
      </AccordInfoWrap>
    </Container>
  );
};

export default UserInfo;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 290px;
  background-color: ${colorsVar.white};
  border: 1px solid ${colorsVar.grey1};
  box-sizing: border-box;
  @media screen and (max-width: ${mobile}px) {
    padding-left: 0px;
  }
`;

export const ContainerBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  border-bottom: 1px solid ${colorsVar.grey1};
`;
const AccordInfoWrap = styled.div<{ active?: boolean }>`
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.7s linear;
  ${({ active }) =>
    active &&
    `
    max-height: 750px;
    transition: max-height 0.7s linear;
  `}
`;

export const BlockItem = styled.div`
  display: flex;
  padding: 10px 0px 10px 0px;
  gap: 0 20px;
`;

export const LineArrow = styled.div<{ border?: boolean }>`
  width: 100%;
  justify-content: end;
  align-items: center;
  padding: 10px 30px;
  border-bottom: ${(props) => (props.border ? `1px solid ${colorsVar.grey1}` : 'none')};
`;
