import React from 'react';
import AvatarAssist from '@ui/avatarAssist/AvatarAssist';
import useGetUrlAvatar from '@hooks/useGetUrlAvatar';

interface IAvatarCardGroup {
  user: any;
  index?: number;
  isLoading?: boolean;
}

const AvatarCardGroup = ({ user, index, isLoading }: IAvatarCardGroup) => {
  const urlAvatar = useGetUrlAvatar({ fileId: user?.avatarFileId });

  return (
    <div className={`flex ${index ? 'ml-[-10px]' : ''}`}>
      <AvatarAssist size="small-large" urlAvatar={urlAvatar} />
    </div>
  );
};

export default AvatarCardGroup;
