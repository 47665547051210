import React, { useState } from 'react';

import { debounce } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { IStateUser } from 'interfaces/user.interfaces';
import { useGetUsersListQuery } from '@store/user/user.api';
import { LocalLoader } from '@ui/loader/LocalLoader';
import Search from '@ui/search/Search';
import './modalChooseAdmin.scss';

const initialAllUsersParams = new URLSearchParams({
  thirdPartyUsersOnly: 'true',
}).toString();

interface IModalChooseAdmin {
  setIsVisible: (arg: boolean) => void;
  handleChoose: (user: IStateUser) => void;
}

export const ModalChooseAdmin = ({ setIsVisible, handleChoose }: IModalChooseAdmin) => {
  const [search, setSearch] = useState('');
  const [paramsString, setParamsString] = useState<string>(initialAllUsersParams);

  const { data: allUsers, isLoading, isFetching } = useGetUsersListQuery(paramsString);

  const debounceValue = debounce((value) => {
    setSearch(value);
    const paramsString = new URLSearchParams(
      value
        ? {
            query: value,
            thirdPartyUsersOnly: 'true',
          }
        : { adminOnly: 'true' },
    ).toString();
    setParamsString(paramsString);
  }, 2000);

  const handleSearchUsers = (value: string) => {
    debounceValue(value);
  };

  return (
    <div className="choose-admin">
      <h1>
        <FormattedMessage id="selectFromList" />
      </h1>
      <Search handleSearch={handleSearchUsers} isLoading={isFetching} searchValue={search} />
      <div className="list-wrap">
        {allUsers &&
          allUsers.map((user) => (
            <div
              key={user.id}
              onClick={() => handleChoose(user)}
              className="list-item"
            >{`${user?.lastName} ${user?.firstName} ${user?.middleName} `}</div>
          ))}
        <LocalLoader condition={isLoading} />
      </div>
    </div>
  );
};
