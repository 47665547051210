import { ASSISTANT_SETTING_URL, SERVICE_URL } from '@components/constants/constants';
import { http } from '../utils/http';

class AssistService {
  constructor() {}

  //TODO:   ================== RTK Query ==================

  getAssists = async (params: string): Promise<any> => {
    return await http.get(`${SERVICE_URL}/assistants/page?${params}`);
  };

  getManagers = async (params: string): Promise<any> => {
    return await http.get(`${SERVICE_URL}/managers/page?${params}`);
  };

  addAssists = async (data: any): Promise<any> => {
    return await http.post(`${ASSISTANT_SETTING_URL}/managers/add`, data);
  };

  deleteAssists = async (data: any): Promise<any> => {
    return await http.post(`${ASSISTANT_SETTING_URL}/managers/remove`, data);
  };

  updateAssists = async (data: any): Promise<any> => {
    return await http.patch(`${ASSISTANT_SETTING_URL}/managers`, data);
  };

  updateManager = async (data: any): Promise<any> => {
    return await http.patch(`${ASSISTANT_SETTING_URL}/assistants`, data);
  };
}

//TODO:   ================== RTK Query ==================

export const assistService = new AssistService();
