import React from 'react';
import InputMask from 'react-input-mask';
import cn from 'classnames';

import { Select } from '../../../ui/select';
import photo from '@assets/icons/default_photo.png';
import { useFormik } from 'formik';
import { userSchema } from '@components/schemas';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICreateUser } from '@store/user/user.api';
import './modalUser.scss';
import { mobile, tablet } from '@components/constants/constants';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import styled from 'styled-components';
import { colorsVar } from '@components/constants/colorVariables';

interface ModalUserProps {
  options?: string[] | null;
  handleCreate: (data: any) => void;
  setIsVisible: (arg: boolean) => void;
  isEmployee: boolean;
}

export const ModalUser = ({ setIsVisible, handleCreate, options, isEmployee }: ModalUserProps) => {
  const intl = useIntl();

  const { submitForm, handleSubmit, handleChange, values, setFieldValue, errors, touched, handleBlur } = useFormik({
    initialValues: {
      login: '',
      lastName: '',
      firstName: '',
      middleName: '',
      departmentId: null,
      isDogmaEmployee: isEmployee,
    },
    validationSchema: userSchema,
    onSubmit: (values: ICreateUser) => {
      handleCreate(values);
    },
  });

  const handleSelectDepartment = (data: any) => {
    setFieldValue('departmentId', data);
  };

  return (
    <Container>
      <form className="modal-user" onSubmit={handleSubmit} action="">
        <h1>
          <FormattedMessage id="addUser" />
        </h1>
        <div className="body">
          <div>
            <span>
              <FormattedMessage id="phoneNumber" />
            </span>
            <InputMask
              id="login"
              maskChar=" "
              onBlur={handleBlur}
              mask="+7 999 999 99 99"
              value={values.login}
              onChange={handleChange}
              placeholder="+7 (___) ___-__-__"
              className={cn({ 'input-error': errors.login && touched.login })}
            />
            {errors.login && touched.login && (
              <div className="error">
                <span className="line-error">{errors.login}</span>
              </div>
            )}
          </div>
          <div>
            <span>
              <FormattedMessage id="lastName" />
            </span>
            <input
              id="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              placeholder={intl.formatMessage({ id: 'enterLastName' })}
              className={cn({ 'input-error': errors.lastName && touched.lastName })}
            />
            {errors.lastName && touched.lastName && (
              <div className="error">
                <span className="line-error">{errors.lastName}</span>
              </div>
            )}
          </div>
          <div>
            <span>
              <FormattedMessage id="firstName" />
            </span>
            <input
              id="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              placeholder={intl.formatMessage({ id: 'enterFirstName' })}
              className={cn({ 'input-error': errors.firstName && touched.firstName })}
            />
            {errors.firstName && touched.firstName && (
              <div className="error">
                <span className="line-error">{errors.firstName}</span>
              </div>
            )}
          </div>
          <div>
            <span>
              <FormattedMessage id="middleName" />
            </span>
            <input
              id="middleName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.middleName}
              placeholder={intl.formatMessage({ id: 'enterMiddleName' })}
              className={cn({ 'input-error': errors.middleName && touched.middleName })}
            />
            {errors.middleName && touched.middleName && (
              <div className="error">
                <span className="line-error">{errors.middleName}</span>
              </div>
            )}
          </div>
        </div>
        <RenderWithCondition condition={isEmployee}>
          <>
            <div className={cn('body', 'hidden', 'active')}>
              <div className="photo">
                <span>
                  <FormattedMessage id="addPhoto" />
                </span>
                <img src={photo} alt="" />
              </div>
            </div>
            <div className="select-departments">
              <span>
                <FormattedMessage id="subdivision" />
              </span>
              <Select
                type="select"
                id="departmentId"
                options={options ?? undefined}
                onChange={handleSelectDepartment}
              />
            </div>
          </>
        </RenderWithCondition>
        <button type="submit" onClick={submitForm}>
          <FormattedMessage id="save" />
        </button>
      </form>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  width: 580px;
  @media screen and (max-width: ${tablet}px) {
    max-width: 100%;
    max-height: 75dvh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: ${colorsVar.greenScroll} ${colorsVar.grey5};
    scrollbar-width: thin;
    padding-right: 10px;
    padding-bottom: 20px;
    margin-top: 30px;
  }
  @media screen and (max-width: ${mobile}px) {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    width: 100%;
    padding-right: 7px;
  }
`;
