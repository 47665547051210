import { ADMIN_URL, SERVICE_URL } from '@components/constants/constants';
import { http } from '../utils/http';

class AdminService {
  constructor() {}

  //TODO:   ================== RTK Query ==================

  getAdmins = async (params: string): Promise<any> => {
    return await http.get(`${SERVICE_URL}/users/page?${params}`);
  };

  getAllUsers = async (params: string): Promise<any> => {
    return await http.get(`${SERVICE_URL}/users/page?${params}`);
  };

  getSpaceId = async (): Promise<any> => {
    return await http.get(`${ADMIN_URL}/spaces`);
  };

  create = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.post(`${SERVICE_URL}/accounts/by-user/${id}`, data);
  };

  updatePassword = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.post(`${SERVICE_URL}/accounts/${id}/change-password`, data);
  };

  delete = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.delete(`${SERVICE_URL}/accounts/by-user/${id}`, { data: data });
  };
  //TODO:   ================== RTK Query ==================
}

export const adminService = new AdminService();
