import { IDepartment } from 'interfaces/user.interfaces';

export const getOptionsDepartments = (arr: IDepartment[]) => {
  const deps = arr.map((item) => item.name);
  return ['---', ...deps];
};

export const getFilterWithoutNull = (obj: any) => {
  const newObj = { ...obj };
  for (var propName in newObj) {
    if (newObj[propName] === null) {
      delete newObj[propName];
    }
  }
  return newObj;
};

export const getIdDepartment = (arr: IDepartment[] | undefined, dep: string | null) => {
  if (dep === null || dep === '---' || !arr) return null;
  let idDep = '';
  arr.map((item) => {
    if (Object.values(item).includes(dep)) {
      idDep = item.id;
    }
    return item;
  });
  return idDep;
};
