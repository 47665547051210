import { colorsVar } from '@components/constants/colorVariables';
import { formattedMonthTitle } from '@utils/formattedMonthTitle';
import dayjs from 'dayjs';
import { IDynamicsCompletionTasks, IDynamicsPlaningTasks, IMonthBusinessGoals } from 'interfaces/dynamics.interfaces';
import {
  IActiveUsers,
  IBusinessGoalsMonth,
  IClosureTasks,
  ICompleteTasks,
  IPlanedTasks,
  IUsersWithGoals,
  IUsersWithTasks,
} from 'interfaces/stats.interfaces';
import { IUserPersonalGoals } from 'interfaces/user.interfaces';

export const formattedDate = (
  data: IActiveUsers[] | IPlanedTasks[] | ICompleteTasks[] | IDynamicsPlaningTasks[] | IDynamicsCompletionTasks[],
) => {
  return data.map((el) => {
    return { ...el, date: dayjs(el.date).format('DD.MM.YY') };
  });
};

export const formattedDateNotTotal = (data: IActiveUsers[]) => {
  return data.map((item) => {
    return { active: item.active, percentage: item.percentage, date: dayjs(item.date).format('DD.MM.YY') };
  });
};

export const formattedClosurDate = (data: IClosureTasks[], value: string) => {
  return data.map(({ totalTasks, date }) => {
    return { [value]: totalTasks, date: dayjs(date).format('DD.MM.YY') };
  });
};

export const formattedDateMonth = (data: IBusinessGoalsMonth[] | IMonthBusinessGoals[] | undefined) => {
  if (!data) return [];
  return data.map((el, i) => {
    return {
      ...el,
      totalGoals: Number(el.totalGoals),
      yearMonth: formattedMonthTitle('ru', el.yearMonth, 'YY'),
    };
  });
};

export const formattedDataGoalsRadar = (obj: IUserPersonalGoals) => {
  return [
    { subject: 'Дом / Семья', value: obj.familyAndHomeGoals },
    { subject: 'Физическое Здоровье', value: obj.physicalConditionAndHealthGoals },
    { subject: 'Социальные Культурные', value: obj.socialAndCulturalGoals },
    { subject: 'Развитие Самообразование', value: obj.developmentAndSelfEducationGoals },
    { subject: 'Духовные / Этические', value: obj.spiritualAndEthicalGoals },
    { subject: 'Финансы Карьера', value: obj.financeAndCareerGoals },
  ];
};

export const formattedDataTasksPie = (obj: IUsersWithTasks | undefined) => {
  if (!obj) return [];
  return [
    { name: 'С задачами', value: obj.withTasks, fill: colorsVar.blueLine },
    { name: 'Без задач', value: obj.withoutTasks, fill: colorsVar.redLine },
  ];
};

export const formattedDataGoalsPie = (obj: IUsersWithGoals | undefined) => {
  if (!obj) return [];
  return [
    { name: 'С бизнесс-целями', value: obj.withGoals, fill: colorsVar.blueLine },
    { name: 'Без бизнесс-целей', value: obj.withoutGoals, fill: colorsVar.redLine },
  ];
};

export const formattedTooltip = (str: string) => {
  switch (str) {
    case 'total':
      return 'Всего';
    case 'active':
      return 'Активные';
    case 'percentage':
      return 'Процентное';
    case 'delegatedTasks':
      return 'Делегированные';
    case 'personalTasks':
      return 'Личные';
    case 'totalTasks':
      return 'Всего запланировано';
    case 'plannedTasks':
      return 'Занимался по плану';
    case 'unplannedTasks':
      return 'Занимался вне плана';
    case 'totalClosure':
      return 'Закрытых задач';
    case 'totalCreation':
      return 'Открытых задач';
    case 'urgentTasks':
      return 'Срочные';
    case 'importantTasks':
      return 'Важные';
    case 'contactTasks':
      return 'Контакты';
    case 'notCompletedTasks':
      return 'Не занимался';
    case 'FAMILY_AND_HOME':
      return 'Дом / Семья';
    case 'FINANCE_AND_CAREER':
      return 'Финансы / Карьера';
    case 'SPIRITUAL_AND_ETHICAL':
      return 'Духовные';
    case 'SOCIAL_AND_CULTURAL':
      return 'Социальные';
    case 'DEVELOPMENT_AND_SELF_EDUCATION':
      return 'Саморазвитие';
    case 'PHYSICAL_CONDITION_AND_HEALTH':
      return 'Здоровье';
    case 'totalGoals':
      return 'Бизнес-цели';
    case 'externalReasonNotCompletedTasks':
      return 'Не занимался по внешним причинам';
    case 'personalReasonNotCompletedTasks':
      return 'Не занимался по личным причинам';
    default:
      return str;
  }
};

export const getRenderLines = (arr: string[], objDate: any | undefined) => {
  if (!objDate) return [];
  let newDate: any[] = [];
  let rendLine: any[] = [];
  if (Object.keys(objDate).length > 1) {
    arr.forEach((line) => {
      objDate[line].map((data: any) => {
        const { totalGoals, ...rest } = data;
        return newDate.push({ [line]: totalGoals, ...rest });
      });
    });
    objDate.FAMILY_AND_HOME.forEach((line: any) => {
      let obj = {};
      newDate.forEach((data) => {
        if (line.yearMonth === data.yearMonth) {
          obj = { ...obj, ...data };
        }
      });
      rendLine.push(obj);
    });
  }
  return rendLine;
};

export const getPosition = (arr: any[]) => {
  return arr.findIndex((line) => line.active);
};
