import React, { useEffect, useState } from 'react';

import { IStateUser, IUserStatistic } from 'interfaces/user.interfaces';
import { ItemSession } from './ItemSession';
import { useStatsSelector } from '@store/statistic/selectors';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as StopIcon } from '@assets/icons/stop-sign.svg';
import { isEmpty } from 'lodash';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useAuthSelector } from '@store/auth/selectors';
import { CURRENT_USER_ID } from '@configs/consts';
import {
  useGetUserSessionsQuery,
  useRemoveOwnSessionsMutation,
  useRemoveSessionsMutation,
} from '@store/sessions/sessions.api';
import { styled } from 'styled-components';

interface ISessions {
  userStatistic?: IUserStatistic;
  userInfo?: IStateUser;
  isWideScreen?: boolean;
}

interface IProps {
  fontSize?: string;
  bold?: string;
  color?: string;
}

export const Sessions = ({ userStatistic, userInfo, isWideScreen }: ISessions) => {
  const [currentUserId, setCurrentUserId] = useState<string>();
  const [paramsSession, setParamsSession] = useState<string | null>(null);

  const { currentUser } = useStatsSelector((state) => state.user);
  const { mainUser } = useAuthSelector((state) => state.auth);

  const { data: userSessions } = useGetUserSessionsQuery(paramsSession, {
    skip: paramsSession === null,
  });
  const [removeOwnSessions] = useRemoveOwnSessionsMutation();
  const [removeSessions] = useRemoveSessionsMutation();

  useEffect(() => {
    if (currentUser?.id) {
      setCurrentUserId(currentUser?.id);
    } else {
      if (!mainUser.id) {
        const id = localStorage.getItem(CURRENT_USER_ID);
        id && setCurrentUserId(id);
      }
      mainUser.id && setCurrentUserId(mainUser.id);
    }
  }, [currentUser?.id, mainUser.id]);

  useEffect(() => {
    if (currentUserId) {
      const paramsString = new URLSearchParams({
        userId: currentUserId,
      }).toString();
      if (mainUser.id === currentUserId) {
        setParamsSession('');
      } else {
        setParamsSession(paramsString);
      }
    }
  }, [currentUserId, mainUser]);

  const currentSession = React.useMemo(() => userSessions?.filter((session) => session.isCurrent), [userSessions]);

  const activeSessions = React.useMemo(() => userSessions?.filter((session) => !session.isCurrent), [userSessions]);

  const handleDeleteSessions = () => {
    const paramsString = `${userSessions
      ?.filter((session) => !session.isCurrent)
      .map((session) => session.id)
      .join(',')}`;
    if (mainUser.id === currentUser?.id) {
      removeOwnSessions(paramsString);
    } else {
      removeSessions(paramsString);
    }
  };

  return (
    <>
      <Container>
        <RenderWithCondition condition={mainUser.id === currentUserId}>
          <>
            <LineText fontSize="20px" bold="700">
              <FormattedMessage id="currentSession" />
            </LineText>
            <ItemSession
              data={currentSession ?? []}
              buttonHidden={mainUser.id === currentUser?.id && !isEmpty(activeSessions)}
              onClick={handleDeleteSessions}
            />
          </>
        </RenderWithCondition>
        <Spacer px={50} />
        <LineText fontSize="20px" bold="700">
          <FormattedMessage id="activeSessions" />
        </LineText>
        <RenderWithCondition condition={mainUser.id !== currentUser?.id && !isEmpty(activeSessions)}>
          <ContainerButton onClick={handleDeleteSessions}>
            <ButtonWrap>
              <LineText color="#FF3B30" fontSize="16px">
                <Icon>
                  <StopIcon />
                </Icon>
                <FormattedMessage id="endAllSessions" />
              </LineText>
            </ButtonWrap>
          </ContainerButton>
        </RenderWithCondition>
        <ItemSession data={activeSessions ?? []} />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 30px;
  background-color: #f5f9f8;
  border: 1px solid $button-disabled-color;
  box-sizing: border-box;
`;
const LineText = styled.div<IProps>`
  display: flex;
  align-items: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  font-weight: ${(props) => (props.bold ? props.bold : '12px')};
  color: ${(props) => (props.color ? props.color : '#274335')};
  gap: 8px;
`;
const Spacer = styled.div<{ px: number; horizontal?: boolean }>`
  margin-top: ${(props) => (!props.horizontal ? `${props.px}px` : undefined)};
  margin-right: ${(props) => (props.horizontal ? `${props.px}px` : undefined)};
`;

const ButtonWrap = styled.button`
  display: flex;
  width: fit-content;
  min-height: inherit !important;
  background-color: transparent;
  padding: 5px;
  border: 0px;
  &:hover {
    background-color: transparent;
  }
  cursor: pointer;
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
`;
const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 603px;
  gap: 5px;
  padding: 12px;
  background-color: #ffff;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: #e9606014;
  }
`;
