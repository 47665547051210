import React, { FC, useEffect, useState } from 'react';

import { ReactComponent as EraseIcon } from '@assets/icons/erase_icon.svg';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';
import { headerGroups } from '../constants';
import ChipMain from '@ui/chipMain/ChipMain';

interface IControlBlockGroups {
  openForm?: (arg: boolean) => void;
  openFilter?: (arg: boolean) => void;
  handleSearch?: (value: string) => void;
  title?: string;
  isAdmin?: boolean;
  supervisor?: boolean;
  openTab?: boolean;
  textButton?: string;
  isLoading?: boolean;
  searchValue?: string;
  noSearch?: boolean;
}

const ControlBlockGroups: FC<IControlBlockGroups> = ({
  openForm,
  openFilter,
  title,
  handleSearch,
  supervisor,
  isAdmin,
  isLoading,
  searchValue,
  textButton = 'addUser',
  noSearch = false,
}) => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [value, setValue] = useState('');

  const { isMobile } = useWidthScreens();

  function handleSelectGroups(value: string) {
    if (value === 'drop') return setSelectedGroups([]);
    if (value === 'all') return setSelectedGroups(headerGroups.map((item) => item.key));
    if (selectedGroups.includes(value)) {
      setSelectedGroups(selectedGroups.filter((item) => item !== value));
    } else {
      setSelectedGroups([...selectedGroups, value]);
    }
  }

  return (
    <>
      <Wrapper>
        <LineChips>
          <ChipMain clickEvent={() => handleSelectGroups('all')} buttonText={'all'} isDisabled={false} />
          {headerGroups.map((item) => (
            <ChipMain
              clickEvent={handleSelectGroups}
              buttonText={item.name}
              isDisabled={false}
              key={item.key}
              keyGroup={item.key}
              active={selectedGroups.includes(item.key)}
            />
          ))}
        </LineChips>
        <ChipMain
          clickEvent={() => handleSelectGroups('drop')}
          buttonText={'drop'}
          isDisabled={false}
          svgIconLeft={<EraseIcon />}
        />
      </Wrapper>
    </>
  );
};

export default ControlBlockGroups;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  height: 24px;
  box-sizing: border-box;
  margin-top: -8px;
`;
const LineChips = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;
`;
