import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataCreateUser, otherService } from '@services/other.service';

const getError = (error: any, thunkAPI: any) => {
  const message = (error.data && error.data.message) || error.message || error.toString();
  return thunkAPI.rejectWithValue(message);
};

//TODO:   ================== RTK Query ==================

export const getOthers: any = createAsyncThunk(' other/getOthers', async (params, thunkAPI) => {
  try {
    const response = await otherService.getOthers(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getMoreOthers: any = createAsyncThunk('other/getMoreOthers', async (params, thunkAPI) => {
  try {
    const response = await otherService.getOthers(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateOther: any = createAsyncThunk('other/updateOther', async ({ id, data }: any, thunkAPI) => {
  try {
    const response = await otherService.update(id, data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteOther: any = createAsyncThunk('other/deleteOther', async (id: string, thunkAPI) => {
  try {
    await otherService.delete(id);
    return { id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const addOtherIntoEmployees: any = createAsyncThunk(
  'other/addOtherIntoEmployees',
  async ({ id, spaceId }: any, thunkAPI) => {
    try {
      const response = await otherService.addOtherIntoEmployees(id, spaceId);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const createOther: any = createAsyncThunk('other/createOther', async (data: DataCreateUser, thunkAPI) => {
  try {
    const response = await otherService.create(data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

//TODO:   ================== RTK Query ==================
