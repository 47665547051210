import React, { useState, useEffect, Dispatch } from 'react';

import { ControlBlock } from '@components/shared/controlBlock';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { titleGroups } from './constants';
import { setState } from '@store/assistants/assistants.slice';
import ModalAssist from '../month/components/modalAssist/ModalAssist';
import PopupModal from '@ui/modal/PopupModal';
import Notification from '@ui/notification/Notification';
import { IAddAssist, useDeleteAssistsMutation, useUpdateManagerMutation } from '@store/assistants/assistant.api';
import { useGetUsersListQuery } from '@store/user/user.api';
import { toast } from 'react-toastify';
import notify from '@utils/notify';
import TableGroups from './components/TableGroups';
import { IGroups } from 'interfaces/groups.interfaces';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import styled from 'styled-components';

// TODO: =======  mock data until API will be ready =======
const mockDataGroups: IGroups[] | null = [
  {
    id: '1',
    name: 'Отдел предотвращения утечек коммерческой тайны',
    participants: [
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      {
        id: '1',
        firstName: 'Alexey',
        avatarFileId: '1111',
        workPosition: 'Руководитель направления по работе с молодежью',
      },
      {
        id: '1',
        firstName: 'Alexey',
        avatarFileId: '1111',
        workPosition: 'Руководитель направления по работе с молодежью',
      },
      {
        id: '1',
        firstName: 'Alexey',
        avatarFileId: '1111',
        workPosition: 'Руководитель направления по работе с молодежью',
      },
      {
        id: '1',
        firstName: 'Alexey',
        avatarFileId: '1111',
        workPosition: 'Руководитель направления по работе с молодежью',
      },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
    ],
    participantsWithAccess: [
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
    ],
  },
  {
    id: '2',
    name: 'Отдел разработки приложений и веб-сервисов',
    participants: [
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
    ],
    participantsWithAccess: [
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
    ],
  },
  {
    id: '3',
    name: 'Отдел учета товарно-материальных ценностей',
    participants: [
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
    ],
    participantsWithAccess: [
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
      { id: '1', firstName: 'Alexey', avatarFileId: '1111', workPosition: 'Руководитель отдела' },
    ],
  },
];

const initialUserParams = new URLSearchParams({
  thirdPartyUsersOnly: 'false',
}).toString();

export const Groups = () => {
  const [searchGroups, setSearchGroups] = useState('');
  const [tabActive, setTabActive] = useState(false);
  const [groupsQueryParams, setGroupsQueryParams] = useState<string>(initialUserParams);
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [openCreateGroup, setOpenCreateGroup] = useState<boolean | null>(false);

  const { data: groupsList, isFetching: isLoadingGroups } = useGetUsersListQuery(groupsQueryParams, {
    skip: !groupsQueryParams,
  });

  const [updateGroup, updateGroupResult] = useUpdateManagerMutation();

  const dispatch = useDispatch();

  const debounceValueCommon = debounce((value: string, action: Dispatch<React.SetStateAction<string>>) => {
    action(value);
  }, 2000);

  function handleSearchGroups(value: string) {
    debounceValueCommon(value, setSearchGroups);
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  useEffect(() => {
    if (searchGroups) {
      const paramsString = new URLSearchParams({
        query: searchGroups,
        thirdPartyUsersOnly: 'false',
      }).toString();
      setGroupsQueryParams(paramsString);
    }
  }, [searchGroups]);

  useEffect(() => {
    if (updateGroupResult.isSuccess) {
      toast.success('Группа успешно создана', {
        draggable: true,
        position: 'top-center',
      });
      setOpenCreateGroup(false);
    }

    if (updateGroupResult.error) {
      notify.error('Не удалось добавить группу');
    }
  }, [updateGroupResult]);

  return (
    <>
      <ControlBlock
        title={titleGroups}
        isAdmin={true}
        openForm={setOpenNotification}
        handleSearch={() => undefined}
        textButton="createGroup"
        noSearch
        onlyTitle
      />
      <TableGroups
        handleSearch={setSearchGroups}
        searchValue={searchGroups}
        groupsList={mockDataGroups ?? []}
        isLoading={isLoadingGroups}
      />

      <ModalAssist
        isOpenPopup={openCreateGroup}
        setOpenPopup={setOpenCreateGroup}
        textTitle="addAssist"
        onSave={() => undefined}
        handleSearch={() => undefined}
        isLoadingUsers={isLoadingGroups}
      />
    </>
  );
};

export default Groups;
