import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import Search from '@ui/search/Search';
import styled from 'styled-components';
import CardGroup from './Card.Group';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { IGroups } from 'interfaces/groups.interfaces';
import { useDispatch } from 'react-redux';
import { setState } from '@store/assistants/assistants.slice';
import ListGroups from './ListGroups';
import ItemGroup from './ItemGroup';
import './styles.css';
import ItemGroupEdit from './ItemGroupEdit';
import PopupModal from '@ui/modal/PopupModal';
import ModalNameGroup from './ModalName.Group';
import { IAddAssist, useDeleteAssistsMutation } from '@store/assistants/assistant.api';
import useDataGroup from '@hooks/useDataGroup';

interface ITableGroups {
  handleSearch: (value: string) => void;
  searchValue?: string;
  isLoading: boolean;
  groupsList: IGroups[];
}

const TableGroups = ({ handleSearch, searchValue, isLoading, groupsList }: ITableGroups) => {
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const [openListGroups, setOpenListGroups] = useState(false);
  const [openItemGroup, setOpenItemGroup] = useState(false);
  const [openEditParticipants, setOpenEditParticipants] = useState(false);
  const [openIEditAccess, setOpenEditAccess] = useState(false);
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [currentGroup, setCurrentGroup] = useState<IGroups | null>(null);
  const [newGroup, setNewGroup] = useState<Partial<IGroups> | {}>({});
  const [openModalNameGroup, setOpenModalNameGroup] = useState<boolean | null>(false);

  const { data, createAction, newNameGroup, setNewNameGroup, handleData, paramsCreateGroup } = useDataGroup({
    groupData: currentGroup,
  });

  const [createGroup, createGroupResult] = useDeleteAssistsMutation();

  useEffect(() => {
    setOpenListGroups(true);
  }, []);

  useEffect(() => {
    if (newNameGroup) {
      setNewGroup({ ...newGroup, name: newNameGroup });
    }
  }, [newNameGroup]);

  function onSaveNewGroup() {
    createGroup(paramsCreateGroup);
  }

  function handleOpenGroup(data: any) {
    setCurrentGroup(data);
    setOpenItemGroup(true);
    setOpenListGroups(false);
  }

  function handleCreateGroup() {
    setOpenModalNameGroup(true);
    setCurrentGroup(null);
  }
  function handleCloseParticipantsEdit() {
    setOpenEditParticipants(false);
    setOpenItemGroup(true);
  }
  function handleCloseAccessEdit() {
    setOpenEditAccess(false);
    setOpenItemGroup(true);
  }
  function handleSaveParticipants() {
    setOpenEditParticipants(false);
    setOpenItemGroup(true);
  }

  return (
    <Wrapper>
      <CSSTransition in={openListGroups} timeout={300} classNames="ListGroups" unmountOnExit>
        <ListGroups
          handleSearch={handleSearch}
          isLoading={isLoading}
          searchValue={searchValue}
          groupsList={groupsList}
          clickEvent={handleOpenGroup}
          buttonEvent={handleCreateGroup}
        />
      </CSSTransition>

      <CSSTransition in={openItemGroup} timeout={500} classNames="itemGroup" unmountOnExit>
        <ItemGroup
          groupData={data}
          create={createAction}
          onBack={() => {
            setOpenItemGroup(false);
            setOpenListGroups(true);
            createAction && setNewNameGroup('');
          }}
          openEditPageLeft={() => {
            setOpenEditParticipants(true);
            setOpenItemGroup(false);
          }}
          openEditPageRight={() => {
            setOpenEditAccess(true);
            setOpenItemGroup(false);
          }}
          buttonText={createAction ? 'save' : 'deleteGroup'}
          setCreateNameGroup={setNewNameGroup}
          valueCreateNameGroup={newNameGroup}
          handleData={handleData}
          onSave={onSaveNewGroup}
        />
      </CSSTransition>

      <CSSTransition in={openEditParticipants} timeout={500} classNames="itemGroup" unmountOnExit>
        <ItemGroupEdit
          edit="participants"
          groupData={data}
          onBack={handleCloseParticipantsEdit}
          handleData={handleData}
        />
      </CSSTransition>

      <CSSTransition in={openIEditAccess} timeout={500} classNames="itemGroup" unmountOnExit>
        <ItemGroupEdit edit="access" groupData={data} onBack={handleCloseAccessEdit} handleData={handleData} />
      </CSSTransition>

      <PopupModal isShown={openModalNameGroup} closeEvent={(v) => setOpenModalNameGroup(v)}>
        <ModalNameGroup
          textTitle={'nameGroup'}
          onDone={() => {
            setOpenItemGroup(true);
            setOpenModalNameGroup(false);
          }}
          onCancel={() => {
            setOpenModalNameGroup(false);
            setNewNameGroup('');
          }}
          textButton={'continue'}
          handleChange={setNewNameGroup}
          inputValue={newNameGroup}
        />
      </PopupModal>
    </Wrapper>
  );
};

export default TableGroups;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  position: relative;
  background-color: ${colorsVar.grey3};
  border-top: 1px solid ${colorsVar.grey1};
  padding-bottom: 30px;

  @media screen and (max-width: 800px) {
    width: calc(100vw - 50px);
    min-width: inherit;
  }
  @media screen and (max-width: 700px) {
    width: 100vw;
  }
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 531px;
  width: 100%;
  flex-grow: 1;
  position: relative;
`;
const ButtonWrap = styled.div`
  display: flex;
  padding: 4px 0px 30px;
  justify-content: end;
  height: 43px;
`;
