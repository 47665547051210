import React, { useEffect, useRef } from 'react';
import { SelectDate } from '@components/shared/selectDate';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LineStatChart } from '@components/shared/lineChart/LineStatChart';
import { formattedClosurDate } from '../../statsUtils';
import { colorsVar } from '@components/constants/colorVariables';
import { IActiveUsersPeriod, IClosureTasks, IRenderLine } from 'interfaces/stats.interfaces';
import { IActionCreator } from 'interfaces/interfaces';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import styled from 'styled-components';

interface ChartTaskProps {
  tooltip: ({ active, payload, label }: any) => JSX.Element | null;
  data: IClosureTasks[];
  period: IActiveUsersPeriod;
  setStartDate: (payload: any) => IActionCreator;
  setEndDate: (payload: any) => IActionCreator;
  getData: React.Dispatch<React.SetStateAction<string>>;
  titleChart: string;
  nameLine: string;
  renderLine: IRenderLine[];
  isFavorites: boolean;
  isLoading: boolean;
  position: CalendarPosition;
  isLoadingPage?: boolean;
}

export const ChartTask = ({
  tooltip,
  renderLine,
  nameLine,
  titleChart,
  period,
  data,
  setStartDate,
  setEndDate,
  isFavorites,
  getData,
  isLoading,
  position,
  isLoadingPage = false,
}: ChartTaskProps) => {
  const prevPeriod = useRef(period);

  const dispatch = useDispatch();

  const handleStartData = (event: any) => {
    dispatch(setStartDate(event));
  };
  const handleEndData = (event: any) => {
    dispatch(setEndDate(event));
  };

  useEffect(() => {
    if (!data.length && isEmpty(period)) {
      dispatch(setStartDate(dayjs().add(-10, 'day')));
      dispatch(setEndDate(dayjs()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(period)) {
      const value = isFavorites ? 'true' : 'false';
      const paramsString = new URLSearchParams({
        favoritesOnly: value,
        startDate: dayjs(period.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(period.endDate).format('YYYY-MM-DD'),
      }).toString();
      getData(paramsString);
      prevPeriod.current = period;
    }
  }, [period, isFavorites]);

  return (
    <>
      <div className="graf-task">
        <div className="users-header">
          <div className="data-wrap left">
            <SelectDate
              type="input"
              onChange={handleStartData}
              id="firstEntryDateStartRange"
              value={dayjs(period.startDate).format('DD.MM.YY')}
              position={position}
            />
            <span>-</span>
            <SelectDate
              type="input"
              onChange={handleEndData}
              id="firstEntryDateStartRange"
              value={dayjs(period.endDate).format('DD.MM.YY')}
              position={position}
            />
          </div>
        </div>
        <div className="graf-item">
          <LabelChart className="flex">
            <FormattedMessage id={titleChart} />
          </LabelChart>
          <RenderWithCondition condition={!isLoadingPage}>
            <LineStatChart
              dot={{ r: 3 }}
              pointXaxis="date"
              renderLine={renderLine}
              externalTooltip={tooltip}
              fill={colorsVar.textColor}
              data={formattedClosurDate(data, nameLine)}
            />
          </RenderWithCondition>
          <LocalLoader condition={isLoading || (isLoadingPage ?? false)} size={25} />
        </div>
      </div>
    </>
  );
};

export const LabelChart = styled.div`
  padding-left: 60px;
  font-size: 16px;
  color: ${colorsVar.textColor};
  min-height: 12px;
`;
