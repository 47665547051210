import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as SetMenuIcon } from '@assets/icons/menu-set.svg';
import { ReactComponent as CloseGreyIcon } from '@assets/icons/close-grey.svg';
import { IStateUser } from 'interfaces/user.interfaces';
import { useNavigate } from 'react-router-dom';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useDispatch } from 'react-redux';
import { isFullVersion } from '@components/constants/constants';
import { FormattedMessage } from 'react-intl';
import { useClickAway, useHover } from 'react-use';
import dayjs from 'dayjs';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { truncate } from 'lodash';
import { addCurrentUser } from '@store/user/slice';
import { resetDynamicsState } from '@store/dynamics/slice';
import { CURRENT_USER_ID } from '@configs/consts';
import cn from 'classnames';
import useGetUrlAvatar from '../../../../hooks/useGetUrlAvatar';
import { colorsVar } from '@components/constants/colorVariables';
import AvatarAssist from '@ui/avatarAssist/AvatarAssist';
import styled from 'styled-components';

interface ITableItem {
  user: IStateUser;
  isLoading?: boolean;
  supervisor?: boolean;
  index: number;
  handleExcludeUser?: (id: string, spaceId: string) => void;
  onChangeTest?: (id: string, login: string, test: boolean) => void;
  onChangeAccess?: (id: string, login: string, isEnabled: boolean) => void;
  isNotSuccessUpdate?: boolean;
}

export const TableItem = ({
  user,
  index,
  supervisor,
  onChangeTest,
  onChangeAccess,
  handleExcludeUser,
  isNotSuccessUpdate,
}: ITableItem) => {
  const [isActive, setIsActive] = useState(false);
  const [checked, setChecked] = useState(user.isTest);

  const urlAvatar = useGetUrlAvatar({ fileId: user?.avatarFileId });
  const ref = useRef(null);

  useClickAway(ref, () => {
    setIsActive(false);
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tooTipInfo = () => <div className="field-name">{`${user.lastName} ${user.firstName} ${user.middleName} `}</div>;

  const [hoverElement, hovered] = useHover(tooTipInfo);

  useEffect(() => {
    if (isNotSuccessUpdate) {
      setChecked(user.isTest);
    }
  }, [isNotSuccessUpdate]);

  const handleUserInfo = (user: IStateUser, id: string) => {
    if (isFullVersion) {
      dispatch(addCurrentUser({ ...user, blobUrlAvatar: urlAvatar }));
      localStorage.setItem(CURRENT_USER_ID, id);
      dispatch(resetDynamicsState(user));
      navigate('/employees/month');
    }
  };

  const getLogin = (user: IStateUser) => {
    let data = { id: '', login: '' };
    user.accounts &&
      user.accounts.map((account) => {
        if (account.type === 'PHONE_NUMBER') {
          data.login = account.login;
          data.id = account.id;
        }
        return account;
      });
    return data;
  };

  const handleOpenTooltip = () => {
    if (user.isManuallyCreated) {
      setIsActive(!isActive);
    }
  };

  const textTooltipAvatar = useMemo(() => {
    if (!user?.isAssistant && !user?.isManager) {
      return '';
    }
    return user?.isAssistant ? 'assistant' : 'supervisor';
  }, [user]);

  return (
    <div
      className={cn('row', { supervisor: supervisor })}
      style={{ backgroundColor: index % 2 === 0 ? '#F5F9F8' : '#FFFFFF' }}
    >
      <div className="flex justify-center">
        <AvatarAssist
          size="medium"
          textTooltip={textTooltipAvatar}
          urlAvatar={urlAvatar}
          isAssistant={user?.isAssistant}
          isManager={user?.isManager}
        />
      </div>
      <div>
        <span>{getLogin(user).login}</span>
      </div>
      <div className="user-item" onClick={() => handleUserInfo(user, user.id)}>
        {hoverElement}
        <MouseTooltip visible={hovered} style={{ transition: '0.5s' }}>
          <div className={cn('tooltip info', { active: hovered })}>
            <div className="tooltip-item">
              <span>
                <FormattedMessage id="showInfo" />
              </span>
            </div>
          </div>
        </MouseTooltip>
      </div>
      <div className="user-item">
        <span>{user.department === null ? <FormattedMessage id="notFilled" /> : truncate(user.department.name)}</span>
      </div>
      <div className="dates">
        <RenderWithCondition condition={!!user?.activity?.firstActivityDate}>
          <span>{dayjs(user?.activity?.firstActivityDate).format('DD.MM.YY')}</span>
        </RenderWithCondition>
      </div>
      <div className="dates">
        <span>{user?.activity?.lastActivityDate && dayjs(user?.activity?.lastActivityDate).format('DD.MM.YY')}</span>
      </div>
      <RenderWithCondition condition={!supervisor}>
        <>
          <div>
            <input
              type="checkbox"
              className="check-input"
              checked={user.isEnabled}
              onChange={(e) => onChangeAccess && onChangeAccess(user.id, user.login, e.target.checked)}
            />
            <label className="check"></label>
          </div>
          <div>
            <input
              type="checkbox"
              className="check-input"
              checked={checked}
              onChange={(e) => {
                onChangeTest && onChangeTest(user.id, user.login, e.target.checked);
                setChecked(!checked);
              }}
              onClick={() => undefined}
            />
            <label className="check"></label>
          </div>
          <div
            className="set-menu"
            ref={ref}
            style={user.isManuallyCreated ? undefined : { cursor: 'inherit' }}
            onClick={handleOpenTooltip}
          >
            <SetMenuIcon fill={user.isManuallyCreated ? '#5F6E67' : '#dfd8ff'} />
            <div className={cn('tooltip', { active: isActive })}>
              <div
                className="tooltip-item"
                onClick={() => handleExcludeUser && handleExcludeUser(user.id, user.spaceId)}
              >
                <span>
                  <CloseGreyIcon />
                </span>
                <span>
                  <FormattedMessage id="exludeFromDogma" />
                </span>
              </div>
            </div>
          </div>
        </>
      </RenderWithCondition>
    </div>
  );
};

export const ImgAvatar = styled.img`
  object-fit: cover;
`;

export const Icon = styled.div<{ size: number }>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${colorsVar.grey1};
  border: 1px solid ${colorsVar.grey4};
`;
