import React, { useState, useRef } from 'react';

import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import { ReactComponent as LeftIcon } from '@assets/icons/chevron-left.svg';
import { ReactComponent as RightIcon } from '@assets/icons/chevron-right.svg';
import { FormattedMessage } from 'react-intl';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { formattedMonthTitle } from '../../utils/formattedMonthTitle';
import { useClickAway } from 'react-use';
import 'react-calendar/dist/Calendar.css';
import './selectCalendar.scss';

interface ISelectCalendar {
  id: string;
  type: string;
  value: string;
  onChange: (value: string | null | boolean, id: string) => void;
}

export const SelectCalendar = ({ onChange, value, id }: ISelectCalendar) => {
  const [isVisible, setVisible] = useState(false);
  const [currentData, setCurrentData] = useState(value);
  const [startDate, setStartDate] = useState(new Date());

  const ref = useRef(null);
  useClickAway(ref, () => {
    setVisible(false);
  });

  const handlePickDate = (event: any) => {
    const currDate = dayjs(event).format('YYYY-MM-DD');

    setCurrentData(dayjs(event).format('DD.MM.YY'));
    onChange(currDate, id);
    setVisible(false);
  };
  const dropDate = () => {
    setCurrentData('');
    onChange(null, id);
  };

  return (
    <div className="picker-wrapper" ref={ref}>
      <div className={`select-body ${isVisible ? 'active' : ''}`}>
        <div className="select" onClick={() => setVisible(!isVisible)}>
          <span>{currentData}</span>
          <CalendarIcon />
        </div>
        <div>
          <span onClick={dropDate} className="clear">
            <FormattedMessage id="clearField" />
          </span>
        </div>
        <div className={isVisible ? 'calendar-wrapper open' : 'calendar-wrapper'}>
          <Calendar
            // formatMonthYear={(locale, date) => new Intl.DateTimeFormat(locale, { month: 'long' }).format(date)}
            formatMonthYear={formattedMonthTitle}
            navigationAriaLabel={'none'}
            tileClassName="content"
            prev2Label={''}
            next2Label={''}
            prevLabel={<LeftIcon fill="#297952" />}
            nextLabel={<RightIcon fill="#297952" />}
            onChange={(e) => handlePickDate(e)}
            formatLongDate={(locale, date) => dayjs(locale).format('DD-MM-YYYY')}
            defaultView="month"
            value={startDate}
            view={'month'}
          />
        </div>
      </div>
    </div>
  );
};
