import React, { FC, useEffect, useState } from 'react';
import { PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';
import { IUserPersonalGoals } from 'interfaces/user.interfaces';
import { formattedDataGoalsRadar } from '@components/screens/home/statsUtils';
import './stylesPolar.css';

interface IRadarChartItem {
  data: IUserPersonalGoals;
  externalTooltip?: ({ active, payload, label }: any) => JSX.Element | null;
}

export const RadarChartItem: FC<IRadarChartItem> = (props) => {
  const [isWideScreen, setIsWideScreen] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1000px)');
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setIsWideScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <ResponsiveContainer aspect={isWideScreen ? 1.36 : 2.36} id="polar">
      <RadarChart cx="50%" cy="50%" outerRadius="85%" data={formattedDataGoalsRadar(props.data)}>
        <PolarGrid
          gridType="circle"
          outerRadius={80}
          innerRadius={10}
          strokeDasharray={5}
          type="line"
          cy={100}
          strokeMiterlimit={2}
          strokeWidth={1}
        ></PolarGrid>
        <PolarRadiusAxis tickCount={1} axisLine={false} tick={false} domain={[0, 5]} />
        <Radar
          name="PersonalGoal"
          dataKey="value"
          strokeWidth={1.5}
          stroke="#297952"
          fill="transparent"
          fillOpacity={1}
          dot={{ strokeWidth: 0.3, fill: '#297952' }}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};
