import React, { useEffect, useState, useMemo } from 'react';

import { ModalFilter } from '@components/shared/modalFilter';
import { ModalUser } from '@components/shared/modalUser';
import { ControlBlock } from '@components/shared/controlBlock';
import { titleHome } from './constants';
import { LIMIT_PAGE } from '../../constants/constants';
import { usePagination } from '../../../hooks/usePagination';
import { Table } from './components/Table';
import { selectUserSlice, setFilter } from '@store/user/slice';
import { getIdDepartment, getOptionsDepartments } from '../../../utils/getOptionsDepartments';
import { getFilterWithoutNull } from '../../../utils/getOptionsDepartments';
import { debounce } from 'lodash';
import notify from '@utils/notify';
import {
  ICreateUser,
  IExcludeUser,
  useCreateUserMutation,
  useExcludeUserMutation,
  useGetDepartmentsListQuery,
  useGetUsersListQuery,
  useUpdateUserMutation,
} from '@store/user/user.api';
import { useAppSelector } from '@store/hooks';
import { selectAuthSlice } from '@store/auth/slice';
import { toast } from 'react-toastify';
import PopupModal from '@ui/modal/PopupModal';
import Notification from '@ui/notification/Notification';

const initialUserParams = new URLSearchParams({
  thirdPartyUsersOnly: 'false',
}).toString();

interface IDataNotification {
  id: string;
  data: { isEnabled: boolean };
}

export const Employees = () => {
  const [isVisibleFilter, setIsVisibleFilter] = useState<boolean | null>(false);
  const [isVisibleUser, setIsVisibleUser] = useState<boolean | null>(false);
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [openNotificationExclude, setOpenNotificationExclude] = useState<boolean | null>(false);

  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState('');
  const [newNotification, setNewNotification] = useState({} as IDataNotification);
  const [newNotificationExclude, setNewNotificationExclude] = useState({} as IExcludeUser);
  const [paramsString, setParamsString] = useState<string>(initialUserParams);

  const { filter } = useAppSelector(selectUserSlice);
  const { supervisor } = useAppSelector(selectAuthSlice);

  const { data: users, isLoading, isFetching } = useGetUsersListQuery(paramsString);
  const { currentData: departmentsList } = useGetDepartmentsListQuery(null, {
    skip: !isVisibleUser && !isVisibleFilter,
  });

  const [updateUser, updateResult] = useUpdateUserMutation();
  const [excludeUser, excludeResult] = useExcludeUserMutation();
  const [createUser, createResult] = useCreateUserMutation();

  const { nextLoad, start } = usePagination(users?.length ?? 0, LIMIT_PAGE);

  const debounceValue = debounce((value) => {
    setSearch(value);
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      query: value,
      ...tempObj,
      thirdPartyUsersOnly: 'false',
    }).toString();
    setParamsString(paramsString);
  }, 2000);

  const filterOptionsDeps = useMemo(() => {
    return departmentsList ? [...getOptionsDepartments(departmentsList)] : undefined;
  }, [departmentsList]);
  const text = useMemo(() => {
    return newNotification?.data?.isEnabled ? 'giveRights' : 'takeRights';
  }, [newNotification?.data?.isEnabled]);

  useEffect(() => {
    const usedFilter = Object.values(filter).every((value) => {
      if (value === null) {
        return true;
      }
      return false;
    });
    setIsFiltered(!usedFilter);
  }, [filter]);

  useEffect(() => {
    if (createResult.isSuccess) {
      toast.success('Новый сотрудник успешно добавлен', {
        draggable: true,
        position: 'top-center',
      });
      setIsVisibleUser(false);
    }
    if (createResult.error) {
      notify.error('Не удалось добавить сотрудника');
    }
  }, [createResult]);

  useEffect(() => {
    if (excludeResult.isSuccess) {
      toast.success('Пользователь удален из сотрудников', {
        draggable: true,
        position: 'top-center',
      });
      setOpenNotificationExclude(false);
    }
    if (excludeResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [excludeResult]);

  useEffect(() => {
    if (updateResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [updateResult]);

  function handleFilteredUser() {
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      thirdPartyUsersOnly: 'false',
      query: search,
      ...tempObj,
    }).toString();
    setParamsString(paramsString);
    setIsVisibleFilter(false);
  }

  function handleGetMoreUsers() {
    nextLoad();
    const tempObj = getFilterWithoutNull(filter);
    const newParamsString = new URLSearchParams({
      query: search,
      thirdPartyUsersOnly: 'false',
      ...tempObj,
      page: `${start}`,
    }).toString();
    if (users?.length && Number.isInteger(users?.length / LIMIT_PAGE)) {
      setParamsString(newParamsString);
    }
  }

  function handleSearchUsers(value: string) {
    debounceValue(value);
  }

  function handleChangeTest(id: string, login: string, test: boolean) {
    const newData = {
      id,
      data: {
        isTest: test,
      },
    };
    updateUser(newData);
  }

  function handleChangeAccess(id: string, login: string, isEnabled: boolean) {
    const newData: IDataNotification = {
      id,
      data: {
        isEnabled,
      },
    };
    setNewNotification(newData);
    setOpenNotification(true);
  }

  function confirmAccess() {
    updateUser(newNotification);
    setNewNotification({} as IDataNotification);
    setOpenNotification(false);
  }
  function confirmAccessExclude() {
    excludeUser(newNotificationExclude);
    setNewNotificationExclude({} as IExcludeUser);
  }

  function handleExcludeUser(id: string, spaceId: string) {
    const newData: IExcludeUser = {
      id,
      spaceId,
    };
    setNewNotificationExclude(newData);
    setOpenNotificationExclude(true);
  }

  function handleCreateUser(data: ICreateUser) {
    if (!departmentsList) return;
    const idDep = getIdDepartment(departmentsList, data?.departmentId);
    const newData: ICreateUser = {
      ...data,
      departmentId: idDep,
      type: 'PHONE_NUMBER',
    };
    createUser(newData);
  }

  return (
    <>
      <ControlBlock
        title={titleHome}
        supervisor={supervisor}
        openForm={setIsVisibleUser}
        openFilter={setIsVisibleFilter}
        handleSearch={handleSearchUsers}
        searchValue={search}
        isLoading={isLoading ? false : isFetching}
      />

      <Table
        usersList={users ?? []}
        isLoading={isLoading}
        supervisor={supervisor}
        isLoadingExpand={isLoading ? false : isFetching}
        handleGetUsers={handleGetMoreUsers}
        handleChangeTest={handleChangeTest}
        handleExcludeUser={handleExcludeUser}
        handleChangeAccess={handleChangeAccess}
        isNotSuccessUpdate={updateResult.status === 'rejected'}
      />
      <PopupModal isShown={isVisibleFilter} closeEvent={(value) => setIsVisibleFilter(value)}>
        <ModalFilter
          filter={filter}
          setFilter={setFilter}
          isFiltered={!isFiltered}
          supervisor={supervisor}
          options={filterOptionsDeps}
          setIsVisible={setIsVisibleFilter}
          handleFiltered={handleFilteredUser}
          departmentsList={departmentsList}
        />
      </PopupModal>
      <PopupModal isShown={isVisibleUser} closeEvent={(value) => setIsVisibleUser(value)}>
        <ModalUser
          isEmployee={true}
          setIsVisible={setIsVisibleUser}
          handleCreate={handleCreateUser}
          options={filterOptionsDeps}
        />
      </PopupModal>
      <PopupModal isShown={openNotification} closeEvent={(value) => setOpenNotification(value)}>
        <Notification textTitle={text} onDone={confirmAccess} onCancel={() => setOpenNotification(false)} />
      </PopupModal>
      <PopupModal isShown={openNotificationExclude} closeEvent={(value) => setOpenNotificationExclude(value)}>
        <Notification
          textTitle={'excludeFromDogmaClarifications'}
          onDone={confirmAccessExclude}
          onCancel={() => setOpenNotificationExclude(false)}
        />
      </PopupModal>
    </>
  );
};
