import { DASHBOARD_SERVICE_URL } from '@components/constants/constants';
import { http } from '../utils/http';

export interface DataCreateUser {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  type: string | null;
  login: string | null;
}

class StatsService {
  constructor() {}

  //TODO: ==============  RTK Query =================

  getActiveUsers = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/users-count?${params}`);
  };

  getUsersTasks = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/user-tasks-count?${params}`);
  };

  getUsersGoals = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/user-goals-count?${params}`);
  };

  getOpenTasks = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/tasks-planning?${params}`);
  };

  getCompletionTasks = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/tasks-completion?${params}`);
  };

  getCreationTasks = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/tasks-creation?${params}`);
  };

  getClosureTasks = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/tasks-closure?${params}`);
  };

  getBusinessGoals = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/business-goals-count?${params}`);
  };

  getPersonalGoals = async (params: string): Promise<any> => {
    return await http.get(`${DASHBOARD_SERVICE_URL}/personal-goals-count?${params}`);
  };
  //TODO: ==============  RTK Query =================
}

export const statsService = new StatsService();
