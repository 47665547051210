import { ADMIN_URL, SERVICE_URL } from '@components/constants/constants';
import { http } from '../utils/http';

export interface DataCreateUser {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  type: string | null;
  login: string | null;
}

class OtherService {
  constructor() {}

  //TODO:   ================== RTK Query ==================
  update = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.patch(`${SERVICE_URL}/users/${id}`, data);
  };

  getOthers = async (params: any): Promise<any> => {
    return await http.get(`${SERVICE_URL}/users/page?${params}`);
  };

  delete = async (id: string): Promise<any> => {
    return await http.delete(`${SERVICE_URL}/users/${id}`);
  };

  addOtherIntoEmployees = async (id: string, spaceId: string): Promise<any> => {
    return await http.post(`${ADMIN_URL}/spaces/${spaceId}/include/users/${id}`);
  };
  create = async (data: any): Promise<any> => {
    return await http.post(`${SERVICE_URL}/accounts`, data);
  };

  //TODO:   ================== RTK Query ==================
}

export const otherService = new OtherService();
