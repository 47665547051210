import React, { useEffect, useState } from 'react';
import Search from '@ui/search/Search';
import styled from 'styled-components';
import CardGroup from './Card.Group';
import ControlBlockGroups from './ControlBlock.Groups';
import { IGroups } from 'interfaces/groups.interfaces';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import PopupModal from '@ui/modal/PopupModal';
import ModalNameGroup from './ModalName.Group';
import { IAddAssist, useDeleteAssistsMutation } from '@store/assistants/assistant.api';

interface IListGroups {
  handleSearch: (value: string) => void;
  searchValue?: string;
  isLoading: boolean;
  groupsList: IGroups[];
  buttonEvent?: () => void;
  clickEvent?: (value: any) => void;
}

const ListGroups = ({ handleSearch, searchValue, isLoading, groupsList, buttonEvent, clickEvent }: IListGroups) => {
  return (
    <ContentWrap>
      <Search handleSearch={handleSearch} isLoading={isLoading} searchValue={searchValue} className="max-h-[32px]" />
      <ControlBlockGroups />
      <ListCards>
        {groupsList?.map((group) => (
          <CardGroup dataGroup={group} key={group.id} handleEvent={clickEvent} />
        ))}
      </ListCards>
      <ButtonWrap>
        <div className="w-[250px]">
          <ButtonMain clickEvent={buttonEvent} buttonText="createGroup" />
        </div>
      </ButtonWrap>
    </ContentWrap>
  );
};

export default ListGroups;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 531px;
  width: 100%;
  flex-grow: 1;
  position: relative;
  opacity: 1;
  margin-top: 20px;
  transition: all 0.5s ease;
  height: calc(100vh - 135px);
  position: absolute;
`;
const ListCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 270px);
  overflow-y: auto;
`;
const ButtonWrap = styled.div`
  display: flex;
  bottom: 30px;
  right: -50%;
  padding: 24px 0px 10px;
  justify-content: end;
`;
