import React, { useEffect, useRef, useState } from 'react';
import { SelectDate } from '@components/shared/selectDate';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { formattedDataTasksPie } from '../../statsUtils';
import { addPieTasksDate, selectStatsSlice } from '@store/statistic/slice';
import { PieStatChart } from '@components/shared/pieChart/PieStatChart';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useGetUsersTasksQuery } from '@store/statistic/statistic.api';
import { useAppSelector } from '@store/hooks';

interface PieChartTasksProps {
  position: CalendarPosition;
  isLoadingPage: boolean;
}

export const PieChartTasks = ({ position, isLoadingPage }: PieChartTasksProps) => {
  const [usersWithTasksParams, setUsersWithTasksParams] = useState('');

  const { tasksBusinessPeriod } = useAppSelector(selectStatsSlice);

  const { data: usersWithTasks, isLoading, isFetching } = useGetUsersTasksQuery(usersWithTasksParams, {
    skip: !usersWithTasksParams,
  });
  const prevTasksBusinessPeriod = useRef(tasksBusinessPeriod.tasksDate);

  const dispatch = useDispatch();

  const handleStartData = (event: any) => {
    dispatch(addPieTasksDate(event));
  };

  useEffect(() => {
    if (isEmpty(usersWithTasks) && isEmpty(tasksBusinessPeriod.tasksDate)) {
      dispatch(addPieTasksDate(dayjs()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(tasksBusinessPeriod)) {
      const paramsString = new URLSearchParams({
        date: dayjs(tasksBusinessPeriod.tasksDate).format('YYYY-MM-DD'),
      }).toString();
      setUsersWithTasksParams(paramsString);
    }
    prevTasksBusinessPeriod.current = tasksBusinessPeriod.tasksDate;
  }, [tasksBusinessPeriod.tasksDate]);

  return (
    <>
      <div className="graf-task">
        <div className="users-header goals">
          <h2>
            <FormattedMessage id="usersWithTasks" />
          </h2>
          <div className="data-wrap">
            <SelectDate
              type="input"
              onChange={handleStartData}
              id="firstEntryDateStartRange"
              value={dayjs(tasksBusinessPeriod.tasksDate).format('DD.MM.YY')}
              position={position}
            />
          </div>
        </div>
        <div className="graf-item">
          <div className="pie-wrap">
            <div className="pie-stats">
              <div className="pie-info">
                <FormattedMessage id="total" />
                <span>{usersWithTasks?.total}</span>
              </div>
              <div className="pie-info blue">
                <FormattedMessage id="withTasks" />
                <span>{usersWithTasks?.withTasks}</span>
              </div>
              <div className="pie-info red">
                <FormattedMessage id="withoutTasks" />
                <span>{usersWithTasks?.withoutTasks}</span>
              </div>
            </div>
            <RenderWithCondition condition={!isLoadingPage}>
              <PieStatChart data={formattedDataTasksPie(usersWithTasks)} total={usersWithTasks?.total ?? ''} />
            </RenderWithCondition>
            <LocalLoader condition={isLoading || isFetching || isLoadingPage} size={25} />
          </div>
        </div>
      </div>
    </>
  );
};
