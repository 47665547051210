import React, { FC } from 'react';
import NavBar from './components/NavBar';
import { useAppSelector } from '@store/hooks';
import { selectDeviceSlice } from '@store/device/device.slice';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';

interface ILayout {
  children: JSX.Element;
}

const Layout: FC<ILayout> = (props) => {
  const { width } = useWidthScreens();

  const { isHiddenNavbar } = useAppSelector(selectDeviceSlice);

  return (
    <Wrapper>
      <Container className="flex">
        <LayoutLeft className="flex">
          <NavBar />
        </LayoutLeft>
        <RenderWithCondition condition={!isHiddenNavbar || (isHiddenNavbar && width !== null && width > 800)}>
          <LayoutRight className="flex">{props.children}</LayoutRight>
        </RenderWithCondition>
      </Container>
    </Wrapper>
  );
};

export default Layout;

const Wrapper = styled.div`
  flex: 1;
`;
const Container = styled.div`
  background-color: ${colorsVar.greyBase};
  color: ${colorsVar.textColor};
  flex: 1;
`;

const LayoutLeft = styled.div`
  z-index: 2;
  position: relative;
`;

const LayoutRight = styled.div`
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colorsVar.grey3};
`;
