import React, { useState, useEffect, useRef } from 'react';

import { TableAdmin } from './components/tableAdmin';
import { ModalAdmin } from './components/modalAdmin';
import { ControlBlock } from '@components/shared/controlBlock';
import { titleAdmin } from './constants';
import { LIMIT_PAGE } from '@components/constants/constants';
import { usePagination } from '../../../hooks/usePagination';
import { IAddAdmin, IStateUser } from 'interfaces/user.interfaces';
import { ModalChooseAdmin } from './components/modalChooseAdmin';
import { debounce } from 'lodash';
import { ModalPasswordAdmin } from './components/modalPasswordAdmin/ModalPasswordAdmin';
import {
  IAdditionalAdmin,
  IDeleteAdmin,
  useAddAdminMutation,
  useDeleteAdminMutation,
  useGetAdminsListQuery,
  useUpdatePasswordMutation,
} from '@store/admin/admin.api';
import { toast } from 'react-toastify';
import notify from '@utils/notify';
import PopupModal from '@ui/modal/PopupModal';

const initialAdminParams = new URLSearchParams({
  adminOnly: 'true',
}).toString();

export const Admin = () => {
  const [isVisibleAdmin, setIsVisibleAdmin] = useState<boolean | null>(false);
  const [isVisiblePasswordAdmin, setVisiblePasswordAdmin] = useState<boolean | null>(false);
  const [isVisibleChooseAdmin, setIsVisibleChooseAdmin] = useState<boolean | null>(false);
  const [search, setSearch] = useState('');

  const refId = useRef({} as IStateUser);
  const refIdPass = useRef('');

  const [paramsString, setParamsString] = useState<string>(initialAdminParams);

  const { data: admins, isLoading, isFetching } = useGetAdminsListQuery(paramsString);

  const [addAdmin, addAdminResult] = useAddAdminMutation();
  const [changePassword, changePasswordResult] = useUpdatePasswordMutation();
  const [deleteAdmin, deleteAdminResult] = useDeleteAdminMutation();

  const { nextLoad, start } = usePagination(admins?.length ?? 0, LIMIT_PAGE);
  const debounceValue = debounce((value) => {
    setSearch(value);
    const paramsString = new URLSearchParams(
      value
        ? {
            query: value,
            adminOnly: 'true',
          }
        : { adminOnly: 'true' },
    ).toString();
    setParamsString(paramsString);
  }, 2000);

  useEffect(() => {
    if (addAdminResult.isSuccess) {
      toast.success('Администратор успешно добавлен', {
        draggable: true,
        position: 'top-center',
      });
      setIsVisibleAdmin(false);
      refId.current = {} as IStateUser;
    }

    if (addAdminResult.error) {
      notify.error('Не удалось добавить администратора');
    }
  }, [addAdminResult]);

  useEffect(() => {
    if (deleteAdminResult.isSuccess) {
      toast.success('Администратор успешно исключен', {
        draggable: true,
        position: 'top-center',
      });
    }
    if (deleteAdminResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [deleteAdminResult]);

  useEffect(() => {
    if (changePasswordResult.isSuccess) {
      toast.success('Пароль успешно изменен', {
        draggable: true,
        position: 'top-center',
      });
      setVisiblePasswordAdmin(false);
      refIdPass.current = '';
    }
    if (changePasswordResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [changePasswordResult]);

  function handleGetMoreAdmins() {
    nextLoad();
    const paramsString = new URLSearchParams({
      query: search,
      adminOnly: 'true',
      page: `${start}`,
    }).toString();
    if (admins?.length && Number.isInteger(admins?.length / LIMIT_PAGE)) {
      setParamsString(paramsString);
    }
  }

  function handleSearchAdmins(value: string) {
    debounceValue(value);
  }

  function handleGetIdPassword(id: string) {
    refIdPass.current = id;
  }

  function handleChooseAdmin(user: IStateUser) {
    setIsVisibleChooseAdmin(false);
    setIsVisibleAdmin(true);
    refId.current = user;
  }

  function handleAddAdmin(data: IAddAdmin) {
    const { id, firstName, lastName } = refId.current;
    const newData: IAdditionalAdmin = {
      id,
      data: {
        login: data.login,
        isAdmin: 'true',
        password: data.password,
        type: 'LOGIN',
        firstName,
        lastName,
      },
    };
    addAdmin(newData);
  }

  function handleChangePassword(values: Record<string, string>) {
    const newData = { id: refIdPass.current, data: { password: values.password } };
    changePassword(newData);
  }

  function handleDeleteAdmin(id: string, data: { id: string; login: string }) {
    const newData: IDeleteAdmin = {
      id,
      data: {
        ...data,
        type: 'LOGIN',
      },
    };
    deleteAdmin(newData);
  }

  return (
    <>
      <ControlBlock
        title={titleAdmin}
        isAdmin={true}
        openForm={() => setIsVisibleChooseAdmin(true)}
        handleSearch={handleSearchAdmins}
        searchValue={search}
        isLoading={isLoading ? false : isFetching}
      />
      <TableAdmin
        adminsList={admins ?? []}
        isLoading={isLoading}
        isLoadingExpand={isLoading ? false : isFetching}
        handleGetAdmins={handleGetMoreAdmins}
        handleDeleteAdmin={handleDeleteAdmin}
        setModalOpen={setVisiblePasswordAdmin}
        handleGetIdPassword={handleGetIdPassword}
      />
      <PopupModal isShown={isVisibleChooseAdmin} closeEvent={(value) => setIsVisibleChooseAdmin(value)}>
        <ModalChooseAdmin setIsVisible={setIsVisibleChooseAdmin} handleChoose={handleChooseAdmin} />
      </PopupModal>

      <PopupModal isShown={isVisibleAdmin} closeEvent={(value) => setIsVisibleAdmin(value)}>
        <ModalAdmin setIsVisible={setIsVisibleAdmin} handleAddAdmin={handleAddAdmin} />
      </PopupModal>

      <PopupModal isShown={isVisiblePasswordAdmin} closeEvent={(value) => setVisiblePasswordAdmin(value)}>
        <ModalPasswordAdmin setIsVisible={setVisiblePasswordAdmin} handleChangePassword={handleChangePassword} />
      </PopupModal>
    </>
  );
};
