import React, { useEffect } from 'react';

import { ButtonShowUsers } from '@components/shared/buttonShowUsers';
import { LIMIT_PAGE } from '@components/constants/constants';
import { FormattedMessage } from 'react-intl';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { headersAssist, headersManager } from '../constants';
import TableAssistItem from './TableAssistItem';
import { colorsVar } from '@components/constants/colorVariables';
import { LineText } from '@components/commons/commonsStyled';
import { IAssistFull, IManagersFull } from '@store/assistants/assistants.slice';
import { uniqueId } from 'lodash';
import styled from 'styled-components';

interface ITableAssist {
  assistList: IAssistFull[] | null | undefined;
  managersList: IManagersFull[] | null | undefined;
  isLoading: boolean;
  handleGetAssist: () => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
  setModalOpenDown: React.Dispatch<React.SetStateAction<boolean | null>>;
  handleGetIdPassword: (id: string) => void;
  handleDeleteAssist: (id: string) => void;
  openTab: boolean;
  isLoadingExpand?: boolean;
}

const TableAssist = ({
  openTab,
  isLoading,
  assistList,
  managersList,
  setModalOpen,
  handleGetAssist,
  isLoadingExpand,
  setModalOpenDown,
  handleDeleteAssist,
  handleGetIdPassword,
}: ITableAssist) => {
  const isNextAssist = assistList?.length ? Number.isInteger(assistList?.length / LIMIT_PAGE) : false;
  const isNextManager = managersList?.length ? Number.isInteger(managersList?.length / LIMIT_PAGE) : false;
  const isNext = openTab ? isNextManager : isNextAssist;

  return (
    <div className="admin-wrapper">
      <div className="admin">
        <TableHeader>
          {(openTab ? headersManager : headersAssist).map((title) => (
            <LineText fontSize={'16px'} color={colorsVar.greenClose}>
              {title ? <FormattedMessage id={title} /> : ''}
            </LineText>
          ))}
        </TableHeader>
        <TableBody className="flex">
          {assistList &&
            !openTab &&
            assistList.map((user, index: number) => (
              <TableAssistItem
                currentIndex={index}
                assistData={user}
                managerData={null}
                key={uniqueId(user.assistant.id)}
                setModalOpen={setModalOpen}
                setModalOpenDown={setModalOpenDown}
                handleDelete={handleDeleteAssist}
                handleGetIdPassword={handleGetIdPassword}
                openTab={openTab}
              />
            ))}
          {managersList &&
            openTab &&
            managersList.map((user, index: number) => (
              <TableAssistItem
                currentIndex={index}
                assistData={null}
                managerData={user}
                key={uniqueId(user.manager.id)}
                setModalOpen={setModalOpen}
                setModalOpenDown={setModalOpenDown}
                handleDelete={handleDeleteAssist}
                handleGetIdPassword={handleGetIdPassword}
                openTab={openTab}
              />
            ))}
          <ButtonShowUsers
            isActive={isNext}
            title={'showMore'}
            loading={isLoadingExpand}
            handleEvents={handleGetAssist}
          />
          <LocalLoader condition={isLoading} />
        </TableBody>
      </div>
    </div>
  );
};

export default TableAssist;

const TableBody = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1;
  max-height: calc(100vh - 177px);
  background-color: ${colorsVar.white};
  border: 1px solid ${colorsVar.grey1};
  scrollbar-color: ${colorsVar.greenScroll} ${colorsVar.grey5};
  scrollbar-width: thin;
  min-height: 300px;
`;
const TableHeader = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 154px 1.2fr 2fr 70px;
  grid-template-rows: 43px;
  align-items: center;
  background-color: ${colorsVar.white};
`;
