import React, { useEffect, useState, useRef, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { codeApi, getCodeApi } from '@store/auth/api';
import dayjs from 'dayjs';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { useDeviceData, useDeviceSelectors } from 'react-device-detect';
import { APP_VERSION, DEVICE_ID } from '@configs/consts';
import { v4 as uuidv4 } from 'uuid';
import styles from './auth.module.scss';

const initialTime = dayjs().minute(1).second(0);

export const AuthCheckCode = () => {
  const [inputValue, setInputValue] = useState('');
  const [isTimer, setIsTimer] = useState(true);
  const [time, setTime] = useState(initialTime);
  const [deviceId, setDeviceId] = useState('');

  const deviceInfo = useDeviceData(window.navigator.userAgent);
  const navigate = useNavigate();

  const { phoneNumber, registered, errorMsg } = useSelector((state: RootState) => state.auth);

  const valuePhone = phoneNumber ? phoneNumber : '';

  const [selectors] = useDeviceSelectors(window.navigator.userAgent);

  const dispatch = useDispatch();
  const refCode = useRef<HTMLInputElement>(null);

  const typeOfDevice = useMemo(() => {
    if (selectors?.isTablet) {
      return 'TABLET';
    }
    if (selectors?.isMobile) {
      return 'MOBILE';
    }
    if (selectors?.isDesktop) {
      return 'DESKTOP';
    }
    return null;
  }, [selectors]);

  useEffect(() => {
    if (isTimer) {
      setTime(initialTime);
      let tempTime = initialTime;
      const intervalTimer = setInterval(() => {
        if (tempTime.minute() === 0 && tempTime.second() === 0) {
          setIsTimer(false);
          clearInterval(intervalTimer);
        }
        tempTime = tempTime.second(tempTime.second() - 1);
        setTime(tempTime);
      }, 1000);
    }
  }, [isTimer]);

  useEffect(() => {
    const id = localStorage.getItem(DEVICE_ID);
    if (id) {
      setDeviceId(id);
    } else {
      const newId = uuidv4();
      setDeviceId(newId);
      localStorage.setItem(DEVICE_ID, newId);
    }
  }, []);

  useEffect(() => {
    if (inputValue.length === 4) {
      const data = {
        phone_number: phoneNumber,
        sms_code: inputValue,
        grant_type: 'sms_code',
        device: {
          device_id: deviceId,
          brand: deviceInfo.device.vendor,
          model: deviceInfo.device.model
            ? `${deviceInfo.device.model} ${deviceInfo.os.version}`
            : `${deviceInfo.browser.name} ${deviceInfo.browser.major}`,
          operating_system: deviceInfo.os.name,
          app_version: `Dashboard ${APP_VERSION}`,
          device_type: typeOfDevice,
        },
      };
      setInputValue('');
      dispatch(codeApi(data));
    }
  }, [inputValue]);

  useEffect(() => {
    if (!registered) {
      return navigate('/auth');
    }
  }, [registered]);

  const handleInput = (value: string) => {
    if (!value) {
      refCode.current?.focus();
    }
    setInputValue(value);
  };

  const handleRepeatCode = async () => {
    setIsTimer(true);
    await dispatch(getCodeApi(phoneNumber));
  };

  return (
    <div className={styles.auth_wrapper}>
      <div className={styles.auth_content}>
        <div className={styles.modal_user}>
          <h1>
            <FormattedMessage id="loginToPersonalAccount" />
          </h1>
          <div className={styles.body}>
            <div>
              <span>
                <FormattedMessage id="phoneNumber" />
              </span>
              <input id="login" disabled value={valuePhone} />
            </div>
            <div>
              <span>
                <FormattedMessage id="entercode_3" />
              </span>
              <input
                autoFocus
                ref={refCode}
                value={inputValue}
                onChange={(e) => handleInput(e.target.value)}
                placeholder="- - - -"
                className={cn({ [styles.input_error]: !!errorMsg })}
              />
              {!!errorMsg && (
                <div className={styles.error}>
                  <span className={styles.line_error}>
                    <FormattedMessage id="invalidCode" />
                  </span>
                </div>
              )}
            </div>
          </div>
          <button disabled={isTimer} onClick={handleRepeatCode}>
            <FormattedMessage id="repeatCode" />
          </button>
          <RenderWithCondition condition={isTimer}>
            <div className={styles.repeat_text}>
              <FormattedMessage id="timeCode" /> {` ${time.format('mm:ss')} `}
              <FormattedMessage id="sec" />
            </div>
          </RenderWithCondition>
          <RenderWithCondition condition={!isTimer}>
            <div className={styles.repeat_text}> {`СМС-код не получен`}</div>
          </RenderWithCondition>
        </div>
      </div>
    </div>
  );
};
