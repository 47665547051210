import React, { FC } from 'react';

import { Tasks } from '../tableMonth/Tasks';
import { IMapTasksDay } from 'interfaces/user.interfaces';
import styled from 'styled-components';
import './tableDay.scss';
import { mobile } from '@components/constants/constants';

interface ITableDay {
  data: IMapTasksDay[];
}

const TableDay: FC<ITableDay> = (props) => {
  return (
    <Container className="flex">
      <Tasks showCalendar data={props.data} textTitle={'tasks'} />
    </Container>
  );
};

export default TableDay;

const Container = styled.div`
  display: flex;
  padding: 30px;
  @media screen and (max-width: ${mobile}px) {
    padding: 30px 16px;
  }
`;
