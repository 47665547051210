import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import UserInfo from './components/userInfo/UserInfo';
import { TableMonth } from './components/tableMonth/TableMonth';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TableInfo } from './components/tableInfo/TableInfo';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { Sessions } from './components/sessions/Sessions';
import useTableUserStatistics from './hooks/useTableUserStatistics';
import dayjs from 'dayjs';
import { changeCurrentMonthDay, selectUserSlice } from '@store/user/slice';
import { Spacer } from '@components/commons/commonsStyled';
import useUserInfoData from './hooks/useUserInfoData';
import NavBarMonth from './components/navBarMonth/NavBarMonth';
import useGetUrlAvatar from '../../../hooks/useGetUrlAvatar';
import TableDay from './components/tableDay/TableDay';
import TableDynamics from './components/tableDynamics/TableDynamics';
import { useWidthScreens } from '../../../hooks/useWidthScreens';
import { colorsVar } from '@components/constants/colorVariables';
import { useAppSelector } from '@store/hooks';
import styled from 'styled-components';

const Month: FC = () => {
  const { isWideScreen, isMobile } = useWidthScreens();

  const dispatch = useDispatch();

  const { currentDate, userInfo } = useAppSelector(selectUserSlice);

  const urlAvatar = useGetUrlAvatar({ fileId: userInfo?.avatarFileId });

  const userTablesStatistic = useTableUserStatistics();
  const { dataUserMonth, dataUserDay } = userTablesStatistic;

  const userInfoData = useUserInfoData();
  const {
    mapTasksUserInfo,
    mapTasksStatisticUserInfo,
    userNamesLine,
    userShortNamesLine,
    connectionAssist,
  } = userInfoData;

  const handleStartData = (event: dayjs.Dayjs) => {
    dispatch(changeCurrentMonthDay(dayjs(event)));
  };

  return (
    <Container className="flex">
      <RenderWithCondition condition={isWideScreen}>
        <UserInfo
          userNamesLine={userNamesLine}
          urlAvatar={urlAvatar}
          mapItemInfo={mapTasksUserInfo}
          mapBlockInfo={mapTasksStatisticUserInfo}
          connectionAssist={connectionAssist}
        />
      </RenderWithCondition>
      <ContainerGroup className="flex">
        <NavBarMonth currentDate={currentDate} onChange={handleStartData} />
        <RenderWithCondition condition={!isWideScreen}>
          <>
            <Spacer px={isMobile ? 44 : 20} />
            <UserInfo
              userNamesLine={userNamesLine}
              mapItemInfo={mapTasksUserInfo}
              mapBlockInfo={mapTasksStatisticUserInfo}
              connectionAssist={connectionAssist}
            />
          </>
        </RenderWithCondition>
        <Routes>
          <Route path="/month" element={<TableMonth data={dataUserMonth} userName={userShortNamesLine} />} />
          <Route path="/day" element={<TableDay data={dataUserDay.mapTasksOfDay} />} />
          <Route path="/dynamics" element={<TableDynamics />} />
          <Route path="/info" element={<TableInfo user={userInfo} />} />
          <Route path="/sessions" element={<Sessions />} />
          <Route path="/" element={<Navigate to="/employees/month/month" />} />
        </Routes>
      </ContainerGroup>
    </Container>
  );
};

export default Month;

const Container = styled.div`
  flex-grow: 1;
`;

const ContainerGroup = styled.div`
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colorsVar.grey3};
`;
