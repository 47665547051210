import React, { useEffect, useMemo, useState } from 'react';

import { colorsVar } from '@components/constants/colorVariables';
import { IAssistFull, IManagersFull } from '@store/assistants/assistants.slice';
import Search from '@ui/search/Search';
import styled from 'styled-components';
import { IStateUser } from 'interfaces/user.interfaces';
import { LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import AvatarAssist from '@ui/avatarAssist/AvatarAssist';
import useGetUrlAvatar from '@hooks/useGetUrlAvatar';
import AvatarCardGroup from './Avatar.Card.Group';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import ButtonMain from '@ui/buttonMain/ButtonMain';

interface ICardGroup {
  dataGroup: any;
  handleEvent?: (value: any) => void;
  searchValue?: string;
  isLoading?: boolean;
}

const CardGroup = ({ dataGroup, handleEvent, searchValue, isLoading }: ICardGroup) => {
  const firstTenPersons = useMemo(() => dataGroup?.participants.slice(0, 10), [dataGroup?.participants]);
  const firstTenPersonsAccess = useMemo(() => dataGroup?.participantsWithAccess.slice(0, 10), [
    dataGroup?.participantsWithAccess,
  ]);
  return (
    <CardWrap onClick={() => handleEvent && handleEvent(dataGroup)}>
      <LineText fontSize="16px" bold={'700'} lineHeight="24px">
        {dataGroup.name}
      </LineText>
      <BlockUsers>
        <LineUsers>
          <LineText fontSize="12px" color={colorsVar.grey4} bold={'450'} lineHeight="24px">
            <FormattedMessage id="participants" />
          </LineText>
          <LineAvatars>
            {firstTenPersons.map((user: any, index: number) => (
              <AvatarCardGroup user={user} index={index} isLoading={isLoading} />
            ))}
            <RenderWithCondition condition={dataGroup?.participants.length > 10}>
              <LastPersons>
                <LineText fontSize="14px" color={colorsVar.white} bold={'400'} lineHeight="18px">
                  +{dataGroup?.participants.length - 10}
                </LineText>
              </LastPersons>
            </RenderWithCondition>
          </LineAvatars>
        </LineUsers>
        <LineUsers>
          <LineText fontSize="12px" color={colorsVar.grey4} bold={'450'} lineHeight="24px">
            <FormattedMessage id="accessRights" />
          </LineText>
          <LineAvatars>
            {firstTenPersonsAccess.map((user: any, index: number) => (
              <AvatarCardGroup user={user} index={index} isLoading={isLoading} />
            ))}
            <RenderWithCondition condition={dataGroup?.participantsWithAccess.length > 10}>
              <LastPersons>
                <LineText fontSize="14px" color={colorsVar.white} bold={'400'} lineHeight="18px">
                  +{dataGroup?.participantsWithAccess.length - 10}
                </LineText>
              </LastPersons>
            </RenderWithCondition>
          </LineAvatars>
        </LineUsers>
      </BlockUsers>
    </CardWrap>
  );
};

export default CardGroup;

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: ${colorsVar.white};
  cursor: pointer;
`;
const BlockUsers = styled.div`
  display: flex;
  flex-direction: column;
`;
const LineUsers = styled.div`
  display: flex;
  flex-direction: column;
`;
const LineAvatars = styled.div`
  display: flex;
`;
const LastPersons = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: -8px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  border: 1px solid ${colorsVar.grey4};
  background: ${colorsVar.greenBase};
  backdrop-filter: blur(20px);
`;
