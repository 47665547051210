import React, { FC, useEffect, useRef, useState } from 'react';

import { SelectDateMonth } from '@components/shared/selectDate/SelectDateMonth';
import { Link, NavLink, useLocation, useMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import { ReactComponent as MenuIcon } from '@assets/icons/menu_icon_12.svg';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useClickAway } from 'react-use';
import { uniqueId } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { mapNavBarMonth } from '../../constants';
import { useAuthSelector } from '@store/auth/selectors';
import cookieService from '@services/cookie.service';
import { SUPERVISOR, mobile } from '@components/constants/constants';
import { CURRENT_USER_ID } from '@configs/consts';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { colorsVar } from '@components/constants/colorVariables';

import styled from 'styled-components';
import styles from './navBarMonth.module.scss';

interface INavBarMonth {
  currentDate: dayjs.Dayjs;
  onChange: (event: dayjs.Dayjs) => void;
}

const NavBarMonth: FC<INavBarMonth> = (props) => {
  const { isWideScreen } = useWidthScreens();

  const [openNavBar, setOpenNavBar] = useState(false);

  const { mainUser } = useAuthSelector((state) => state.auth);

  const location = useLocation();
  const isMonthScreen = location.pathname === '/employees/month/month';

  const ref = useRef(null);
  useClickAway(ref, () => {
    setOpenNavBar(false);
  });

  const NavLinkContainer = isWideScreen ? NavBarLine : NavBarColumn;

  const handleClick = () => {
    setOpenNavBar(false);
  };

  const CustomLink = (props: any) => {
    const { children, to, ...rest } = props;
    const match = useMatch(to);
    return (
      <Link {...rest} to={to} className={styles.navLink}>
        {children}
      </Link>
    );
  };

  return (
    <Container className="flex">
      <RenderWithCondition condition={!isWideScreen}>
        <div onClick={() => setOpenNavBar(!openNavBar)} className="flex flex-col pl-4 justify-center cursor-pointer">
          <MenuIcon />
        </div>
      </RenderWithCondition>
      <RenderWithCondition condition={isWideScreen || openNavBar}>
        <NavLinkContainer className="flex" ref={ref}>
          {mapNavBarMonth.map((linkItem) => {
            const isAdminOrMain =
              !cookieService.get(SUPERVISOR) || mainUser.id === localStorage.getItem(CURRENT_USER_ID);
            const isShowLink = linkItem.titleLink === 'sessions' ? isAdminOrMain : true;
            return (
              <RenderWithCondition condition={isShowLink}>
                <NavLink
                  key={uniqueId(linkItem.titleLink)}
                  onClick={handleClick}
                  className={({ isActive }) => {
                    return [styles.navLink, isActive ? styles.active : null].join(' ');
                  }}
                  to={linkItem.goTo}
                >
                  <FormattedMessage id={linkItem.titleLink} />
                </NavLink>
              </RenderWithCondition>
            );
          })}
        </NavLinkContainer>
      </RenderWithCondition>
      <RenderWithCondition condition={isMonthScreen}>
        <SelectDateMonth
          type="input"
          onChange={props.onChange}
          id="firstEntryDateStartRange"
          value={props.currentDate}
          simpleVew
          position="left"
        />
      </RenderWithCondition>
    </Container>
  );
};

export default NavBarMonth;

const NavBarLine = styled.div`
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 700;
  background-color: ${colorsVar.white};
`;

const NavBarColumn = styled.div`
  position: absolute;
  top: 50px;
  left: 5px;
  width: 150px;
  align-items: start;
  flex-direction: column;
  z-index: 3;
  border: 1px solid ${colorsVar.grey4};
  border-radius: 10px;
  gap: 10px;
  font-size: 16px;
  font-weight: 700;
  background-color: ${colorsVar.white};
`;

const Container = styled.div`
  justify-content: space-between;
  height: 44px;
  padding-right: 20px;
  background-color: ${colorsVar.white};
  border: 1px solid ${colorsVar.grey1};
  position: relative;
  @media screen and (max-width: ${mobile}px) {
    padding-left: 48px;
  }
`;
