import React, { useMemo } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import { LineText } from '@components/commons/commonsStyled';
import AvatarAssist from '@ui/avatarAssist/AvatarAssist';
import { IStateUser } from 'interfaces/user.interfaces';

const initialUserParams = new URLSearchParams({
  thirdPartyUsersOnly: 'false',
}).toString();

interface IItemUserBlock {
  dataUser: Partial<IStateUser>;
  isLoading?: boolean;
}

const ItemUserBlock = ({ dataUser, isLoading }: IItemUserBlock) => {
  const nameUser: string = useMemo(() => {
    return `${dataUser?.lastName ?? ''} ${dataUser?.firstName ?? ''} ${dataUser?.middleName ?? ''}`;
  }, [dataUser]);

  return (
    <div className="flex gap-3">
      <AvatarAssist urlAvatar={undefined} />
      <div className="flex flex-col gap-[2px]">
        <LineText fontSize="14px" color={colorsVar.textColor} bold={'700'} lineHeight="16px">
          {nameUser}
        </LineText>
        <LineText fontSize="12px" color={colorsVar.grey4} bold={'450'} lineHeight="14px">
          {dataUser?.workPosition}
        </LineText>
      </div>
    </div>
  );
};

export default ItemUserBlock;
