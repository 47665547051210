import React, { useEffect, useState } from 'react';

import { IMapUserInfo } from 'interfaces/stats.interfaces';
import dayjs from 'dayjs';
import { CURRENT_USER_ID } from '@configs/consts';
import { IConnectionAssist } from 'interfaces/user.interfaces';
import { useGetUserInfoQuery, useGetUserStatisticQuery } from '@store/user/user.api';
import { useAppSelector } from '@store/hooks';
import { selectUserSlice } from '@store/user/slice';
import { isEmpty } from 'lodash';
import { useAuthSelector } from '@store/auth/selectors';

const useUserInfoData = () => {
  const [currentUserId, setCurrentUserId] = useState<string>('');

  const { currentUser } = useAppSelector(selectUserSlice);
  const { supervisor } = useAuthSelector((state) => state.auth);

  const { data: userInfo } = useGetUserInfoQuery(currentUserId, { skip: !currentUserId || supervisor });
  const { data: userStatistic } = useGetUserStatisticQuery(currentUserId, { skip: !currentUserId });

  const {
    lastEntryDate,
    firstTaskDate,
    lastTaskDate,
    todayTasks = 0,
    notDoneBusinessGoals = 0,
    doneBusinessGoals = 0,
    openedDelegatedTasks = 0,
    closedDelegatedTasks = 0,
    pausedDelegatedTasks = 0,
    openedPersonalTasks = 0,
    pausedPersonalTasks = 0,
    closedPersonalTasks = 0,
  } = userStatistic || {};

  useEffect(() => {
    if (currentUser?.id) {
      setCurrentUserId(currentUser?.id);
    } else {
      const id = localStorage.getItem(CURRENT_USER_ID);
      id && setCurrentUserId(id);
    }
  }, [currentUser?.id, userInfo]);

  const formattedDate = (value: string | null) => {
    if (value === null) {
      return '-';
    }
    return dayjs(value).format('DD.MM.YY');
  };

  const userNamesLine: string | null = React.useMemo(() => {
    if (isEmpty(userInfo)) return null;
    return `${userInfo.lastName ?? ''} ${userInfo.firstName ?? ''} ${userInfo.middleName ?? ''}`;
  }, [userInfo]);

  const userShortNamesLine: string = React.useMemo(
    () =>
      ` ${userInfo?.lastName ?? ''} ${userInfo?.firstName ? userInfo.firstName.slice(0, 1) + '.' : ''} ${
        userInfo?.middleName ? userInfo.middleName.slice(0, 1) + '.' : ''
      }`,
    [userInfo],
  );

  const mapTasksUserInfo: IMapUserInfo[] = React.useMemo(
    () => [
      {
        title: 'lastActivity',
        value: lastEntryDate ? formattedDate(lastEntryDate) : '-',
      },
      {
        title: 'tasksPlanedToday',
        value: todayTasks,
      },
      {
        title: 'createdFirstTask',
        value: firstTaskDate ? formattedDate(firstTaskDate) : '-',
      },
      {
        title: 'createdLastTask',
        value: lastTaskDate ? formattedDate(lastTaskDate) : '-',
      },
    ],
    [userStatistic, currentUser],
  );

  const mapTasksStatisticUserInfo: IMapUserInfo[][] = React.useMemo(
    () => [
      [
        {
          title: 'tasksOnUser',
          value: openedPersonalTasks + closedPersonalTasks + pausedPersonalTasks,
          boldTitle: '700',
        },
        {
          title: 'open',
          value: openedPersonalTasks,
          leftIndent: '10px',
        },
        {
          title: 'Приостановленныe',
          value: pausedPersonalTasks,
          leftIndent: '10px',
        },
        {
          title: 'closed',
          value: closedPersonalTasks,
          leftIndent: '10px',
        },
      ],
      [
        {
          title: 'tasksDelegatedToOther',
          value: openedDelegatedTasks + closedDelegatedTasks + pausedDelegatedTasks,
          boldTitle: '700',
        },
        {
          title: 'open',
          value: openedDelegatedTasks,
          leftIndent: '10px',
        },
        {
          title: 'Приостановленныe',
          value: pausedDelegatedTasks,
          leftIndent: '10px',
        },
        {
          title: 'closed',
          value: closedDelegatedTasks,
          leftIndent: '10px',
        },
      ],
      [
        {
          title: 'businessGoals',
          value: notDoneBusinessGoals + doneBusinessGoals,
          boldTitle: '700',
        },
        {
          title: 'open',
          value: notDoneBusinessGoals,
          leftIndent: '10px',
        },
        {
          title: 'achieved',
          value: doneBusinessGoals,
          leftIndent: '10px',
        },
      ],
    ],
    [userStatistic, currentUser],
  );

  const connectionAssist: IConnectionAssist | null = React.useMemo(() => {
    if (!userInfo?.isAssistant && userInfo?.managers) {
      return {
        isAssistant: true,
        managers: [...userInfo?.assistants],
      };
    }
    if (!userInfo?.isManager && userInfo?.assistants) {
      return {
        isManager: true,
        assistants: [...userInfo?.assistants],
      };
    }
    return null;
  }, [userInfo]);

  return {
    userNamesLine,
    mapTasksUserInfo,
    mapTasksStatisticUserInfo,
    userShortNamesLine,
    connectionAssist,
    urlAvatar: currentUser?.blobUrlAvatar,
  };
};

export default useUserInfoData;
