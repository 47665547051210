import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';
import { IGroups } from 'interfaces/groups.interfaces';
import { ReactComponent as BinRedIcon } from '@assets/icons/bin_000.svg';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/left_arrow.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit_pencil.svg';
import Notification from '@ui/notification/Notification';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import PopupModal from '@ui/modal/PopupModal';
import { IAddAssist, useDeleteAssistsMutation } from '@store/assistants/assistant.api';
import { useDispatch } from 'react-redux';
import { setState } from '@store/assistants/assistants.slice';
import ListParticipants from './ListParticipants.Group';
import { LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { useGetUsersListQuery } from '@store/user/user.api';
import { debounce } from 'lodash';
import { IStateUser } from 'interfaces/user.interfaces';

const initialUserParams = new URLSearchParams({
  thirdPartyUsersOnly: 'false',
}).toString();

interface IItemGroupEdit {
  groupData: IGroups;
  isLoading?: boolean;
  onBack: () => void;
  edit: 'access' | 'participants';
  handleData: (key: string, value: any) => void;
}

const ItemGroupEdit = ({ isLoading, groupData, onBack, edit, handleData }: IItemGroupEdit) => {
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [paramsString, setParamsString] = useState<string>(initialUserParams);
  const [search, setSearch] = useState('');
  const [searchParticipants, setSearchParticipants] = useState('');
  const [checkedUsers, setCheckedUsers] = useState<Partial<IStateUser>[]>([]);
  const [savedUsers, setSavedUsers] = useState<Partial<IStateUser>[]>([]);

  const { data: users, isLoading: loadUsers, isFetching } = useGetUsersListQuery(paramsString);

  const debounceValueParticipants = debounce((value) => {
    setSearchParticipants(value);
  }, 1000);

  const debounceValue = debounce((value) => {
    setSearch(value);
    const paramsString = new URLSearchParams({
      query: value,
      thirdPartyUsersOnly: 'false',
    }).toString();
    setParamsString(paramsString);
  }, 2000);

  function handleSearchParticipants(value: string) {
    debounceValueParticipants(value);
  }

  function handleSearchUsers(value: string) {
    debounceValue(value);
  }

  const participantsList = useMemo(() => {
    if (!searchParticipants) return groupData?.participants || [];
    return groupData?.participants?.filter((item) => JSON.stringify(item).includes(searchParticipants)) || [];
  }, [groupData, searchParticipants]);

  const accessRightList = useMemo(() => {
    if (!searchParticipants) return groupData?.participantsWithAccess || [];
    return groupData?.participantsWithAccess?.filter((item) => JSON.stringify(item).includes(searchParticipants)) || [];
  }, [groupData, searchParticipants]);

  const dataSavedUsersList = useMemo(() => {
    return edit === 'access' ? [...accessRightList, ...savedUsers] : [...participantsList, ...savedUsers] || [];
  }, [groupData, savedUsers]);

  const chooseUsersList = useMemo(() => {
    const notSavedUsers =
      (users?.length && users?.filter((user) => !dataSavedUsersList?.find((item) => item.id === user.id))) || [];
    const notCheckedUsers = notSavedUsers?.filter((user) => !checkedUsers?.find((item) => item.id === user.id)) || [];

    return [...checkedUsers, ...notCheckedUsers] || [];
  }, [users, checkedUsers]);

  const headerTexts = useMemo(() => {
    switch (edit) {
      case 'participants':
        return { title: 'editParticipants', subTitleLeft: 'addParticipants', subTitleRight: 'participantsGroup' };
      case 'access':
        return { title: 'editAccess', subTitleLeft: 'addParticipantsAccess', subTitleRight: 'participantsAccess' };
      default:
        return { title: 'editParticipants', subTitleLeft: 'addParticipants', subTitleRight: 'participantsGroup' };
    }
  }, [edit]);

  function handleCheckedUsers(value: Partial<IStateUser[]>) {
    setCheckedUsers([...checkedUsers, { ...value, isActiveTemp: true }]);
  }
  console.log('checkedUsers', checkedUsers, savedUsers);

  function handleSave() {
    if (checkedUsers?.length) {
      setSavedUsers([...savedUsers, ...checkedUsers]);
      setCheckedUsers([]);
      return;
    } else {
      if (edit === 'access') {
        handleData('participantsWithAccess', [...dataSavedUsersList]);
        onBack();
        return;
      }
      handleData('participants', [...dataSavedUsersList]);
      onBack();
    }
  }

  return (
    <ContentWrap>
      <HeaderWrap>
        <LeftBlock>
          <IconWrap onClick={onBack}>
            <ArrowLeftIcon />
          </IconWrap>
          <LineText fontSize="16px" color={colorsVar.textColor} bold={'700'} lineHeight="24px">
            <FormattedMessage id={headerTexts.title} />
          </LineText>
        </LeftBlock>
      </HeaderWrap>
      <div className="flex  gap-4">
        <ListParticipants
          titleText={headerTexts.subTitleLeft}
          dataList={chooseUsersList}
          editType="add"
          handleSearchUsers={handleSearchUsers}
          searchValue={search}
          changeCheckEvent={handleCheckedUsers}
        />
        <ListParticipants
          titleText={headerTexts.subTitleRight}
          dataList={dataSavedUsersList}
          editType="delete"
          handleSearchUsers={handleSearchParticipants}
          searchValue={searchParticipants}
        />
      </div>
      <ButtonWrap>
        <div className="w-[200px]">
          <ButtonMain clickEvent={handleSave} buttonText={checkedUsers.length ? 'add' : 'save'} />
        </div>
      </ButtonWrap>
    </ContentWrap>
  );
};

export default ItemGroupEdit;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 16px;
  width: 100%;
  align-items: center;
  background-color: ${colorsVar.grey3};
  height: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  padding: 4px 0px 30px;
  justify-content: end;
  height: 43px;
`;

const HeaderWrap = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorsVar.grey1};
  background: ${colorsVar.white};
`;

const LeftBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;
const IconWrap = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: 7px;
  &:hover {
    background-color: ${colorsVar.grey1};
    transition: background-color 0.5s;
  }
`;
