import { apiBaseQuery } from '../apiBaseQuery';
import { IStateUser } from '../../interfaces/user.interfaces';
import { DASHBOARD_SERVICE_URL } from '@components/constants/constants';
import {
  IActiveUsers,
  IBusinessGoalsMonth,
  IClosureTasks,
  ICompleteTasks,
  IPersonalGoalsMonth,
  IPlanedTasks,
  IUsersWithGoals,
  IUsersWithTasks,
} from 'interfaces/stats.interfaces';

export interface Response {
  content: IStateUser[];
}
export interface IUpdateUser {
  id: string;
  data: Partial<IStateUser>;
}
export interface IExcludeUser {
  id: string;
  spaceId: string;
}

export const statisticApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getActiveUsers: builder.query<IActiveUsers[], string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/users-count?${params}`,
      }),
    }),

    getUsersTasks: builder.query<IUsersWithTasks, string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/user-tasks-count?${params}`,
      }),
    }),

    getUsersGoals: builder.query<IUsersWithGoals, string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/user-goals-count?${params}`,
      }),
    }),

    getOpenTasks: builder.query<IPlanedTasks[], string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/tasks-planning?${params}`,
      }),
    }),

    getCompletionTasks: builder.query<ICompleteTasks[], string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/tasks-completion?${params}`,
      }),
    }),

    getCreationTasks: builder.query<IClosureTasks[], string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/tasks-creation?${params}`,
      }),
    }),

    getClosureTasks: builder.query<IClosureTasks[], string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/tasks-closure?${params}`,
      }),
    }),

    getBusinessGoals: builder.query<IBusinessGoalsMonth[], string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/business-goals-count?${params}`,
      }),
    }),

    getPersonalGoals: builder.query<IPersonalGoalsMonth[], string>({
      query: (params) => ({
        url: `${DASHBOARD_SERVICE_URL}/personal-goals-count?${params}`,
      }),
    }),
  }),
});

export const {
  useGetActiveUsersQuery,
  useGetUsersTasksQuery,
  useGetUsersGoalsQuery,
  useGetOpenTasksQuery,
  useGetCompletionTasksQuery,
  useGetCreationTasksQuery,
  useGetClosureTasksQuery,
  useGetBusinessGoalsQuery,
  useGetPersonalGoalsQuery,
} = statisticApi;
