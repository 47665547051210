import axios from 'axios';
import { BASE_URL } from '@configs/consts';
import { http } from '@utils/http';

export type DataGetAuthToken = {
  phone_number: string;
  sms_code: string;
  grant_type: 'sms_code';
  device: {
    device_id: string;
    brand: string;
    model: string;
    app_version: string;
    operating_system: string;
  };
};

export type ResponseGetAuthToken = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;
};

export type ResponseGetAuthSmsCode = {
  secondsToRetry: number;
  registered: boolean;
};

export interface DataRegister {
  firstName: string;
  lastName: string;
  middleName: string;
}

class AuthService {
  constructor() {}

  getAuthToken = async (data: DataGetAuthToken): Promise<ResponseGetAuthToken> => {
    const base64data = window.btoa('web-app:e2db76d8-bdb8-477d-910c-eb629191eafe');
    const headers = {
      Authorization: `Basic ${base64data}`,
      Accept: 'application/json',
    };
    return await axios.post(`${BASE_URL}/web/auth/token`, data, {
      headers: headers,
    });
  };

  getAuthSmsCode = async (phoneNumber: string): Promise<ResponseGetAuthSmsCode> => {
    const base64data = window.btoa('admin:xgt5rjVM');
    const headers = {
      Authorization: `Basic ${base64data}`,
      Accept: 'application/json',
    };

    const http = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
    return await http.post(
      `api/api-gateway/v1/confirmation/sms-code/${phoneNumber}`,
      {},
      {
        headers: headers,
      },
    );
  };

  getCodeAuthToken = async (data: DataGetAuthToken): Promise<ResponseGetAuthToken> => {
    return await axios.post(`${BASE_URL}/mobile/auth/token`, data);
  };

  getStatusUser = async (data: string): Promise<{}> => {
    const base64data = window.btoa('admin:xgt5rjVM');
    const headers = {
      Authorization: 'Basic ' + base64data,
      Accept: 'application/json',
    };
    return await fetch(`${BASE_URL}/api/admin-service/v1/users/by-login/${data}/is-admin`, {
      headers: headers,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  getMainUser = async () => {
    return await http.get(`/api/uaa/v1/users/info`);
  };

  deleteCurrentSession = async () => {
    return await http.delete(`/api/uaa/v1/sessions/current/logout`);
  };
}

export const authService = new AuthService();
