import { apiBaseQuery } from '../apiBaseQuery';
import { IUserSessions } from '../../interfaces/user.interfaces';
import { ADMIN_URL, SESSIONS_URL } from '@components/constants/constants';

export const userApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getUserSessions: builder.query<IUserSessions[], string | null>({
      query: (params) => ({
        url: `${SESSIONS_URL}/active${params ? `?${params}` : ''}`,
      }),
      providesTags: ['Sessions'],
    }),

    removeOwnSessions: builder.mutation<void, string>({
      query: (params) => ({
        url: `${SESSIONS_URL}/logout?ids=${params}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sessions'],
    }),
    removeSessions: builder.mutation<void, string>({
      query: (params) => ({
        url: `${ADMIN_URL}/sessions/logout?ids=${params}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sessions'],
    }),
  }),
});

export const { useGetUserSessionsQuery, useRemoveOwnSessionsMutation, useRemoveSessionsMutation } = userApi;
