import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import Search from '@ui/search/Search';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from '@assets/icons/edit_pencil.svg';
import { ReactComponent as BinIcon } from '@assets/icons/bin_000.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/Icon_plus.svg';
import { LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import ItemUserBlock from './Item.User.Block';
import { IStateUser } from 'interfaces/user.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import CheckInputMod from '@ui/checkInput/CheckInputMod';

const initialUserParams = new URLSearchParams({
  thirdPartyUsersOnly: 'false',
}).toString();

interface IListParticipants {
  titleText?: string;
  dataList?: Partial<IStateUser>[];
  openEditPage?: () => void;
  editType?: 'add' | 'delete';
  handleSearchUsers: (value: string) => void;
  searchValue: string;
  isLoading?: boolean;
  changeCheckEvent?: (value: any) => void;
}

const ListParticipants = ({
  titleText,
  openEditPage,
  searchValue,
  isLoading,
  dataList,
  editType,
  handleSearchUsers,
  changeCheckEvent,
}: IListParticipants) => {
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [participantsParams, setParticipantsParams] = useState<string>(initialUserParams);
  const [checkedNotLoggedIn, setCheckedNotLoggedIn] = useState(false);

  const [currentGroup, setCurrentGroup] = useState('');

  const typeIcon = useMemo(() => {
    if (dataList?.length) {
      return <EditIcon />;
    }
    return <PlusIcon />;
  }, [dataList?.length]);

  function handleOpenGroup(value: string) {
    setOpenNotification(true);
    setCurrentGroup(value);
  }

  return (
    <ItemGroupWrap>
      <TitleBlock>
        <div className="flex gap-2">
          <LineText fontSize="16px" color={colorsVar.textColor} bold={'700'} lineHeight="24px">
            <FormattedMessage id={titleText} />
          </LineText>
          <Amount>
            <LineText fontSize="14px" color={colorsVar.textColor} bold={'450'} lineHeight="22px">
              {dataList?.length}
            </LineText>
          </Amount>
        </div>
        <RenderWithCondition condition={!editType}>
          <Amount transparent onClick={openEditPage}>
            {typeIcon}
          </Amount>
        </RenderWithCondition>
      </TitleBlock>

      <Search
        handleSearch={handleSearchUsers}
        isLoading={isLoading}
        searchValue={searchValue}
        className="max-h-[32px]"
      />
      <RenderWithCondition condition={editType === 'add'}>
        <div className="flex w-full justify-end gap-2">
          <LineText fontSize="12px" color={colorsVar.textColor} bold={'450'} lineHeight="14px">
            <FormattedMessage id="selectAll" />
          </LineText>
          <CheckInputMod
            changeEvent={() => {
              setCheckedNotLoggedIn(!checkedNotLoggedIn);
            }}
            isActive={checkedNotLoggedIn ?? false}
            isDisabled={false}
            size="small"
          />
        </div>
      </RenderWithCondition>

      <RenderWithCondition condition={editType === 'delete'}>
        <div className="flex w-full justify-end gap-2">
          <Amount transparent onClick={openEditPage}>
            <LineText fontSize="12px" color={colorsVar.textColor} bold={'450'} lineHeight="14px">
              <FormattedMessage id="deleteAll" />
            </LineText>
            <BinIcon fill={colorsVar.greenLine} width={14} height={14} />
          </Amount>
        </div>
      </RenderWithCondition>

      <ListItems edit={!!editType}>
        {dataList?.map((user) => {
          return (
            <div className="flex w-full" key={user.id}>
              <RenderWithCondition condition={!editType}>
                <ItemUserBlock dataUser={user} />
              </RenderWithCondition>
              <RenderWithCondition condition={editType}>
                <EditWrap>
                  <ItemUserBlock key={user.id} dataUser={user} />

                  <div className="flex gap-2 cursor-pointer" onClick={() => undefined}>
                    <RenderWithCondition condition={editType === 'delete'}>
                      <BinIcon fill={colorsVar.greenLine} />
                    </RenderWithCondition>
                    <RenderWithCondition condition={editType === 'add'}>
                      <CheckInputMod
                        changeEvent={() => {
                          changeCheckEvent?.(user);
                        }}
                        isActive={user?.isActiveTemp ?? false}
                        isDisabled={false}
                      />
                    </RenderWithCondition>
                  </div>
                </EditWrap>
              </RenderWithCondition>
            </div>
          );
        })}
      </ListItems>
    </ItemGroupWrap>
  );
};

export default ListParticipants;

const ItemGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${colorsVar.white};
  border-radius: 16px;
  gap: 12px;
  padding: 24px;
  position: relative;
  width: 426px;
`;
const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;
const ListItems = styled.div<{ edit?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 430px);
  overflow-y: auto;
  width: 104.5%;
  padding-right: 10px;
  margin-right: -20px;
  gap: 16px;
  ${({ edit }) =>
    edit &&
    `
    gap: 0px;
  `}
`;
const EditWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid ${colorsVar.grey1};
`;
const Amount = styled.div<{ transparent?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid ${colorsVar.grey4};
  border-radius: 6px;
  background-color: ${colorsVar.grey1};
  transition: background-color 0.3s linear;
  padding: 0px 6px;
  height: 24px;
  min-width: 24px;
  gap: 10px;

  ${({ transparent }) =>
    transparent &&
    `
    cursor: pointer;
    background-color: transparent;
    &:hover {
      background-color: ${colorsVar.grey1};
      transition: background-color 0.5s ease;
    }
  `}
`;
