import { apiBaseQuery } from '../apiBaseQuery';
import { ASSISTANT_SETTING_URL, SERVICE_URL } from '@components/constants/constants';
import { IAssistFull, IManagersFull } from './assistants.slice';

export interface Response {
  content: IAssistFull[];
}
export interface ResponseManager {
  content: IManagersFull[];
}
export interface IAddAssist {
  assistantId: string;
  managerIds: string[];
}
export interface IUpdateManager {
  managerId: string;
  assistantIds: string[];
}

export const assistantApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getAssistList: builder.query({
      query: (params) => ({
        url: `${SERVICE_URL}/assistants/page?${params}`,
      }),
      providesTags: ['Assist'],
      transformResponse: (response: Response) => {
        return response.content;
      },
    }),

    getManagerList: builder.query({
      query: (params) => ({
        url: `${SERVICE_URL}/managers/page?${params}`,
      }),
      providesTags: ['Manager'],
      transformResponse: (response: ResponseManager) => {
        return response.content;
      },
    }),

    addAssists: builder.mutation<void, IAddAssist[]>({
      query: (data) => ({
        body: data,
        url: `${ASSISTANT_SETTING_URL}/managers/add`,
        method: 'POST',
      }),
      invalidatesTags: ['Assist', 'Manager'],
    }),

    deleteAssists: builder.mutation<void, IAddAssist[]>({
      query: (data) => ({
        body: data,
        url: `${ASSISTANT_SETTING_URL}/managers/remove`,
        method: 'POST',
      }),
      invalidatesTags: ['Assist', 'Manager'],
    }),

    updateAssists: builder.mutation<void, IAddAssist[]>({
      query: (data) => ({
        body: data,
        url: `${ASSISTANT_SETTING_URL}/managers`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Assist', 'Manager'],
    }),

    updateManager: builder.mutation<void, IUpdateManager[]>({
      query: (data) => ({
        body: data,
        url: `${ASSISTANT_SETTING_URL}/assistants`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Assist', 'Manager'],
    }),

    updateManagerUserInfo: builder.mutation<void, IUpdateManager[]>({
      query: (data) => ({
        body: data,
        url: `${ASSISTANT_SETTING_URL}/assistants`,
        method: 'PATCH',
      }),
      invalidatesTags: ['UserInfo'],
    }),
  }),
});

export const {
  useGetAssistListQuery,
  useGetManagerListQuery,
  useAddAssistsMutation,
  useDeleteAssistsMutation,
  useUpdateAssistsMutation,
  useUpdateManagerMutation,
  useUpdateManagerUserInfoMutation,
} = assistantApi;
