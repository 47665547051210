import React, { useEffect, useState, useRef, FC } from 'react';

import { TableOthers } from './components/tableOthers';
import { ModalUser } from '@components/shared/modalUser';
import { ModalFilter } from '@components/shared/modalFilter';
import { ControlBlock } from '@components/shared/controlBlock';
import { titleOthers } from './constants';
import { LIMIT_PAGE, SPACE_ID_DOGMA } from '@components/constants/constants';
import { usePagination } from '../../../hooks/usePagination';
import { selectOtherSlice, setFilterOthers } from '@store/other/slice';
import { debounce } from 'lodash';
import { getFilterWithoutNull } from '../../../utils/getOptionsDepartments';
import { useAppSelector } from '@store/hooks';
import {
  useAddOtherIntoEmployeesMutation,
  useCreateOtherMutation,
  useDeleteUserMutation,
  useGetOthersListQuery,
  useGetSpaceIdsQuery,
  useUpdateOtherMutation,
} from '@store/other/other.api';
import notify from '@utils/notify';
import { toast } from 'react-toastify';
import PopupModal from '@ui/modal/PopupModal';
import Notification from '@ui/notification/Notification';

const initialOtherParams = new URLSearchParams({
  thirdPartyUsersOnly: 'true',
}).toString();

const Others: FC = () => {
  const [isVisibleFilter, setIsVisibleFilter] = useState<boolean | null>(false);
  const [isVisibleUser, setIsVisibleUser] = useState<boolean | null>(false);
  const [isVisibleNotification, setVisibleNotification] = useState<boolean | null>(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState('');
  const [paramsString, setParamsString] = useState<string>(initialOtherParams);

  const refUserId = useRef('');
  const { filter } = useAppSelector(selectOtherSlice);

  const { data: others, isLoading, isFetching } = useGetOthersListQuery(paramsString);
  const { data: spaceIds } = useGetSpaceIdsQuery(paramsString);

  const [updateOther, updateResult] = useUpdateOtherMutation();
  const [deleteOther, deleteResult] = useDeleteUserMutation();
  const [addOtherIntoEmployees, addOtherResult] = useAddOtherIntoEmployeesMutation();
  const [createOther, createOtherResult] = useCreateOtherMutation();

  const { nextLoad, start } = usePagination(others?.length ?? 0, LIMIT_PAGE);

  const debounceValue = debounce((value) => {
    setSearch(value);
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      query: value,
      ...tempObj,
      thirdPartyUsersOnly: 'true',
    }).toString();
    setParamsString(paramsString);
  }, 2000);

  useEffect(() => {
    const usedFilter = Object.values(filter).every((value) => value === null);
    setIsFiltered(usedFilter);
  }, [filter]);

  useEffect(() => {
    if (addOtherResult.isSuccess) {
      toast.success('Пользователь добавлен в сотрудники', {
        draggable: true,
        position: 'top-center',
      });
      setVisibleNotification(false);
    }
    if (addOtherResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [addOtherResult]);

  useEffect(() => {
    if (createOtherResult.isSuccess) {
      toast.success('Новый пользователь успешно добавлен', {
        draggable: true,
        position: 'top-center',
      });
      setIsVisibleUser(false);
    }
    if (createOtherResult.error) {
      notify.error('Не удалось добавить пользователя');
    }
  }, [createOtherResult]);

  useEffect(() => {
    if (deleteResult.isSuccess) {
      toast.success('Пользователь удален', {
        draggable: true,
        position: 'top-center',
      });
      setVisibleNotification(false);
    }
    if (deleteResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [deleteResult]);

  useEffect(() => {
    if (updateResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [updateResult]);

  function handleFilteredOther() {
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      thirdPartyUsersOnly: 'true',
      query: search,
      ...tempObj,
    }).toString();
    setParamsString(paramsString);
    setIsVisibleFilter(false);
  }

  function handleGetMoreOthers() {
    nextLoad();
    const tempObj = getFilterWithoutNull(filter);
    const newParamsString = new URLSearchParams({
      query: search,
      thirdPartyUsersOnly: 'true',
      ...tempObj,
      page: `${start}`,
    }).toString();
    if (others?.length && Number.isInteger(others?.length / LIMIT_PAGE)) {
      setParamsString(newParamsString);
    }
  }

  function handleSearchOthers(value: string) {
    debounceValue(value);
  }

  function handleChangeTest(id: string, login: string, test: boolean) {
    const newData = {
      id,
      data: {
        isTest: test,
      },
    };
    updateOther(newData);
  }

  function handleDeleteOther(id: string) {
    refUserId.current = id;
    setVisibleNotification(true);
  }

  function confirmAccess() {
    deleteOther(refUserId.current);
  }

  function handleAddEmployees(id: string) {
    if (SPACE_ID_DOGMA) {
      addOtherIntoEmployees({ id, spaceId: SPACE_ID_DOGMA });
    }
  }

  function handleCreateOther(data: any) {
    const newData = {
      ...data,
      type: 'PHONE_NUMBER',
    };
    createOther(newData);
  }

  return (
    <>
      <ControlBlock
        title={titleOthers}
        openForm={setIsVisibleUser}
        openFilter={setIsVisibleFilter}
        handleSearch={handleSearchOthers}
        searchValue={search}
        isLoading={isLoading ? false : isFetching}
      />
      <TableOthers
        othersList={others ?? []}
        isLoading={isLoading}
        isLoadingExpand={isLoading ? false : isFetching}
        handleDelete={handleDeleteOther}
        handleAddEmployees={handleAddEmployees}
        handleGetOthers={handleGetMoreOthers}
        handleChangeTest={handleChangeTest}
        isNotSuccessUpdate={updateResult.status === 'rejected'}
      />

      <PopupModal isShown={isVisibleUser} closeEvent={(value) => setIsVisibleUser(value)}>
        <ModalUser isEmployee={false} setIsVisible={setIsVisibleUser} handleCreate={handleCreateOther} />
      </PopupModal>

      <PopupModal isShown={isVisibleFilter} closeEvent={(value) => setIsVisibleFilter(value)}>
        <ModalFilter
          filter={filter}
          isOthers={true}
          isFiltered={isFiltered}
          setFilter={setFilterOthers}
          setIsVisible={setIsVisibleFilter}
          handleFiltered={handleFilteredOther}
        />
      </PopupModal>

      <PopupModal isShown={isVisibleNotification} closeEvent={(value) => setVisibleNotification(value)}>
        <Notification
          textTitle={'wantToDeleteOther'}
          onDone={confirmAccess}
          onCancel={() => setVisibleNotification(false)}
        />
      </PopupModal>
    </>
  );
};

export default Others;
