import React from 'react';

import { ReactComponent as BinIconGreen } from '@assets/icons/bin-green.svg';
import { IStateUser } from 'interfaces/user.interfaces';
import { FormattedMessage } from 'react-intl';

interface ITableAdminItem {
  user: IStateUser;
  i: number;
  handleDelete: (
    id: string,
    data: {
      id: string;
      login: string;
    },
  ) => void;
  handleGetIdPassword: (id: string) => void;
  setModalOpen: (arg: boolean) => void;
}

export const TableAdminItem = ({ i, user, handleDelete, setModalOpen, handleGetIdPassword }: ITableAdminItem) => {
  const handleChange = (id: string) => {
    setModalOpen(true);
    handleGetIdPassword(id);
  };

  const getLogin = (user: IStateUser) => {
    let data = { id: '', login: '' };
    user.accounts.map((account) => {
      if (account.type === 'LOGIN') {
        data.login = account.login;
        data.id = account.id;
      }
      return account;
    });
    return data;
  };

  const handleDeleteAdmin = (id: string) => {
    let data = getLogin(user);
    handleDelete(id, data);
  };

  return (
    <div className="row" style={{ backgroundColor: i % 2 === 0 ? '#F5F9F8' : '#FFFFFF' }}>
      <div>
        <span>{getLogin(user).login}</span>
      </div>
      <div>
        <span>{`${user?.lastName} ${user?.firstName} ${user?.middleName}`}</span>
      </div>
      <div className="dates">
        <span onClick={() => handleChange(getLogin(user).id)}>
          <FormattedMessage id="resetPassword" />
        </span>
      </div>
      <div onClick={() => handleDeleteAdmin(user.id)} className="admin-delete">
        <BinIconGreen />
      </div>
    </div>
  );
};
