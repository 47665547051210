import React, { useEffect, useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { mobile, tablet } from '@components/constants/constants';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import PopupModal from '@ui/modal/PopupModal';
import DropDown from '@ui/dropDown/DropDown';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { useAppSelector } from '@store/hooks';
import { useDispatch } from 'react-redux';
import { selectAssistSlice, setState } from '@store/assistants/assistants.slice';
import { IPartialAssist } from '@components/screens/assistants/components/TableAssistItem';
import { truncateName } from '@utils/formattedText';
import { IStateUser } from 'interfaces/user.interfaces';
import styled from 'styled-components';

interface IModalAssist {
  isOpenPopup: boolean | null;
  setOpenPopup: (arg: boolean | null) => void;
  textTitle: string;
  managerName?: string;
  tabActive?: boolean;
  onSave: (managersTds: any, assistIds: any) => void;
  handleSearch: (value: string) => void;
  searchValue?: string;
  assistantName?: string;
  checkedData?: IPartialAssist[];
  users?: IStateUser[];
  isLoadingUsers?: boolean;
}

const ModalAssist = ({
  isOpenPopup,
  setOpenPopup,
  textTitle,
  assistantName,
  managerName,
  onSave,
  tabActive,
  checkedData,
  handleSearch,
  searchValue,
  users,
  isLoadingUsers,
}: IModalAssist) => {
  const { isMobile } = useWidthScreens();
  const dispatch = useDispatch();

  const { currentDataForUpdateManager, currentUserListForCreating, currentDataForUpdate } = useAppSelector(
    selectAssistSlice,
  );

  const [checkedAssist, setCheckedAssist] = useState<IPartialAssist[]>([]);
  const [checkedManager, setCheckedManager] = useState<IPartialAssist[]>([]);

  useEffect(() => {
    if (!isOpenPopup) {
      setCheckedAssist([]);
      setCheckedManager([]);
    }
  }, [isOpenPopup]);

  useEffect(() => {
    if ((currentDataForUpdateManager || currentDataForUpdate) && checkedManager?.length === 0 && isOpenPopup) {
      const dataForCheckedManager = currentDataForUpdateManager
        ? currentDataForUpdateManager?.assistants?.map((item) => ({ ...item, isActive: true }))
        : [];
      const dataForCheckedAssist = currentDataForUpdate
        ? currentDataForUpdate?.managers?.map((item) => ({ ...item, isActive: true }))
        : [];
      setCheckedManager(currentDataForUpdateManager ? dataForCheckedManager : dataForCheckedAssist);
    }
  }, [currentDataForUpdate, currentDataForUpdateManager, checkedAssist?.length, checkedManager?.length]);

  //TODO: =============   CREATE ASSISTANT   =================
  useEffect(() => {
    if (!assistantName && !checkedData) {
      if (!checkedAssist?.length && !checkedManager?.length && users) {
        dispatch(
          setState({
            currentUserListForCreating: [...users.filter((user) => !user.isAssistant && !user.isManager)],
          }),
        );
      }
      if (!checkedAssist?.length && checkedManager?.length && users) {
        const currentList = [...users.filter((user) => !user.isAssistant && !user.isManager)];
        const listWithOutManager = currentList.filter((user) => {
          return !checkedManager?.some((item) => {
            return item.id === user.id;
          });
        });
        dispatch(
          setState({
            currentUserListForCreating: listWithOutManager,
          }),
        );
      }
      if (checkedAssist?.length && !checkedManager?.length && users) {
        const currentList = [...users.filter((user) => !user.isAssistant && !user.isManager)];
        const listWithOutAssist = currentList.filter((user) => {
          return !checkedAssist?.some((item) => {
            return item.id === user.id;
          });
        });
        dispatch(
          setState({
            currentUserListForCreating: listWithOutAssist,
          }),
        );
      }
    }
  }, [checkedManager, JSON.stringify(checkedAssist), JSON.stringify(users), isLoadingUsers]);

  //TODO: =============   UPDATE ASSISTANT   =================

  useEffect(() => {
    if (checkedManager && checkedManager?.length && users) {
      const currentList = [...users.filter((user) => !(user?.assistantsIds?.length === 3) && !user.isAssistant)];
      const currentListAssist = [...users.filter((user) => !(user?.managerIds?.length === 3) && !user.isManager)];
      const listWithOutManager = currentListAssist.filter((user) => {
        return !checkedManager?.some((item) => {
          return item.id === user.id;
        });
      });
      const listWithOutAssist = currentList.filter((user) => {
        return !checkedManager?.some((item) => {
          return item.id === user.id;
        });
      });
      const currentUserList = tabActive ? listWithOutManager : listWithOutAssist;

      dispatch(
        setState({
          currentUserListForCreating: [...currentUserList],
        }),
      );

      dispatch(
        setState({
          checkedDataForUpdate: [...checkedManager],
        }),
      );
    }
  }, [checkedManager, JSON.stringify(users), isLoadingUsers]);

  function handleCheckedAssist(value: IPartialAssist) {
    if (!checkedAssist?.length) {
      setCheckedAssist([...checkedAssist, { ...value, isActive: true }]);
    }
    if (checkedAssist?.length === 1) {
      setCheckedAssist([]);
    }
  }

  function handleCheckedManager(value: IPartialAssist) {
    if (checkedManager?.some((item) => item.id === value.id)) {
      setCheckedManager(checkedManager.filter((item) => item.id !== value.id));
    } else {
      setCheckedManager([...checkedManager, { ...value, isActive: true }]);
    }
  }

  const textHeaderAssist = useMemo(() => {
    if (checkedAssist?.length === 1) {
      return `${checkedAssist[0].lastName} ${checkedAssist[0].firstName} ${checkedAssist[0].middleName}`;
    }
    if (checkedAssist?.length === 0) {
      return '';
    }
  }, [checkedAssist]);

  const textCheckedAssist = useMemo(() => {
    if (checkedAssist?.length) {
      return checkedAssist
        .map((item) => `${item.lastName} ${truncateName(item.firstName)} ${truncateName(item.middleName)}`)
        .join(', ');
    }
    if (checkedAssist?.length === 0) {
      return '';
    }
  }, [checkedAssist]);

  const textCheckedManager = useMemo(() => {
    if (checkedManager?.length) {
      return checkedManager
        .map((item) => `${item.lastName} ${truncateName(item.firstName)} ${truncateName(item.middleName)}`)
        .join(', ');
    }
    if (checkedManager?.length === 0) {
      return '';
    }
  }, [checkedManager]);

  return (
    <PopupModal isShown={isOpenPopup} closeEvent={(v) => setOpenPopup(v)}>
      <ModalContent className="flex">
        <LineText className="justify-center" fontSize={isMobile ? '24px' : '40px'}>
          <FormattedMessage id={textTitle} />
        </LineText>
        <RenderWithCondition condition={!assistantName}>
          <>
            <RenderWithCondition condition={managerName}>
              <div className="flex gap-2">
                <LineText className="justify-center" fontSize={isMobile ? '16px' : '18px'}>
                  <FormattedMessage id={'manager'} />:
                </LineText>
                <LineText className="justify-center" fontSize={isMobile ? '16px' : '18px'} bold="700">
                  {managerName}
                </LineText>
              </div>
            </RenderWithCondition>
            <DropDown
              handleCheck={handleCheckedAssist}
              textLabel="assistName"
              dropList={currentUserListForCreating}
              checkedList={checkedAssist}
              textHeader={textHeaderAssist}
              assist={!!checkedData}
              isOpenPopup={isOpenPopup}
              handleSearch={handleSearch}
              isLoading={isLoadingUsers ?? false}
              searchValue={searchValue}
            />
          </>
        </RenderWithCondition>
        <RenderWithCondition condition={!managerName}>
          <>
            <RenderWithCondition condition={assistantName}>
              <div className="flex gap-2">
                <LineText className="justify-center" fontSize={isMobile ? '15px' : '18px'}>
                  <FormattedMessage id={tabActive ? 'manager' : 'assistant'} />:
                </LineText>
                <LineText className="justify-center" fontSize={isMobile ? '15px' : '18px'} bold="700">
                  {assistantName}
                </LineText>
              </div>
            </RenderWithCondition>
            <DropDown
              handleCheck={handleCheckedManager}
              textLabel={tabActive ? 'assistName' : 'managerName'}
              dropList={currentUserListForCreating}
              checkedList={checkedManager}
              textHeader={textCheckedManager}
              assist={!!checkedData}
              isOpenPopup={isOpenPopup}
              handleSearch={handleSearch}
              isLoading={isLoadingUsers ?? false}
              searchValue={searchValue}
            />
          </>
        </RenderWithCondition>

        <ButtonMain
          clickEvent={() => {
            onSave(
              checkedManager.map((item) => item.id),
              checkedAssist.map((item) => item.id),
            );
            setCheckedAssist([]);
            setCheckedManager([]);
          }}
          buttonText={!assistantName && !managerName ? 'add' : 'save'}
          isDisabled={checkedData ? !checkedManager?.length : !(checkedAssist?.length && checkedManager?.length)}
        />
      </ModalContent>
    </PopupModal>
  );
};

export default ModalAssist;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media screen and (max-width: ${tablet}px) {
    padding: 10px;
  }
  @media screen and (max-width: ${mobile}px) {
    padding: 10px 0;
    gap: 24px;
  }
`;
