import React, { FC, useEffect, useState } from 'react';

import { commonTooltip } from '@components/screens/home/Home';
import { ChartPlanComplete } from '@components/screens/home/components/chartPlanComplete';
import { linesDynamicsCompletionTasks, linesDynamicsPlaningTasks } from '@components/screens/home/constants';
import {
  addEndDynamicsCompletionDate,
  addEndDynamicsPlaningDate,
  addStartDynamicsCompletionDate,
  addStartDynamicsPlaningDate,
  selectDynamicsSlice,
} from '@store/dynamics/slice';
import { mapCompleteTasksOfUser, mapTitleLinesPlaningTasks } from './charts/constants';
import { CURRENT_USER_ID } from '@configs/consts';
import RadarChartPersonCompleteness from './charts/RadarChartPersonCompleteness';
import BarChartMonthBusinessGoals from './charts/BarChartMonthBusinessGoals';
import { useAppSelector } from '@store/hooks';
import { selectUserSlice } from '@store/user/slice';
import { useGetDynamicsCompletionTasksQuery, useGetDynamicsPlaningTasksQuery } from '@store/dynamics/dynamics.api';
import { selectDeviceSlice } from '@store/device/device.slice';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';

const TableDynamics: FC = () => {
  const { width } = useWidthScreens();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isWideScreen, setIsWideScreen] = useState(true);
  const [paramsPlaningTasks, setParamsPlaningTasks] = useState('');
  const [paramsCompletionTasks, setParamsCompletionTasks] = useState('');

  const { currentUser } = useAppSelector(selectUserSlice);
  const currentUserId = currentUser?.id ? currentUser.id : localStorage.getItem(CURRENT_USER_ID);

  const {
    dynamicsPlaningTasksPeriod,
    isLoadingPlaningTasks,
    dynamicsCompletionTasksPeriod,
    isLoadingCompletionTasks,
  } = useAppSelector(selectDynamicsSlice);

  const { data: dynamicsPlaningTasks } = useGetDynamicsPlaningTasksQuery({
    id: currentUserId ?? '',
    params: paramsPlaningTasks,
  });

  const { data: dynamicsCompletionTasks } = useGetDynamicsCompletionTasksQuery({
    id: currentUserId ?? '',
    params: paramsCompletionTasks,
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1051px)');
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setIsWideScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const { isHiddenNavbar } = useAppSelector(selectDeviceSlice);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, [isHiddenNavbar, width]);

  return (
    <ScrollWrapper className="flex ">
      <ContainerCharts className="flex">
        <BlockChart className="flex">
          <BarChartMonthBusinessGoals tooltip={commonTooltip} position={'right'} isLoadingPage={isLoading} />
          <RadarChartPersonCompleteness position={isWideScreen ? 'left' : 'right'} isLoadingPage={isLoading} />
        </BlockChart>
        <BlockChart className="flex">
          <ChartPlanComplete
            planed={true}
            data={dynamicsPlaningTasks ?? []}
            tooltip={commonTooltip}
            getData={setParamsPlaningTasks}
            period={dynamicsPlaningTasksPeriod}
            renderLine={linesDynamicsPlaningTasks}
            setEndDate={addEndDynamicsPlaningDate}
            isLoading={isLoadingPlaningTasks}
            setStartDate={addStartDynamicsPlaningDate}
            currentUser={currentUserId}
            textTitle="dynamicsPlaningTasks"
            lineData={mapTitleLinesPlaningTasks}
            position="right"
            isLoadingPage={isLoading}
          />
        </BlockChart>
        <BlockChart className="flex">
          <ChartPlanComplete
            planed={true}
            data={dynamicsCompletionTasks ?? []}
            tooltip={commonTooltip}
            getData={setParamsCompletionTasks}
            period={dynamicsCompletionTasksPeriod}
            renderLine={linesDynamicsCompletionTasks}
            setEndDate={addEndDynamicsCompletionDate}
            isLoading={isLoadingCompletionTasks}
            setStartDate={addStartDynamicsCompletionDate}
            currentUser={currentUserId}
            textTitle="closePlansDynamics"
            lineRectanglesData={mapCompleteTasksOfUser}
            position="right"
            isLoadingPage={isLoading}
          />
        </BlockChart>
      </ContainerCharts>
    </ScrollWrapper>
  );
};

export default TableDynamics;

export const ScrollWrapper = styled.div`
  flex-direction: column;
  overflow-x: auto;
  @media screen and (max-width: 800px) {
    width: 100vw;
  }
`;
export const ContainerCharts = styled.div`
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  @media screen and (max-width: 800px) {
    padding: 30px 16px;
    min-width: 650px;
  }
`;

const BlockChart = styled.div`
  flex-grow: 1;
  gap: 60px 4%;
  position: relative;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
