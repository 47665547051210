import { IDynamicsCompletionTasks, IDynamicsPlaningTasks, IMonthBusinessGoals } from 'interfaces/dynamics.interfaces';
import { apiBaseQuery } from '../apiBaseQuery';
import { DASHBOARD_SERVICE_URL } from '@components/constants/constants';

export const assistantApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getMonthBusinessGoals: builder.query<IMonthBusinessGoals[], Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${DASHBOARD_SERVICE_URL}/users/${id}/business-goals-count?${params}`,
      }),
    }),

    getDynamicsPlaningTasks: builder.query<IDynamicsPlaningTasks[], Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${DASHBOARD_SERVICE_URL}/users/${id}/tasks-priority?${params}`,
      }),
    }),

    getDynamicsCompletionTasks: builder.query<IDynamicsCompletionTasks[], Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${DASHBOARD_SERVICE_URL}/users/${id}/tasks-completion?${params}`,
      }),
    }),
  }),
});

export const {
  useGetMonthBusinessGoalsQuery,
  useGetDynamicsPlaningTasksQuery,
  useGetDynamicsCompletionTasksQuery,
} = assistantApi;
