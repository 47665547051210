import React, { FC, useEffect, useState } from 'react';

import { ButtonShowUsers } from '@components/shared/buttonShowUsers';
import { ContainerTable, LineFlex, LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as LeftIcon } from '@assets/icons/chevron-left.svg';
import { ReactComponent as RightIcon } from '@assets/icons/chevron-right.svg';
import Calendar from 'react-calendar';
import { formattedMonthTitle } from '@utils/formattedMonthTitle';
import dayjs from 'dayjs';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { IMapTasksDay } from 'interfaces/user.interfaces';
import { useDispatch } from 'react-redux';
import { changeCurrentMonthDay } from '@store/user/slice';
import { useStatsSelector } from '@store/statistic/selectors';
import { useNavigate } from 'react-router-dom';
import { GroupTasks } from './TableMonth';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { mobile } from '@components/constants/constants';

const customPrev2Label = <div className="prev2Label"></div>;

interface ITasks {
  showCalendar?: boolean;
  data?: IMapTasksDay[];
  textTitle: string;
}

export const Tasks: FC<ITasks> = (props) => {
  const { currentDate } = useStatsSelector((state) => state.user);
  const [startDate, setStartDate] = useState(currentDate);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setStartDate(currentDate);
  }, [currentDate]);

  const handlePickDate = (event: any) => {
    dispatch(changeCurrentMonthDay(dayjs(event)));
  };

  return (
    <Container>
      <LineFlex className="h-[60px]">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id={props.textTitle} />
        </LineText>
      </LineFlex>
      <GroupTasks className="flex gap-8">
        <RenderWithCondition condition={props.showCalendar}>
          <CalendarContainer className="flex">
            <>
              <Calendar
                formatMonthYear={formattedMonthTitle}
                navigationAriaLabel={'none'}
                tileClassName="content"
                prev2Label={customPrev2Label}
                next2Label={''}
                prevLabel={<LeftIcon fill="#297952" />}
                nextLabel={<RightIcon fill="#297952" />}
                onChange={(e) => handlePickDate(e)}
                formatLongDate={(locale, date) => dayjs(locale).format('DD-MM-YYYY')}
                onClickMonth={handlePickDate}
                onClickDay={(e) => handlePickDate(e)}
                value={currentDate.format('YYYY-MM-DD')}
              />
            </>
          </CalendarContainer>
        </RenderWithCondition>
        <ContainerTable className="py-2 px-5">
          {props?.data?.map((item) => (
            <LineFlex key={uniqueId(item.title)} border={item.border}>
              <LineText fontSize={'15px'}>
                <FormattedMessage id={item.title} />
              </LineText>
              <LineText fontSize={'15px'} bold={'700'}>
                {item.value}
              </LineText>
            </LineFlex>
          ))}
        </ContainerTable>
      </GroupTasks>
      <RenderWithCondition condition={!props.showCalendar}>
        <ButtonShowUsers
          title={'showChartsDynamic'}
          isActive={true}
          handleEvents={() => navigate('/employees/month/dynamics')}
        />
      </RenderWithCondition>
    </Container>
  );
};

const Container = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 0px;
`;

const CalendarContainer = styled.div`
  display: flex;
  min-width: 357px;
  max-width: 357px;
  border-radius: 10px;
  height: 300px;
  padding: 5px;
  box-sizing: border-box;
  background-color: #fff;
  @media screen and (max-width: ${mobile}px) {
    min-width: 290px;
    max-width: 290px;
  }
`;
