import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import { ReactComponent as CrownIcon } from '@assets/icons/crown.svg';
import { ReactComponent as AssistantIcon } from '@assets/icons/assist__green.svg';
import { ReactComponent as DefaultAvatarIcon } from '@assets/icons/default-avatar.svg';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { ImgAvatar } from '@components/screens/employees/components/TableItem';
import { mobile } from '@components/constants/constants';
import { colorsVar } from '@components/constants/colorVariables';
import HeaderTooltip from '@components/screens/month/components/userInfo/Header.Tooltip';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';

interface IAvatarAssist {
  urlAvatar?: string;
  isAssistant?: boolean;
  isManager?: boolean;
  textTooltip?: string;
  size?: 'small' | 'small-large' | 'medium' | 'large' | undefined;
}

const AvatarAssist: FC<IAvatarAssist> = (props) => {
  const { isMobile } = useWidthScreens();
  const [dataTooltip, setDataTooltip] = useState<any | null>(null);

  const sizeAvatar: number = useMemo(() => {
    switch (props.size) {
      case 'small':
        return 20;
      case 'small-large':
        return 24;
      case 'medium' || isMobile:
        return 40;
      case 'large':
        return 65;
      default:
        return 32;
    }
  }, [props.size, isMobile]);

  const classNameTooltip: string | undefined = useMemo(() => {
    return props.size === 'small' ? `${props.textTooltip}__sm` : props.textTooltip;
  }, [props.size, props.textTooltip]);

  return (
    <div
      className="flex justify-center items-center relative  cursor-pointer"
      onMouseOver={() => setDataTooltip({ text: props.textTooltip, isOpen: true })}
      onMouseOut={() => setDataTooltip({ text: props.textTooltip, isOpen: false })}
    >
      <RenderWithCondition condition={!props.urlAvatar}>
        <Icon className="flex" size={sizeAvatar}>
          <DefaultAvatarIcon width={sizeAvatar} height={sizeAvatar} />
        </Icon>
      </RenderWithCondition>
      <RenderWithCondition condition={props.urlAvatar}>
        <Icon className="flex" size={sizeAvatar}>
          <ImgAvatar src={props.urlAvatar} alt="avatar" />
        </Icon>
      </RenderWithCondition>
      <RenderWithCondition condition={props.isManager}>
        <AssistIcon className="flex" size={sizeAvatar}>
          <CrownIcon />
        </AssistIcon>
      </RenderWithCondition>
      <RenderWithCondition condition={props.isAssistant}>
        <AssistIcon className="flex" size={sizeAvatar}>
          <AssistantIcon fill={colorsVar.greenBase} />
        </AssistIcon>
      </RenderWithCondition>
      <HeaderTooltip isAssist={true} dataTooltip={dataTooltip} className={classNameTooltip} />
    </div>
  );
};
export default AvatarAssist;

const Icon = styled.div<{ size: number }>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: ${(props) => `${props.size === 65 ? `3px solid ${colorsVar.grey4}` : `1px solid ${colorsVar.grey4}`}  `};

  background-color: ${colorsVar.grey1};
  box-sizing: border-box;
`;
export const AssistIcon = styled.div<{ size: number }>`
  position: absolute;
  background-color: ${colorsVar.white};
  right: 0px;
  bottom: 0px;
  border: 1px solid ${colorsVar.grey4};
  width: ${(props) => `${props.size === 65 ? `20px` : `16px`}`};
  height: ${(props) => `${props.size === 65 ? `20px` : `16px`}`};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 4%;
  svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${mobile}px) {
    width: 14px;
    height: 14px;
  }
`;
