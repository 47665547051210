import React, { FC, useEffect, useRef, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SelectDateMonth } from '@components/shared/selectDate/SelectDateMonth';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { addPersonalGoalsDate, selectDynamicsSlice } from '@store/dynamics/slice';
import { LineText } from '@components/commons/commonsStyled';
import { RadarChartItem } from './RadarChartItem';
import { LabelsPolar } from './LabelsPolar';
import { CURRENT_USER_ID } from '@configs/consts';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import { ContainerChart } from '@components/screens/home/components/chartPlanComplete/ChartPlanComplete';
import { useGetUserPersonalGoalsQuery } from '@store/month/month.api';
import { useAppSelector } from '@store/hooks';
import { selectUserSlice } from '@store/user/slice';
import { IUserPersonalGoals } from 'interfaces/user.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';

interface IRadarChartPersonCompleteness {
  tooltip?: ({ active, payload, label }: any) => JSX.Element | null;
  position: CalendarPosition;
  isLoadingPage?: boolean;
}

const RadarChartPersonCompleteness: FC<IRadarChartPersonCompleteness> = (props) => {
  const [paramsStatisticMonth, setParamsStatisticMonth] = useState<Record<string, string>>({});

  const { dynamicsPersonalGoalsPeriod, isLoadingPersonalGoals } = useAppSelector(selectDynamicsSlice);

  const { currentUser } = useAppSelector(selectUserSlice);
  const currentUserId = currentUser?.id ? currentUser.id : localStorage.getItem(CURRENT_USER_ID);
  const prevPeriod = useRef(dynamicsPersonalGoalsPeriod);

  const { data: dynamicsPersonalGoals } = useGetUserPersonalGoalsQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });
  const dispatch = useDispatch();

  const handleDate = (event: Dayjs) => {
    dispatch(addPersonalGoalsDate(event));
  };

  useEffect(() => {
    if (isEmpty(dynamicsPersonalGoals) && isEmpty(dynamicsPersonalGoalsPeriod)) {
      dispatch(addPersonalGoalsDate(dayjs()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(dynamicsPersonalGoalsPeriod)) {
      const paramsString = new URLSearchParams({
        yearMonth: dayjs(dynamicsPersonalGoalsPeriod.startDate).format('YYYY-MM'),
      }).toString();
      if (!isEqual(prevPeriod.current, dynamicsPersonalGoalsPeriod) && currentUserId) {
        setParamsStatisticMonth({ id: currentUserId, params: paramsString });
      }
      prevPeriod.current = dynamicsPersonalGoalsPeriod;
    }
  }, [dynamicsPersonalGoalsPeriod]);

  return (
    <ContainerChart className="flex">
      <HeaderChart className="flex">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="wheelPersonalityCompleteness" />
        </LineText>
        <GroupSelect className="flex">
          <SelectDateMonth
            type="input"
            onChange={handleDate}
            id="firstEntryDateStartRange"
            value={dynamicsPersonalGoalsPeriod.startDate}
            position={props.position}
          />
        </GroupSelect>
      </HeaderChart>
      <ChartWrap className="flex relative" loading={props.isLoadingPage ?? false}>
        <LabelsPolar />
        <RenderWithCondition condition={!props.isLoadingPage}>
          <RadarChartItem data={dynamicsPersonalGoals ?? ({} as IUserPersonalGoals)} />
        </RenderWithCondition>
        <LocalLoader condition={isLoadingPersonalGoals || (props.isLoadingPage ?? false)} size={25} />
      </ChartWrap>
    </ContainerChart>
  );
};

export default RadarChartPersonCompleteness;

const HeaderChart = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  padding: 5px 0 20px 0;
  @media screen and (max-width: 1560px) {
    flex-direction: column;
    align-items: start;
  }
`;
const GroupSelect = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
`;
export const ChartWrap = styled.div<{ loading: boolean }>`
  flex-wrap: wrap;
  align-items: center;
  padding: 3px 7px;
  background-color: ${colorsVar.white};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  max-height: 500px;
  transition: all 0.7s ease;

  ${({ loading }) =>
    loading &&
    `
    min-height: 300px; 
    overflow: hidden;
    max-height: 0px; 
    transition: all 0.7s ease;
    `}
`;
