import { apiBaseQuery } from '../apiBaseQuery';
import {
  IUserBusinessGoals,
  IUserBusinessTasks,
  IUserDetailedBusinessGoals,
  IUserMonthGoal,
  IUserPersonalGoals,
  IUserPlaningMeeting,
  IUserStatistic,
  IUserTasksOfDay,
} from '../../interfaces/user.interfaces';
import { DASHBOARD_SERVICE_URL, SERVICE_URL } from '@components/constants/constants';

export const userApi = apiBaseQuery.injectEndpoints({
  endpoints: (builder) => ({
    getUserStatistic: builder.query<IUserStatistic, string>({
      query: (id) => ({
        url: `${DASHBOARD_SERVICE_URL}/users/${id}`,
      }),
    }),

    getUserMonthGoal: builder.query<IUserMonthGoal, Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/month-goals/user/${id}?${params}`,
      }),
    }),

    getUserPersonalGoals: builder.query<IUserPersonalGoals, Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/dashboards/users/${id}/personal-goals?${params}`,
      }),
    }),

    getUserBusinessGoals: builder.query<IUserBusinessGoals, Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/dashboards/users/${id}/business-goals?${params}`,
      }),
    }),

    getUserBusinessTasks: builder.query<IUserBusinessTasks, Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/dashboards/users/${id}/business-tasks?${params}`,
      }),
    }),

    getPlaningMeeting: builder.query<IUserPlaningMeeting, Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/dashboards/users/${id}/meetings?${params}`,
      }),
    }),

    getUserTasksOfDay: builder.query<IUserTasksOfDay, Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/dashboards/users/${id}/tasks-count?${params}`,
      }),
    }),

    getUserTasksOfMonth: builder.query<IUserTasksOfDay, Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/dashboards/users/${id}/month-tasks-count?${params}`,
      }),
    }),

    getUserDetailedBusinessGoals: builder.query<IUserDetailedBusinessGoals[], Record<string, string>>({
      query: ({ id, params }) => ({
        url: `${SERVICE_URL}/business-goals/user/${id}?${params}`,
      }),
    }),
  }),
});

export const {
  useGetUserStatisticQuery,
  useGetUserMonthGoalQuery,
  useGetUserPersonalGoalsQuery,
  useGetUserBusinessGoalsQuery,
  useGetUserBusinessTasksQuery,
  useGetUserTasksOfDayQuery,
  useGetUserTasksOfMonthQuery,
  useGetUserDetailedBusinessGoalsQuery,
  useGetPlaningMeetingQuery,
} = userApi;
